/*
*
* ASTRONAUT WEB APP
* MIXINS
* VERSION 1.0
*
*/

@mixin rounded($value) {
	-webkit-border-radius: $value;
	-moz-border-radius:$value;
  border-radius: $value;
}

@mixin all-transition($start, $duration) {
	-webkit-transition:all $start ease $duration;
	-moz-transition:all $start ease $duration;
	transition: all $start ease $duration;
}

@mixin transform-transition($duration) {
	-webkit-transition: transform ease $duration;
	-webkit-transition:-webkit-transform ease $duration;
	transition:-webkit-transform ease $duration;
	transition:transform ease $duration;
	transition: transform ease $duration, -webkit-transform ease $duration;
}

@mixin img-sprite($x, $y , $w, $h) {
	@extend .icon;
	width: $w;
	height: $h;
	background-position: $x $y;
}

@mixin ellipsis($w) {
	max-width: $w;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin animated($t) {
  -webkit-animation-duration: $t;
  animation-duration: $t;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@mixin riek($display, $w) {
	display: $display !important;
	width: $w !important;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

@mixin linkStyle($fontSize, $paddingBottom, $borderWidth, $fontWeight, $color, $lineHeight, $transform) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	padding-bottom: $paddingBottom;
	border-bottom-width: $borderWidth;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	line-height: $lineHeight;
	display: inline-block;
	text-transform: $transform;
	color: inherit;
	&:hover, &:focus {
		border-bottom-color: $color;
		color: inherit;
		font-weight: normal;
	}
	&:active, &.active {
		border-bottom-color: $color;
		color: inherit;
	}
}

@mixin selectBox($generalColor, $borderColor) {
	.Select-control {
		cursor: pointer;
		height: 40px;
		border-color: $borderColor;
		.Select-placeholder {
			color: #828282;
			font-size: 16px;
			line-height: 38px;
		}
		.Select-value {
			color: $generalColor;
			font-size: 16px;
			max-width: 90%;
			line-height: 38px;
			.Select-value-label {
				color: $generalColor !important;
				font-size: 16px;
				font-weight: bold;
			}
		}
		.Select-arrow {
			border-color:$generalColor transparent transparent;
		}
		&:hover, &:focus, &:active {
			border-color: $purple;
		}
	}
	&.is-disabled {
		.Select-arrow-zone {
			display: none;
		}
	}
}

@mixin hideAnchorText {
	text-indent: -9999px;
  overflow: hidden;
}
