/*
*
* ASTRONAUT WEB APP
* NAVIGATION
* VERSION 1.0
*
*/

.nav {
  > li > a {
    color: #a7b1c2;
    padding: 14px 20px 14px 25px;
  }
  &.navbar-right > li > a, > li.active > a {
    color: $white;
  }
	.open {
		> a, a:hover, a:focus {
			background-color: transparent;
			border-color: transparent;
		}
	}
}

.dropdown-menu {
  border: medium none;
  @include rounded($medium-rounded);
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 10px;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  > li {
		padding: 0 !important;
		> a {
	    color: inherit;
	    text-align: left;
	    font-weight: normal;
	    font-size: 12px;
	    color: $text-color;
			width: 100%;
	    &.font-bold {
	      font-weight: 600;
	    }
	  }
	}
}

/*
* CUSTOM TOP NAVBAR
*/


.navbar-main {
  background-color: $bg-black;
  .nav {
    .open > a {
      &:hover, &:focus {
      }
    }
    &.navbar-top-links > li > a {
      &:hover, &:focus {
        background-color: transparent;
      }
      &.count-info .label {
        right: 12px;
      }
      &.active {
        color: $white;
				.icon-bar {
					@extend .icon-i-bar-active;
				}
      }
			.icon-bar {
				cursor: pointer;
			  @extend .icon-i-bar;
			  &:hover, &:focus {
			    @extend .icon-i-bar-active;
			  }
			}
    }
    > li > a i {
      margin-right: 6px;
    }
  }
  .navbar {
    border: 0;
    margin-bottom: 0;
    min-height: 55px;
  }
  .navbar-top-links {
    li {
      display: inline-block;
      padding: 0px 5px;
      &:last-child {
        margin-right: 40px;
      }
      a {
        padding: 12px 10px;
        color: $grey;
        font-weight: bold;
        &:hover, &:focus {
          color: $white;
        }
      }
			&.dropdown.open > a {
				&:hover, &:focus {
					background-color: transparent;
				}
			}
    }
    .dropdown-menu {
			&.dropdown-caret:after {
			    border-bottom: 6px solid #fff;
			    border-left: 6px solid transparent;
			    border-right: 6px solid transparent;
			    content: "";
			    display: inline-block;
			    left: 10px;
			    position: absolute;
			    top: -6px;
			}
			&.dropdown-caret:after {
			    left: auto;
			    right: 27px;
			}
			.media-body {
			  overflow: hidden;
				h5 {
					margin: 10px 0 5px;
				}
        small {
    			display: block;
					float: none;
					margin-right: 0;
    		}
			}
			&.dropdown-alerts {
				margin-left: -123px;
				@include rounded($medium-rounded);
				padding: 0;
				li {
					display: block;
					padding: 0;
					border-bottom: 1px solid #d8d8d8;
					a {
						padding: 0;
						min-height: 0;
						font-size: 12px;
						color: $text-color;
						font-weight: normal;
						&.active, &:hover, &:focus {
							background-color: $purple;
							color: $white;
						}
						div {
							white-space: normal;
						}
						h5 {
							font-weight: bold;
						}
						&.link-block {
							padding: 10px 0;
							color: $purple;
							text-align: center;
							font-weight: bold;
							&:hover, &:focus {
								background-color: transparent;
								text-decoration: underline;
							}
						}
					}
					&:first-child {
						border-bottom: 0;
					}
					&:last-child {
						margin-right: 0;
						border-bottom: 0;
					}
					&.dropdown-header {
						font-size: 14px;
						font-weight: bold;
						padding: 12px 15px;
						border-bottom: 1px solid #d8d8d8;
	          color: $text-color;
					}
				}
			}
			&.dropdown-tasks, &.dropdown-alerts {
	      width: 310px;
	      min-width: 0;
	    }
	    &.dropdown-tasks {
	      margin-left: -59px;
	    }
	    &.dropdown-user {
	      right: 0;
	      left: auto;
				width: 240px;
				@include rounded($small-rounded);
				padding: 10px;
				li {
					display: block;
					padding: 0;
          position: relative;
					a {
						border: 0;
						min-height: 0;
						font-size: 12px;
						color: $text-color;
						font-weight: normal;
            display: block;
            @include ellipsis(180px);
						&.active {
							&:before {
								font-family: 'fontAwesome';
								content: "\f111";
								font-size: 7px;
						    left: 0;
						    top: 12px;
						    position: absolute;
							}
						}
						&:hover {
							background-color: transparent;
							color: $purple;
						}
						div {
							white-space: normal;
						}
						h5 {
							font-weight: bold;
						}
					}
					&.footer {
						margin: 0;
						a {
							&:hover, &:focus {
								@include rounded($dropdown-rounded);
							}
						}
					}
					&.dropdown-header {
						font-size: 14px;
						font-weight: bold;
						padding: 12px 15px;
						color: $text-color;
						text-align: center;
					}
          &.divider {
            border-top: 1px solid #d8d8d8;
						width: 60%;
						margin: 10px auto;
          }
				}
	    }
		}
  }
}

.nav-header {
  a {
    color: #DFE4ED;
  }
}

.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: inline-table;
  .form-group {
    margin-bottom: 0;
  }
}

.nav.navbar-top-links a {
  font-size: 14px;
}

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}

.nav > li > a.count-info i {
  font-size: 20px;
}

.navbar-main {
  background-color: $bg-black;
  .nav {
    &.navbar-top-links > li > a {
      &:hover, &:focus {
        background-color: transparent;
      }
      &.count-info .label {
        right: 12px;
      }
    }
    > li > a i {
      margin-right: 6px;
    }
  }
  .navbar {
    border: 0;
    margin-bottom: 0;
    min-height: 55px;
  }
  .navbar-top-links {
    li {
      display: inline-block;
      padding: 0px 5px;
      &:last-child {
        margin-right: 40px;
      }
      a {
        padding: 12px 10px;
        color: $grey;
        font-weight: bold;
        &:hover, &:focus {
          color: $white;
        }
      }
			&.dropdown.open > a {
				&:hover, &:focus {
					background-color: transparent;
				}
			}
    }
    .dropdown-menu {
			&.dropdown-caret:after {
		    border-bottom: 6px solid #fff;
		    border-left: 6px solid transparent;
		    border-right: 6px solid transparent;
		    content: "";
		    display: inline-block;
		    left: 10px;
		    position: absolute;
		    top: -6px;
			}
			&.dropdown-caret:after {
		    left: auto;
		    right: 27px;
			}
			.media-body {
			  overflow: hidden;
				h5 {
					margin: 10px 0 5px;
				}
        small {
    			display: block;
					float: none;
					margin-right: 0;
    		}
			}
			&.dropdown-alerts {
				margin-left: -123px;
				@include rounded($medium-rounded);
				padding: 0;
				li {
					display: block;
					padding: 0;
					border-bottom: 1px solid #d8d8d8;
					a {
						padding: 0;
						min-height: 0;
						font-size: 12px;
						color: $text-color;
						font-weight: normal;
						&.active, &:hover, &:focus {
							background-color: $purple;
							color: $white;
						}
						div {
							white-space: normal;
						}
						h5 {
							font-weight: bold;
						}
						&.link-block {
							padding: 10px 0;
							color: $purple;
							text-align: center;
							font-weight: bold;
							&:hover, &:focus {
								background-color: transparent;
								text-decoration: underline;
							}
						}
					}
					&:first-child {
						border-bottom: 0;
					}
					&:last-child {
						margin-right: 0;
						border-bottom: 0;
					}
					&.dropdown-header {
						font-size: 14px;
						font-weight: bold;
						padding: 12px 15px;
						border-bottom: 1px solid #d8d8d8;
	          color: $text-color;
					}
				}
			}
			&.dropdown-tasks, &.dropdown-alerts {
	      width: 310px;
	      min-width: 0;
	    }
	    &.dropdown-tasks {
	      margin-left: -59px;
	    }
	    &.dropdown-user {
				li {
					display: block;
					padding: 0;
					a {
						border: 0;
						min-height: 0;
						font-size: 12px;
						color: $text-color;
						font-weight: normal;
						padding: 8px 15px;
						div {
							white-space: normal;
						}
						h5 {
							font-weight: bold;
						}
					}
					&.dropdown-header {
						font-size: 14px;
						font-weight: bold;
						padding: 12px 15px;
						color: $text-color;
						text-align: center;
					}
				}
	    }
		}
  }
}

.navbar-light {
	border-bottom: 1px solid #eee;
  .logo-brand {
    color: inherit;
  }
  .nav.navbar-right > li > a {
    color: inherit;
  }
	.pull-left .nav > li > a,
	.pull-right .nav > li > a {
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

}

.nav-header {
  a {
    color: #DFE4ED;
  }
}

.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: inline-table;
  .form-group {
    margin-bottom: 0;
  }
}

.nav.navbar-top-links a {
  font-size: 14px;
}

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}

.nav > li > a.count-info i {
  font-size: 20px;
}

.dropdown.other-menu {
	display: inline-block;
	.dropdown-menu {
		border-radius: 0px;
		box-shadow: 1px 1px 2px rgba(86, 96, 117, 0.7);
		background-color: #f7f7f7;
		li {
			> a {
				font-size: 10px;
				padding: 5px 20px;
				&:hover, &:focus {
					background-color: #C5D7E8;
				}
			}
			&.dropdown-header {
				background-color: $dark-grey;
				color: $white;
				padding: 5px 20px;
				font-size: 10px;
				i {
					&.exit {
						float: right;
		    		padding: 2px 0;
						color: $white;
						cursor: pointer;
					}
				}
			}
			&.dropdown-footer {
				padding: 3px 10px;
				border-top: 1px solid #d8d8d8;
				.btn-xsmall {
					font-size: 10px;
				}
			}
		}
	}
}

.nav-second-level li, .nav-third-level li {
  border-bottom: none !important;
}

.nav-second-level li a {
  padding: 12px 10px 12px 55px;
  color: #99ACC7;
  font-size: 12px;
}

.nav-third-level li a {
  padding-left: 62px;
}

.navbar-toggle {
  border: 1px solid #fff;
  .icon-bar {
    border: 1px solid #fff;
  }
}

ul.nav.navbar-top-links.navbar-right li.form-search .dropdown-menu {
  width: 313px;
  @include rounded($none-rounded);
}

.navbar-top-links.navbar-right li.form-search .dropdown-menu li:first-child {
  border-bottom: 1px solid #D8D8D8;
  padding: 3px 20px;
  min-height: 0;
  h5 {
    font-size: 14px;
    font-weight: bold;
    padding: 3px 4px;
  }
}

ul.nav.navbar-top-links.navbar-right li.form-search {
  width: 351px;
  vertical-align: middle;
  border: 0;
  margin-right: 20px;
}

.nav-folder {
  padding: 0 0 20px 0;
}

.menu-option {
	color: #9c9c9c;
	&:hover, &.active {
		color: #343434;
	}
}

nav.horizontal {
	li {
    float: left;
		padding: 0 16px;
		&:first-child {
			padding-left: 0;
		}
		a {
	    display: block;
	    text-align: center;
			&.active {
				font-weight: bold;
			}
		}
	}

}

#theme-purple {
	.logo-brand {
	  float: left;
	  padding: 0 24px;
	  font-size: 18px;
	  line-height: 20px;
		height: auto;
	  img {
			height: 53px;
      width: 100%;
      object-fit: none;
	  }
	}

  .input-brand {
		display: inline-block;
		padding: 24px 0;
    a:nth-child(2) {
      border-left: 1px solid $black;
    }
  }

}

.primary-nav-link {
  @include linkStyle(16px, 12px, 4px, bold, #eaeaea, 22px, uppercase)
}

.secondary-nav-link {
  @include linkStyle(16px, 4px, 2px, normal, inherit, 25px, capitalize)
}

.nav-button {
  @extend .flex-container;
  @extend .flex-between;
  .full-width {
    @extend .flex-1;
  }
	button {
		border: 3px solid transparent;
		&.button__secondary {
			box-shadow: 0 0 0 1px $purple01;
		}
		&:hover, &:focus {
			border-width: 3px;
		}
	}
  button:not(:last-child) {
    margin-right: 10px;
  }
  button:not(:first-child) {
    margin-left: 10px;
  }
}
