/*
*
* ASTRONAUT WEB APP
* ICONS
* VERSION 1.0
*
*/

.icon {
  vertical-align: middle;
  display: inline-block;
}

.action-spritesheet {
	background-image: $action-sprite;
	background-repeat: no-repeat;
}

.notif-spritesheet {
	background-image: $notif-sprite;
	background-repeat: no-repeat;
}

.add-candidate-spritesheet {
	background-image: $add-candidate-sprite;
	background-repeat: no-repeat;
}

.page-spritesheet {
	background-image: $page-sprite;
	background-repeat: no-repeat;
}

.social-spritesheet {
	background-image: $social-sprite;
	background-repeat: no-repeat;
}

.thumbs-spritesheet {
	background-image: $thumbs-sprite;
	background-repeat: no-repeat;
}

.thumbs2-spritesheet {
	background-image: $thumbs2-sprite;
	background-repeat: no-repeat;
}

.report-spritesheet {
	background-image: $reports-sprite;
	background-repeat: no-repeat;
}

.stars-spritesheet {
	background-image: $stars-sprite;
	background-repeat: no-repeat;
}

@each $iconConfig in '14' '16' '18' '21' '22' '25' '30' '35' '38' '45' '60'  {
	.icon#{$iconConfig} {
		width: #{$iconConfig}px;
		height: #{$iconConfig}px;
	}
}

.icon-i-clone {
	@extend .action-spritesheet;
	@include img-sprite(-71px, -5px, 16px, 16px);
}

.icon-i-clone-active {
	@extend .action-spritesheet;
  @include img-sprite(-93px, -5px, 16px, 16px);
}

.icon-i-clone-disabled {
	@extend .action-spritesheet;
  @include img-sprite(-115px, -5px, 16px, 16px);
}

.icon-i-archive {
  @extend .action-spritesheet;
  @include img-sprite(-506px, -5px, 16px, 16px);
}

.icon-i-archive-active {
  @extend .action-spritesheet;
  @include img-sprite(-530px, -5px, 16px, 16px);
}

.icon-i-archive-disabled {
	@extend .action-spritesheet;
  @include img-sprite(-49px, -5px, 16px, 16px);
}

.icon-i-o-archive {
	@extend .action-spritesheet;
  @include img-sprite(-206px, -5px, 16px, 16px);
}

.icon-i-o-archive-active {
	@extend .action-spritesheet;
	@include img-sprite(-229px, -5px, 16px, 16px);
}

.icon-i-md-archive {
	@extend .page-spritesheet;
	@include img-sprite(-640px, -5px, 35px, 35px);
}

.icon-i-md-archive-active {
	@extend .page-spritesheet;
	@include img-sprite(-679px, -5px, 35px, 35px);
}

.icon-i-edit {
	@extend .action-spritesheet;
	@include img-sprite(-137px, -5px, 16px, 16px);
}

.icon-i-edit-active {
	@extend .action-spritesheet;
	@include img-sprite(-160px, -5px, 16px, 16px);
}

.icon-i-edit-disabled {
	@extend .action-spritesheet;
	@include img-sprite(-183px, -5px, 16px, 16px);
}

.icon-i-pencil {
	@extend .action-spritesheet;
	@include img-sprite(-252px, -5px, 16px, 16px);
}

.icon-i-pencil-active {
	@extend .action-spritesheet;
  @include img-sprite(-278px, -5px, 16px, 16px);
}

.icon-i-message {
	@extend .notif-spritesheet;
	@include img-sprite(-5px, -5px, 36px, 36px);
}

.icon-i-message-grey {
	@extend .notif-spritesheet;
	@include img-sprite(-51px, -5px, 36px, 36px);
}

.icon-i-question {
	@extend .notif-spritesheet;
	@include img-sprite(-97px, -5px, 36px, 36px);
}

.icon-i-question-grey {
	@extend .notif-spritesheet;
	@include img-sprite(-143px, -5px, 36px, 36px);
}

.icon-i-rating {
	@extend .notif-spritesheet;
	@include img-sprite(-189px, -5px, 36px, 36px);
}

.icon-i-rating-grey {
	@extend .notif-spritesheet;
	@include img-sprite(-235px, -5px, 36px, 36px);
}

.icon-i-share {
	@extend .notif-spritesheet;
	@include img-sprite(-281px, -5px, 36px, 36px);
}

.icon-i-share-grey {
	@extend .notif-spritesheet;
	@include img-sprite(-327px, -5px, 36px, 36px);
}

.icon-i-xs-message {
	@extend .notif-spritesheet;
	@include img-sprite(-10px, -56px, 25px, 25px);
}

.icon-i-xs-question {
	@extend .notif-spritesheet;
	@include img-sprite(-45px, -56px, 25px, 25px);
}

.icon-i-xs-share {
	@extend .notif-spritesheet;
	@include img-sprite(-80px, -56px, 25px, 25px);
}

.icon-i-info {
	@extend .page-spritesheet;
	@include img-sprite(-525px, -5px, 25px, 25px);
}

.icon-i-cross {
	@extend .page-spritesheet;
	@include img-sprite(-174px, -5px, 26px, 26px);
}

.icon-i-cross-white {
  width: 26px;
  height: 26px;
  background: url('../img/cross-white.png');
  background-repeat: no-repeat;
}

.icon-i-cross-hover {
	@extend .page-spritesheet;
	@include img-sprite(-210px, -5px, 26px, 26px);
}

.icon-i-filter {
	@extend .page-spritesheet;
	@include img-sprite(-360px, -5px, 25px, 25px);
}

.icon-i-question-pop {
	@extend .page-spritesheet;
	@include img-sprite(-718px, -5px, 18px, 18px);
}

.icon-i-bell-o {
	background: url('../img/icon-bell-o.png');
	background-repeat: no-repeat;
}

.icon-i-bell-o-hover {
	background: url('../img/icon-bell-o-hover.png');
	background-repeat: no-repeat;
}

.icon-i-barcode {
	@extend .page-spritesheet;
	@include img-sprite(-111px, -5px, 25px, 25px);
}

.icon-i-record {
	@extend .action-spritesheet;
  @include img-sprite(-330px, -5px, 16px, 16px);
}

.icon-i-practice {
	@extend .action-spritesheet;
  @include img-sprite(-304px, -5px, 16px, 16px);
}

.icon-i-takes {
	@extend .action-spritesheet;
  @include img-sprite(-404px, -5px, 16px, 16px);
}

.icon-i-video {
	@extend .page-spritesheet;
	@include img-sprite(-837px, -5px, 16px, 16px);
}

.icon-i-lamp {
	@extend .page-spritesheet;
	@include img-sprite(-555px, -5px, 16px, 16px);
}

.icon-i-document {
	@extend .page-spritesheet;
	@include img-sprite(-309px, -5px, 21px, 21px);
}

.icon-i-arrow-left {
	@extend .page-spritesheet;
	@include img-sprite(-61px, -5px, 16px, 16px);
}

.icon-i-arrow-right {
	@extend .page-spritesheet;
	@include img-sprite(-86px, -5px, 16px, 16px);
}

.icon-i-document-black {
	@extend .page-spritesheet;
	@include img-sprite(-335px, -5px, 20px, 20px);
}

.icon-i-duplicate-purple {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/duplicate-purple.png');
	background-repeat: no-repeat;
}

.icon-i-delete {
	@extend .page-spritesheet;
  @include img-sprite(-245px, -5px, 22px, 22px);
}

.icon-i-delete-active {
	@extend .page-spritesheet;
  @include img-sprite(-277px, -5px, 22px, 22px);
}

.icon-i-remove {
	@extend .action-spritesheet;
  @include img-sprite(-356px, -5px, 16px, 16px);
}

.icon-i-remove-active {
	@extend .action-spritesheet;
  @include img-sprite(-380px, -5px, 16px, 16px);
}

.icon-i-list {
	background: url('../img/icon-list.png');
	background-repeat: no-repeat;
}

.icon-i-list-active {
	background: url('../img/icon-list-active.png');
	background-repeat: no-repeat;
}

.icon-i-grid {
	background: url('../img/icon-grid.png');
	background-repeat: no-repeat;
}

.icon-i-grid-active {
	background: url('../img/icon-grid-active.png');
	background-repeat: no-repeat;
}

.icon-i-import {
	@extend .add-candidate-spritesheet;
	@include img-sprite(-5px, -5px, 60px, 78px);
}

.icon-i-single-input {
	@extend .add-candidate-spritesheet;
	@include img-sprite(-75px, -5px, 57px, 51px);
}

.icon-i-trash {
	@extend .action-spritesheet;
  @include img-sprite(-430px, -5px, 16px, 16px);
}

.icon-i-trash-purple {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/trash-purple.png');
	background-repeat: no-repeat;
}

.icon-i-trash-active {
	@extend .action-spritesheet;
  @include img-sprite(-456px, -5px, 16px, 16px);
}

.icon-i-trash-disabled {
	@extend .action-spritesheet;
  @include img-sprite(-482px, -5px, 16px, 16px);
}

.icon-i-folder-plus {
	@extend .page-spritesheet;
  @include img-sprite(-391px, -5px, 25px, 25px);
}

.icon-i-folder-plus-active {
	@extend .page-spritesheet;
  @include img-sprite(-426px, -5px, 25px, 25px);
}

.icon-i-folder {
	width: 30px;
	height: 23px;
	background: url('../img/icon-folder.png');
	background-repeat: no-repeat;
}

.icon-i-folder-active {
	background: url('../img/icon-folder-active.png');
	background-repeat: no-repeat;
}

.icon-i-folder-question {
	width: 35px;
	height: 28px;
	@extend .icon;
	background: url('../img/icon-folder-question.png');
	background-repeat: no-repeat;
}

.icon-vote-yes {
	@extend .thumbs-spritesheet;
	@include img-sprite(-5px, -39px, 35px, 35px);
}

.icon-vote-yes-hover {
	@extend .thumbs-spritesheet;
	@include img-sprite(-50px, -39px, 35px, 35px);
}

.icon-i-xs-yes {
	@extend .thumbs-spritesheet;
	@include img-sprite(-73px, -5px, 24px, 24px);
}

.icon-i-xs-no {
	@extend .thumbs-spritesheet;
	@include img-sprite(-39px, -5px, 24px, 24px);
}

.icon-i-xs-maybe {
	@extend .thumbs-spritesheet;
	@include img-sprite(-5px, -5px, 25px, 24px);
}

.icon-i-big-info {
	@extend .page-spritesheet;
	@include img-sprite(-6px, -56px, 27px, 27px);
}

.icon-i-big-info-hover {
	@extend .page-spritesheet;
	@include img-sprite(-43px, -56px, 27px, 27px);
}

.icon-i-pdf {
	@extend .page-spritesheet;
	@include img-sprite(-79px, -56px, 24px, 27px);
}

.icon-i-pdf-hover {
	@extend .page-spritesheet;
	@include img-sprite(-113px, -56px, 24px, 27px);
}

.icon-i-mini-player {
	@extend .page-spritesheet;
	@include img-sprite(-147px, -56px, 25px, 24px);
}

.icon-i-mini-player-hover {
	@extend .page-spritesheet;
	@include img-sprite(-182px, -56px, 25px, 24px);
}

.icon-i-player {
	@extend .page-spritesheet;
	@include img-sprite(-149px, -98px, 26px, 27px);
}

.icon-i-player-hover {
	@extend .page-spritesheet;
	@include img-sprite(-185px, -98px, 26px, 27px);
}

.icon-i-list-question {
	@extend .page-spritesheet;
	@include img-sprite(-297px, -56px, 26px, 27px);
}

.icon-i-list-question-hover {
	@extend .page-spritesheet;
	@include img-sprite(-333px, -56px, 26px, 27px);
}

.icon-i-comment {
	@extend .page-spritesheet;
	@include img-sprite(-77px, -98px, 26px, 27px);
}

.icon-i-comment-hover {
	@extend .page-spritesheet;
	@include img-sprite(-113px, -98px, 26px, 27px);
}

.icon-i-big-share {
	@extend .page-spritesheet;
	@include img-sprite(-437px, -56px, 21px, 27px);
}

.icon-i-big-share-hover {
	@extend .page-spritesheet;
	@include img-sprite(-468px, -56px, 21px, 27px);
}

.icon-i-cloud {
	@extend .page-spritesheet;
	@include img-sprite(-499px, -56px, 22px, 16px);
}

.icon-i-video-upload {
	@extend .page-spritesheet;
	@include img-sprite(-531px, -56px, 20px, 15px);
}

.icon-i-score {
	@extend .icon;
  width: 20px;
  height: 20px;
	background: url('../img/score.png');
	background-repeat: no-repeat;
}

.icon-i-xs-score {
	@extend .page-spritesheet;
	@include img-sprite(-596px, -56px, 13px, 16px);
}

.icon-i-xs-score-purple {
	@extend .icon;
  width: 19px;
  height: 18px;
	background: url('../img/score-small.png');
	background-repeat: no-repeat;
}

.icon-i-download {
	@extend .page-spritesheet;
	@include img-sprite(-619px, -56px, 20px, 20px);
}

.icon-i-video-o {
	@extend .page-spritesheet;
	@include img-sprite(-573px, -56px, 21px, 13px);
}

.icon-i-video-o-hover {
	@extend .page-spritesheet;
	@include img-sprite(-604px, -56px, 21px, 13px);
}

.icon-i-test-o {
	@extend .page-spritesheet;
	@include img-sprite(-635px, -56px, 22px, 23px);
}

.icon-i-test-o-hover {
	@extend .page-spritesheet;
	@include img-sprite(-667px, -56px, 22px, 23px);
}

.icon-i-longarrow-left {
	@extend .page-spritesheet;
	@include img-sprite(-795px, -56px, 64px, 33px);
}

.icon-i-longarrow-left-hover {
	@extend .page-spritesheet;
	@include img-sprite(-869px, -56px, 64px, 33px);
}

.icon-i-longarrow-right {
	@extend .page-spritesheet;
	@include img-sprite(-649px, -56px, 63px, 33px);
}

.icon-i-longarrow-right-hover {
	@extend .page-spritesheet;
	@include img-sprite(-722px, -56px, 63px, 33px);
}

.icon-i-profile {
	@extend .page-spritesheet;
	@include img-sprite(-5px, -98px, 26px, 27px);
}

.icon-i-profile-hover {
	@extend .page-spritesheet;
	@include img-sprite(-41px, -98px, 26px, 27px);
}

.icon-i-vote-maybe {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/thumb-up-down.png');
	background-repeat: no-repeat;
}

.icon-i-vote-maybe-hover {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/thumb-up-down.png');
	background-repeat: no-repeat;
}

.icon-i-vote-no {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/thumb-down.png');
	background-repeat: no-repeat;
}

.icon-i-vote-no-hover {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/thumb-down.png');
	background-repeat: no-repeat;
}

.icon-i-vote-yes {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/thumb-up.png');
	background-repeat: no-repeat;
}

.icon-i-vote-yes-hover {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/thumb-up.png');
	background-repeat: no-repeat;
}

.icon-i-duplicate {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/clone.png');
	background-repeat: no-repeat;
}

.icon-i-cancel {
	@extend .icon;
  width: 16px;
  height: 16px;
	background: url('../img/cancel.png');
	background-repeat: no-repeat;
}

.icon-i-time {
  @extend .icon16;
	background: url('../img/icon-time.png');
	background-repeat: no-repeat;
}

.icon-i-detail {
	@extend .report-spritesheet;
	@include img-sprite(-5px, -5px, 53px, 70px);
}

.icon-i-detail-active {
	@extend .report-spritesheet;
	@include img-sprite(-68px, -5px, 57px, 74px);
}

.icon-i-summary {
	@extend .report-spritesheet;
	@include img-sprite(-135px, -5px, 52px, 70px);
}

.icon-i-summary-active {
	@extend .report-spritesheet;
	@include img-sprite(-197px, -5px, 56px, 74px);
}

.icon-i-test {
	@extend .report-spritesheet;
	@include img-sprite(-263px, -5px, 53px, 70px);
}

.icon-i-test-active {
	@extend .report-spritesheet;
	@include img-sprite(-326px, -5px, 57px, 74px);
}

.icon-i-mini-question {
	@extend .icon;
  width: 24px;
  height: 24px;
	background: url('../img/icon-question2.png');
	background-repeat: no-repeat;
}

.icon-i-mini-question-active {
	@extend .icon;
  width: 21px;
  height: 25px;
	background: url('../img/icon-question-hover.png');
	background-repeat: no-repeat;
}

.icon-i-md-question {
  width: 23px;
  height: 25px;
	background: url('../img/icon-md-question.png');
	background-repeat: no-repeat;
}

.icon-i-md-question2 {
  width: 24px;
  height: 24px;
	background: url('../img/icon-md-question2.png');
	background-repeat: no-repeat;
}

.icon-dots {
	@extend .icon;
	width: 16px;
	height: 4px;
	background: url('../img/icon-dots.png');
	background-repeat: no-repeat;
  transform: rotate(90deg);
}

.icon-i-long-arrow-left {
	@extend .icon;
	width: 30px;
	height: 24px;
	background: url('../img/arrow-left.png');
	background-repeat: no-repeat;
}

.icon-i-prev-button {
	@extend .icon;
	width: 54px;
	height: 54px;
	background: url('../img/prev-button.png');
	background-repeat: no-repeat;
}

.icon-i-next-button {
	@extend .icon;
	width: 54px;
	height: 54px;
	background: url('../img/next-button.png');
	background-repeat: no-repeat;
}

.icon-i-long-arrow-left-active {
	@extend .icon;
	width: 30px;
	height: 24px;
	background: url('../img/arrow-left-hover.png');
	background-repeat: no-repeat;
}

.icon-i-long-arrow-right {
	@extend .icon;
	width: 30px;
	height: 24px;
	background: url('../img/arrow-right.png');
	background-repeat: no-repeat;
}

.icon-i-long-arrow-right-active {
	@extend .icon;
	width: 30px;
	height: 24px;
	background: url('../img/arrow-right-hover.png');
	background-repeat: no-repeat;
}

.icon-i-retake {
	@extend .icon;
	width: 24px;
	height: 24px;
	background: url('../img/retake2.png');
	background-repeat: no-repeat;
}

.icon-i-retake-active {
	@extend .icon;
	width: 27px;
	height: 26px;
	background: url('../img/retake-hover.png');
	background-repeat: no-repeat;
}

.icon-i-retake-disabled {
	@extend .icon;
	width: 27px;
	height: 26px;
	background: url('../img/retake-disabled.png');
	background-repeat: no-repeat;
}

.icon-i-rename {
	@extend .icon;
	width: 16px;
	height: 16px;
	background: url('../img/rename.png');
	background-repeat: no-repeat;
}

.arrow-right {
	@extend .icon;
	width: 30px;
	height: 22px;
	background: url('../img/right-arrow.png');
	background-repeat: no-repeat;
}

.icon-i-clock-purple {
	@extend .icon;
	width: 24px;
	height: 24px;
	background: url('../img/clock-purple.png');
	background-repeat: no-repeat;
}

.img-add-img {
	width: 150px;
	height: 150px;
	display: inline-block;
	background: url('../img/add-img.png') no-repeat;
}

.img-add-img-process {
	width: 150px;
	height: 150px;
	display: inline-block;
	background: url('../img/add-img-process.gif') no-repeat;
}

.close-img-2 {
	width: 32px;
	height: 32px;
	display: inline-block;
	background: url('../img/close-img-2.png') no-repeat;
}

.img-timer {
	width: 34px;
	height: 34px;
	display: inline-block;
	background: url('../img/timer.png') no-repeat;
}

.img-mcq {
	width: 34px;
	height: 34px;
	display: inline-block;
	background: url('../img/mcq.png') no-repeat;
}

.img-ftq {
	width: 34px;
	height: 34px;
	display: inline-block;
	background: url('../img/ftq.png') no-repeat;
}

.img-video {
	width: 34px;
	height: 34px;
	display: inline-block;
	background: url('../img/icon-vid.png') no-repeat;
}

.icon-i-exam {
	width: 20px;
	height: 20px;
	display: inline-block;
	background: url('../img/icon-mcq.png') no-repeat;
}

.icon-i-close {
	width: 16px;
	height: 16px;
	display: inline-block;
	background: url('../img/close.png') no-repeat;
}

.icon-i-ftq {
	width: 20px;
	height: 20px;
	display: inline-block;
	background: url('../img/icon-i-ftq.png') no-repeat;
}

.icon-i-video-grey {
	width: 30px;
	height: 30px;
	opacity: 0.5;
	display: inline-block;
	background: url('../img/video-grey.png') no-repeat;
}

.icon-i-vid {
	width: 22px;
	height: 14px;
	display: inline-block;
	background: url('../img/icon-vid.png') no-repeat;
}

.icon-i-video-outline {
	width: 22px;
	height: 13px;
	display: inline-block;
	background: url('../img/icon-video-outline.png') no-repeat;
}

.icon-loader {
	width: 160px;
	height: 160px;
	display: inline-block;
	background: url('../img/loader.gif') no-repeat;
}

.icon-alert {
	width: 94px;
	height: 94px;
	display: inline-block;
	background: url('../img/alert.png') no-repeat;
}

.icon-success {
	width: 62px;
	height: 62px;
	display: inline-block;
	background: url('../img/success.png') no-repeat;
}

.icon-download-xs {
	width: 12px;
	height: 10px;
	display: inline-block;
	background: url('../img/download-xs.png') no-repeat;
}

.icon-download-svg-xs {
	width: 16px;
	height: 14px;
	display: inline-block;
	background: url('../img/download-xs.svg') no-repeat;
}

.icon-candidate-xxs {
	width: 13px;
	height: 13px;
	display: inline-block;
	background: url('../img/candidates-xxs.png') no-repeat;
}

.icon-question-xxs {
	width: 11px;
	height: 10px;
	display: inline-block;
	background: url('../img/question-xxs.png') no-repeat;
}

.icon-reset-xxs {
	width: 11px;
	height: 11px;
	display: inline-block;
	background: url('../img/reset-xxs.png') no-repeat;
}

.icon-i-bar {
	width: 19px;
	height: 16px;
	display: inline-block;
	background: url('../img/icon-bar.png') no-repeat;
}

.icon-i-bar-active {
	width: 19px;
	height: 16px;
	display: inline-block;
	background: url('../img/icon-bar-active.png') no-repeat;
}

.icon-i-clock {
	width: 19px;
	height: 16px;
	display: inline-block;
	background: url('../img/icon-bar-active.png') no-repeat;
}

.stars[data-rating="0"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -290px, 30px, 30px);
}

.stars[data-rating="1"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -261px, 30px, 30px);
}

.stars[data-rating="0.1"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, 0, 30px, 30px);
}

.stars[data-rating="0.2"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -29px, 30px, 30px);
}

.stars[data-rating="0.3"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -58px, 30px, 30px);
}

.stars[data-rating="0.4"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -87px, 30px, 30px);
}

.stars[data-rating="0.5"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -116px, 30px, 30px);
}

.stars[data-rating="0.6"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -145px, 30px, 30px);
}

.stars[data-rating="0.7"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -174px, 30px, 30px);
}

.stars[data-rating="0.8"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -203px, 30px, 30px);
}

.stars[data-rating="0.9"] {
	@extend .stars-spritesheet;
	@include img-sprite(0, -232px, 30px, 30px);
}

.icon2 {
	display: inline-block;
  width: 24px;
  height: 24px;
  stroke-width: 0;
  background-color: #fff;
	fill: #333333;
	&.icon-xs {
		width:16px;
		height: 16px;
	}
}

.icon-upvote {
	background-image: url('../img/upvote.svg');
}

.icon-video {
  background-image: url('../img/video.svg');
}

.icon-folder {
  background-image: url('../img/folder.svg');
}

.icon-folder-o {
  background-image: url('../img/folder-o.svg');
}

.icon-attempt {
  background-image: url('../img/attempt.svg');
}

.icon-record-time {
  background-image: url('../img/record-time.svg');
}

.icon-add {
  background-image: url('../img/add.svg');
}

.icon-ellipsis {
  color: #333333;
  &:hover, &:focus {
    color: $purple01;
  }
}
