/* ----------- Non-Retina Screens ----------- */
@media (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 960px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 960px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 960px) and (max-width: 1279px) {
  .container {
    max-width: 960px;
    padding: 0; } }

/* ----------- Tablet ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 720px) and (max-device-width: 960px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 720px) and (max-width: 959px) {
  .container {
    max-width: 720px;
    padding: 0; }
  #sidebar {
    position: relative; } }

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 360px) and (max-device-width: 720px) and (-webkit-min-device-pixel-ratio: 3) and (min-width: 360px) and (max-width: 719px) {
  #sidebar {
    top: 48px; } }

/* ----------- iPhone under 5 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) and (min-width: 360px) and (max-width: 719px) {
  #sidebar {
    top: 48px; } }
