/*
*
* ASTRONAUT WEB APP
* ELEMENTS
* VERSION 1.0
*
*/

h5.page-heading {
	font-size: 14px;
	font-weight: bold;
	padding: 20px 10px 10px;
	color: $body-text;
}

/*
* CUSTOM FORM
*/
.error {
	color: $error;
	font-size: 12px;
	max-width: 100%;
}

.form-control.error {
	border: 1px solid $error;
	color: $error;
}

/*
* CUSTOM SELECT
*/
.select-custom {
	display: block;
	height: 36px;
	line-height: 1.42857;
	padding: 9px 20px;
	@include rounded($small-rounded);
	font-size: 12px;
	font-weight: bold;
	border: 0;
	@include all-transition(0.3s,0s);
	&.select-transparent {
		background-color: transparent;
		color: #343434;
		&:hover, &:focus {
			background-color: transparent;
			color: #4B4F85;
		}
	}
	&.select-grey {
		background-color: #D8D8D8;
		color: #4B4F85;
		&:hover, &:focus {
			background-color: #4B4F85;
			color: $white;
		}
	}
	&.select-dark-green {
		background-color: #314B5A;
		color: #FFF;
		&:hover, &:focus {
			background-color: #2B2E4D;
			color: $white;
		}
	}
	.caret {
		margin-left: 6px;
		border-top: 5px dashed;
		border-top: 5px solid \9;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
	}
}

.select2 {
	.Select-control {
		@include all-transition(0.3s,0s);
		border: 0;
		width: 160px;
		max-width: 95vw;
		cursor: pointer;
		.Select-input:focus {
			background: transparent;
		}
	}
	&.is-disabled {
		.Select-arrow-zone {
			display: none;
		}
	}
	&.select-grey {
		.Select-control {
			background-color: #CCCCCC;
			.Select-placeholder {
				color: #333333;
				font-size: 16px;
				font-weight: normal;
				font-family: "Open Sans", Helvetica;
			}
			.Select-value {
				color: #333333;
				font-size: 16px;
				max-width: 90%;
				.Select-value-label {
					color: #333333 !important;
					font-size: 16px;
					font-weight: normal;
					font-family: "Open Sans", Helvetica;
				}
			}
			.Select-arrow {
				border-color:#4B4F85 transparent transparent;
			}
			&:hover, &:focus {
				background-color: #4B4F85;
				color: $white;
				.Select-placeholder {
						color: $white;
						font-size: 16px;
						font-weight: normal;
						font-family: "Open Sans", Helvetica;
				}
				.Select-arrow {
					border-color:#fff transparent transparent;
				}
				.Select-value {
					color: $white;
					.Select-value-label {
						color: $white !important;
					}
				}
			}
		}
	}
	&.select-dark-green {
		.Select-control {
			background-color: #314B5A;
			.Select-placeholder {
				color: #4B4F85;
				font-size: 12px;
				font-weight: bold;
			}
			.Select-value {
				color: #4B4F85;
				font-size: 12px;
				.Select-value-label {
					color: #4B4F85 !important;
					font-size: 12px;
				}
			}
			.Select-arrow {
				border-color:#4B4F85 transparent transparent;
			}
			&:hover, &:focus {
				background-color: #2B2E4D;
				color: $white;
				.Select-placeholder {
					color: $white;
					font-size: 12px;
					font-weight: bold;
				}
				.Select-arrow {
					border-color:#fff transparent transparent;
				}
				.Select-value {
					color: $white;
					.Select-value-label {
						color: $white !important;
					}
				}
			}
		}
	}
	&.select-white {
		.Select-control {
			background-color: $white;
			border: 1px solid #D8D8D8;
			width: 100%;
			.Select-placeholder {
				color: #555;
				font-size: 12px;
				font-weight: bold;
			}
			.Select-value {
				color: #555;
				font-size: 12px;
				.Select-value-label {
					color: #555 !important;
					font-size: 12px;
				}
			}
			.Select-arrow {
				border-color:#4B4F85 transparent transparent;
			}
		}
		&.no-arrow {
			.Select-control .Select-arrow {
				display: none;
			}
		}
	}
	&.select-transparent {
		.Select-control {
			.Select-value {
				padding-left: 0;
				.Select-value-label {
					font-size: 32px;
					font-weight: bold;
				}
			}
		}
	}
}

.select-box {
	margin: 0 10px 30px;
}

.select3 {
	@include selectBox(#333333, #dddddd);
}

.select3-transparent {
	@include selectBox(transparent, transparent);
}

/*
* CUSTOM CHECKBOX
*/

.inline-check {
	font-weight: normal;
	input[type="checkbox"] {
		vertical-align: text-top;
	}
}

/*
* CUSTOM ELEMENT WRAPPER
*/

.p-wrap {
	white-space: pre-line;
}

/*
* LABELS
*/

.label {
	padding: 2px 15px;
	font-size: 12px;
	&.label-share {
		font-size: 12px;
		color: #5CA7DA;
		border: 1px solid #6BB5E8;
		padding: 5px 10px;
		@include rounded($small-rounded);
		cursor: normal;
		&:hover{
			color: #5CA7DA;
		}
	}
}


/*
* PAGINATION
*/

.paginations-wrapper {
	padding: 20px 15px 10px 0;
	text-align: right;
	.btn-group > {
		.btn {
			background: transparent;
			box-shadow: none;
			&:first-child:not(:last-child):not(.dropdown-toggle), &:last-child:not(:first-child) {
				font-size: 14px;
		    background-color: #E0E0E0;
		    border-radius: 0;
		    padding: 3px 9px;
			}
			&:hover, &:focus {
				color: #5272D1;
			}
		}
		.dropdown-toggle:not(:first-child) {
			background: transparent;
		}
		.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
			background: transparent;
			padding: 3px 6px;
			box-shadow: none;
			font-size: 14px;
			color: $dark-grey;
			&:active, &.active, &:hover, &:focus {
				color: $white;
		    font-weight: bold;
		    background-color: $purple01;
			}
		}
	}
}

/*
* PROGRESS BAR
*/

.progress-page {
	display: inline-block;
	margin-right: 15px;
	font-size: 14px;
	vertical-align: middle;
}

/*
* CUSTOM ALERT BAR
*/

.alertbar {
	padding: 20px 10px;
	line-height: 20px;
	@include rounded($medium-rounded);
	margin-bottom: 15px;
	&.alertbar-success {
		background-color: #F7F1CC;
	}
}

/*
* CUSTOM BASIC FORM
*/

.form-control {
	&.form-xs {
		width: 10%;
	}
	&.form-sm {
		width: 30%;
	}
	&.form-md {
		width: 50%;
	}
}

/*
* SEARCH
*/

.search {
	width: 300px;
	@include all-transition(0.3s,0s);
	border: 1px solid transparent;
	box-shadow: none;
	background: $white url(../img/icon-search.png) no-repeat 8px 6px;
	padding: 0 5px 0 40px;
	border-radius: 100px;
	-webkit-border-radius: 100px;
}

.square-search {
	@include all-transition(0.3s,0s);
	@include rounded($medium-rounded);
	border: 1px solid #ddd;
	box-shadow: none;
	background: $white url(../img/icon-search.png) no-repeat 8px 6px;
	padding: 0 5px 0 40px;
}

.search.grey {
	background: #E0E0E0 url(../img/icon-search.png) no-repeat 8px 6px;
}

/*
* CUSTOM BADGE
*/

.badge {
	display: inline-block;
	min-width: 10px;
	padding: 4px 7px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #777;
	border-radius: 10px;
}

.badge-primary {
		background-color: $navy;
		color: $white;
}

/*
* CUSTOM TOOLTIP
*/

.tooltip {
	white-space: pre-line;
}

/*
* CUSTOM REACT BOOTSTRAP TABLE
*/

.react-bs-table-container .react-bs-table-search-form {
	margin-bottom: 0;
}

.react-bs-table {
	border: 1px solid #ddd;
	@include rounded($medium-rounded);
	table {
		margin-bottom: 0;
		table-layout: fixed;
		th {
			overflow: visible;
			white-space: normal;
			text-overflow: initial;
		}
		td {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	margin: 10px;
}

.react-bs-table-pagination {
	margin: 10px;
}

.react-bs-table-tool-bar {
	margin: 10px 10px 0 10px;
}

.react-bs-container-header {
	overflow: hidden;
	width: 100%;
}

.react-bs-container-body {
	/*height: 154px;*/
	overflow: auto;
	width: 100%;
}

.react-bs-table {
	.table-bordered {
		border: 0;
		> {
			thead > tr > {
				th, td {
					border-bottom-width: 2px;
				}
			}
			tfoot > tr > {
				th, td {
					border-top-width: 2px;
				}
				th, td {
					border-bottom-width: 0;
				}
			}
			thead > tr > th:first-child, tbody > tr > th:first-child, tfoot > tr > th:first-child, thead > tr > td:first-child, tbody > tr > td:first-child, tfoot > tr > td:first-child {
				border-left-width: 0;
			}
			thead > tr > th:last-child, tbody > tr > th:last-child, tfoot > tr > th:last-child, thead > tr > td:last-child, tbody > tr > td:last-child, tfoot > tr > td:last-child {
				border-right-width: 0;
			}
			thead > tr:first-child > {
				th, td {
					border-top-width: 0;
				}
			}
			tfoot > tr:last-child > {
				th, td {
					border-bottom-width: 0;
				}
			}
		}
	}
	.react-bs-container-header {
		> table > thead > tr > th {
			vertical-align: middle;
			.filter {
				font-weight: normal;
			}
			.select-filter {
				option[value=''], &.placeholder-selected {
					color: light-grey;
					font-style: italic;
				}
			}
			.filter::-webkit-input-placeholder, .number-filter-input::-webkit-input-placeholder {
				color: light-grey;
				font-style: italic;
			}
			.select-filter.placeholder-selected option:not([value='']) {
				color: initial;
				font-style: initial;
			}
			.number-filter, .date-filter {
				display: flex;
			}
			.number-filter-input, .date-filter-input {
				margin-left: 5px;
				float: left;
				width: calc(100% - 67px - 5px);
			}
			.number-filter-comparator, .date-filter-comparator {
				width: 67px;
				float: left;
			}
		}
		.sort-column {
			cursor: pointer;
		}
	}
}

.react-bs-container {
	.textarea-save-btn {
		position: absolute;
		z-index: 100;
		right: 0;
		top: -21px;
	}
}

.react-bs-table-no-data {
	text-align: center;
}

/*
* NProgress
*/

#nprogress .bar {
	z-index: 9999;
}

/*
* CUSTOM TABLE
*/

.custom-table .table-bordered > {
	thead > tr > th:first-child, tbody > tr > th:first-child, tfoot > tr > th:first-child, thead > tr > td:first-child, tbody > tr > td:first-child, tfoot > tr > td:first-child {
		border-left-width: 0;
	}
}

.custom-table .table-percandidate p {
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
	max-width: 200px;
}

.js-candidate-field-editable {
	color: #343434;
	width: 100%;
	display: inline-block;
	@include ellipsis(120px);
	&:empty:after {
		content: "Click to edit";
		border-bottom: 1px dashed #ccc;
		color: #b3b3b3;
		text-transform: lowercase;
	}
}

.editable-profile {
	&.loading span {
		border-color: $light-green;
	}
	&:empty:after {
		content: "Click to edit";
		border-bottom: 1px dashed #ccc;
		color: #b3b3b3;
	}
}

.react-bs-table table td:hover {
	white-space: normal;
	overflow-x: auto;
}

.progress-float {
	position: fixed;
	left: 20px;
	bottom: 20px;
	width: 500px;
	z-index: 101;
}

/*
* CUSTOM REACT-SELECT, TO ADD OPTGROUPS ON IT
*/

.Select-menu-outer {
	.Select-option {
		font-size: 12px;
	}
	.Select-option.is-disabled {
		cursor: default;
		padding-left: 1em;
		font-weight: bold;
		color: #4F4F4F;
	}
}

/*
* CUSTOM GRIDS
*/

.wrapper-grid {
	margin-top: 20px;
	@include rounded(5px);
}

.col-12 {
	width: 100%;
}

.col-6 {
	width: 50%;
}

.col-5 {
	width: 40%;
}

.col-4 {
	width: 35%;
}

.col-3 {
	width: 25%;
}

.col-2 {
	width: 15%;
}

.col-1 {
	width: 7.25%;
}

.sort-arrow--down {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #637282;
}

.sort-arrow--up {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid #637282;
}

.sorting {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	width: 0;
	height: 0;
	display: inline-block;
	margin: 0 0 2px 8px;
}

.grids {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	.grid-content {
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-width: 420px;
		margin-left: 40px;
		&.hide-checkbox {
			margin-left: 0;
		}
		.select-checkbox {
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			position: absolute;
			width: 30px;
			height: 100%;
			left: -28px;
			top: 0;
			cursor: default;
			&.show-boxes {
				.btn-checkbox, .btn-checkbox-minus {
					opacity: 1;
				}
			}
			.btn-checkbox, .btn-checkbox-minus {
				opacity: 1;
				display: block;
				cursor: pointer;
			}
		}
	}
	.grid-list-header {
		min-height: 40px;
		.grid-list-header__title {
			position: relative;
			margin-left: -40px;
			padding-left: 40px;
			box-sizing: border-box;
			top: auto;
			background: #fff;
			box-sizing: border-box;
			min-height: 40px;
			padding-top: 0;
			&.hide-grid-list {
				margin-left: 0;
				padding-left: 0;
			}
			li {
				position: relative;
				vertical-align: middle;
				&.column-header {
					display: inline-block;
					margin-bottom: 0;
					.column-header__button {
						background: transparent;
						border: 0;
						font-size: 14px;
						color: #37417D;
						font-weight: bold;
						padding: 0;
					}
				}
			}
			&:hover, &:focus {
				.btn-checkbox, .btn-checkbox-minus {
					opacity: 1;
				}
			}
		}
	}
	.grid-list-body {
		position: relative;
		.grid-list-body__wrap {
			top: auto;
			min-width: 420px;
			display: block;
			position: relative;
			.grid__row {
				height: 55px;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				padding: 0;
				position: relative;
				margin-bottom: -1px;
				box-sizing: border-box;
				list-style: none;
				border-top: 1px solid #E6E8EB;
				border-bottom: 1px solid #E6E8EB;
				border-left: 1px solid transparent;
				border-right: 1px solid transparent;
				.row__gridcell {
					color: #637282;
					line-height: 1.4;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-webkit-flex-direction: column;
					-ms-flex-direction: column;
					flex-direction: column;
					-webkit-box-pack: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: center;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					.content-cell {
						@include ellipsis(100%);
						a {
							font-weight: 600;
						}
						&.no-overflow {
							overflow: visible;
						}
						.select2 {
							max-width: 150px;
						}
					}
				}
				&:hover, &:focus {
					.btn-checkbox {
						opacity: 1;
					}
				}
			}
		}
	}
	&.no-checkbox {
		.grid-content {
			margin: 0;
		}
		.grid-list-header__title {
			margin: 0;
			padding-left: 0;
		}
	}
}

/*
* LABELS
*/

.label-full-time {
  background-color: #51BEBF;
}

.label-part-time {
  background-color: #9F98E6;
}

.label-contract {
  background-color: #EC9E4E;
}

.label-internship {
  background-color: #4EA8EC;
}

.label-fellowship {
  background-color: #EF6C00;
}

.label-grey {
	border-radius: 10px;
	background-color: #BDBDBD;
	a {
		color: $white;
	}
}

/*
* CUSTOM ALERT
*/

.sweet-alert {
	padding: 16px 16px 32px;
	.sa-icon {
		&.sa-warning {
			width: 60px;
			height: 60px;
			.sa-body {
				height: 28px;
			}
		}
	}
	h2 {
		font-size: 24px;
		margin: 20px 0 10px;
	}
	button {
		font-size: 18px;
		padding: 8px 32px;
		&.cancel {
			background-color: $white;
			color: $purple01;
			border: 1px solid $purple01;
			&:hover {
				background-color: $white;
		    border-color: $purple02;
		    border-style: solid;
			}
		}
	}
	p {
		max-width: 320px;
    text-align: center;
    margin: 0 auto;
	}
}

/*
* CUSTOM FORM TAGGING
*/

.comment-form__input--multiLine, .comment-form__input, .comment-form__highlighter {
	display: block;
	position: relative;
  width: 100% !important;
  min-height: 76px !important;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-image: none;
  border: 1px solid #ccc;
  @include rounded(4px);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	word-break: break-word;
}

.comment-form {
	.media-body {
		overflow: initial !important;
	}
	&__suggestions {
		top: 13px !important;
	}
	&__suggestions__list {
		border: 1px solid $border-color;
	}
	&__suggestions__item {
		padding: 4px 10px;
		&--focused {
			background-color: #337ab7;
			color: $white;
		}
	}
}
.tree {
	float: right;
  width: 210px;
  margin-right: 32px;
}

[role="tree-item"] .items  {
	background: #ffffff;
	border: 2px solid rgba(216, 216, 216, 0.8);
	width: 100%;
	border-radius: 6px;
	color: #929292;
	margin: 10px 5px;
	box-shadow: 0 3px 2px rgba(0, 0, 0, 0.05);
	position: relative;
	display: block;
	cursor: pointer;
	.sticky {
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 99;
		visibility: hidden;
	}
	h3 {
		margin: 0;
		padding: 8px 15px;
		font-size: 12px;
		font-weight: normal;
		line-height: 18px;
		position: relative;
		z-index: 9;
		@include ellipsis(160px);
	}
	&:hover, &:focus, &.selected {
		border: 2px solid $purple;
		background: $purple;
		.sticky {
			z-index: 99;
			visibility: visible;
		}
		h3 {
			color: $white;
		}
	}
	&.active {
		border-color: $purple;
	}
}

[role="group"] {
	margin-left: 20px;
}

/*
* CUSTOM TABLE
*/

.custom-table {
	th {
		background-color: #6472C7;
		color: #fff;
	}
}

/*
* CUSTOM SWITCHER
*/

.switcher {
  position: relative;
  outline: 0;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin: 0 0 5px 0;
  font-size:24px;

  // add default box-sizing for this scope
  &, &:after, &:before,
  & *, & *:after, & *:before,
  & + .switcher-btn {
    box-sizing: border-box;
    &::selection{
      background: none;
    }
  }
	span {
    position: relative;
    display: block;
    height: 1.8em;
    line-height: 1.2em;
    overflow: hidden;
    font-weight: normal;
    text-align: center;
    border-radius: 2em;
    padding: 0.2em 1em;
    border: 1px solid darken($switcher-enabled-tab-bg, 2);
    box-shadow:
    inset 0 2px 0 rgba(0,0,0,.2),
    0 2px 0 rgba(255,255,255,0.7);

    transition:
      color 0.3s ease,
      padding 0.3s ease-in-out,
      background 0.3s ease-in-out;

    &:before {
      position: relative;
      display: block;
      line-height: 1.3em;
      padding: 0 0.2em;
      font-size: 1em;
    }

    &:after {
      position: absolute;
      display: block;
      content: '';
      border-radius: 2em;
      width: 1.3em;
      height: 1.3em;
      margin-left: -1.45em;
      top: 0.2em;
      background: $switcher-enabled-tab-bg;
      transition:
        left 0.3s cubic-bezier( 0.175, 0.885, 0.320, 0.97 ),
        background 0.3s ease-in-out;

    }

  }

  input[type="checkbox"]{

    display: none !important;

    // Off
    &:not(:checked) {
      + span {
        background: $switcher-disabled-bg;
        color: $switcher-disabled-color;
        padding-left: 1.6em;
        padding-right: 0.4em;
        &:before {
          content: "\f10b";
          font-family: fontawesome;
          color: $switcher-disabled-color;
					font-size: 32px;
					line-height: 1em;
        }
        &:after {
          background: $switcher-disabled-tab-bg;
          left: 1.6em;
        }
      }
    }

    // On
    &:checked {
      + span {
        background: $switcher-enabled-bg;
        color: $switcher-enabled-color;
        padding-left: 0.4em;
        padding-right: 1.6em;
        &:before {
          content: "\f108";
          font-family: fontawesome;
        }
        &:after {
          background: $switcher-enabled-tab-bg;
          left: 100%;
        }
      }
    }
  }
}

/*
* CUSTOM PRIMARY FORM
*/

.primary-form {
	.form-control {
		border-color: $form-basic-field;
		&:focus {
			border-color: $form-active-field;
			box-shadow: none;
		}
	}
	.has-error .form-control {
		color: $form-error-field;
		border-color: $form-error-field;
	}
	.form-group .error {
		color: $form-error-field;
	}
}

/*
* CUSTOM SWITCHER TABS
*/

ul.tabs.custom-tabs {
  margin: 0;
  list-style-type: none;
  line-height: 35px;
  max-height: 38px;
  overflow: hidden;
  display: inline-block;
	padding-right: 10px;
	padding-left: 12px;

  li {
    &.active {
      z-index: 2;
      background: #FFFFFF;
      &:before {
        border-color: transparent #efefef transparent transparent;
      }
      &:after {
        border-color: transparent transparent transparent #efefef;
      }
			a {
				color: $dark01;
				font-weight: bold;
			}
    }
    float: right;
    margin: 5px -10px 0;
    border-top-right-radius: 20px 90px;
    border-top-left-radius: 20px 90px;
    padding: 0 30px 0 25px;
    height: 170px;
    background: #BDBDBD;
    position: relative;
    max-width: 210px;
		border: 1px solid #BDBDBD;
    > a {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      color: #868585;
    }
    &:before {
			border-color: transparent #ddd transparent transparent;
			-webkit-transform: rotate(48deg);
			-ms-transform: rotate(48deg);
			transform: rotate(48deg);
      left: -23px;
    }
    &:after {
      border-color: transparent transparent transparent #ddd;
      -webkit-transform: rotate(-48deg);
			-ms-transform: rotate(-48deg);
			transform: rotate(-48deg);
      right: -17px;
    }
		&:first-child {
			border-top-right-radius: 0;
			box-shadow: -7px 10px 20px rgba(0,0,0,0.2);
		}
  }
	.clearfix:before, .clearfix:after { content: ""; display: table; }
	.clearfix:after { clear: both; }
	.clearfix { zoom: 1; }
}

/*
* CUSTOM TABS WIZARD
*/

.tabs-add-candidates {
  ul.nav.nav-tabs > li {
    background-color: #030A10;
    &.active {
      border: 0;
      background: #3B7895;
      > a {
        background-color: #3B7895;
        color: $white;
        &:hover,&:focus {
          background-color: #3B7895;
          border: 0;
        }
      }
    }
    > a {
      @include rounded($none-rounded);
      border: 0;
      background-color: #030A10;
      font-size: 14px;
      font-weight: 600;
      color: $white;
		}
  }
}

.tabs-add-candidates ul.nav-wizard li {
	&::before {
		position: absolute;
    display: block;
    border: 24px solid transparent;
    border-left: 16px solid #ffffff;
    border-right: 0;
    top: -1px;
    z-index: 10;
    content: '';
    right: -16px;
	}
	&::after {
		position: absolute;
		display: block;
		border: 24px solid transparent;
		border-left: 16px solid #030a0f;
		border-right: 0;
		top: -1px;
		z-index: 10;
		content: '';
		right: -15px;
	}
	&.active::after {
		border-left: 16px solid #3c7895;
	}
	&:last-child {
		&::before {
			border: 0;
		}
		&::after {
			border: 0;
		}
	}
}


.custom-label {
		display: inline-block;
    padding: 2px 12px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 10px;
		color: $white;
	&.sourced {
		background-color: #2A3788;
	}
	&.applied {
		background-color: $purple;
	}
	&.shortlisted {
		background-color: #58A5C9;
	}
	&.offer {
		background-color: #70C780;
	}
	&.disqualified {
		background-color: $red;
	}
}

/*
* STAR RATING
*/

.star-rating {
  border: none;
  float: left;
  > {
    input {
      display: none;
    }
    label:before {
      margin: 5px;
      font-size: 1.5em;
      font-family: FontAwesome;
      display: inline-block;
      content: "\f005";
			cursor: pointer;
    }
    .half:before {
      content: "\f089";
      position: absolute;
    }
    label {
      color: #ddd;
      float: right;
    }
    input:checked ~ label {
      color: #FFC107;
    }
  }
  &:not(:checked) > label:hover {
    color: #FFC107;
    ~ label {
      color: #FFC107;
    }
  }
  > {
    input:checked {
      + label:hover, ~ label:hover {
        color: #FFC107;
      }
    }
    label:hover ~ input:checked ~ label, input:checked ~ label:hover ~ label {
      color: #FFC107;
    }
  }
	&--result {
		width: 150px;
		height: 32px;
		display: inline-block;
		background: url('../img/star-inactive.png') repeat;
		position: relative;
		margin-left: 10px;
		.current-rating {
			height: 32px;
			position: absolute;
		}
	}
}

.box-action {
	.counted {
		margin: 0 0 0 10px;

	}
}

/*
* LIST ITEM
*/

.list {
	padding: 6px 20px;
	@at-root #{&}__box {
		padding: 6px 20px;
		li {
			position: relative;
			padding: 6px 0;
			&.list__heading {
				padding: 0;
			}
			@at-root #{&}--dense {

			}
			&.question__item {
				padding: 12px 0;
			}
		}
		@at-root #{&}--bordered {
			> li {
				@extend .border-bottom;
			}
		}
	}
	@at-root #{&}__box-footer {
		padding: 6px 20px;
	}
	@at-root #{&}__well {
		@at-root #{&}--dense {
			margin: 12px 12px 6px;
			.well-tab:first-child:nth-last-child(1) {
			    @include rounded(8px);
			}
			.well-tab:first-child:nth-last-child(2) {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
			.well-tab:first-child:nth-last-child(2) ~ .well-tab:last-child {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
	@at-root #{&}__question-item {
		cursor: move;
		padding-bottom: 12px;
		border-bottom: 1px solid $border-color-lighter;
		margin-bottom: 12px;
		min-width:200px;
	}
	@at-root #{&}__section-panel {
		.panel-title[aria-expanded="true"] {
			.list__panel-container {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.panel-title[aria-expanded="false"] {
			.list__panel-container {
				border: 1px solid $dark03;
			}
		}
		.panel-body {
			padding: auto 0;
			border: 1px solid $dark03;
			border-top: 0;
			.list__section-item li:last-child .list__question-item {
				margin-bottom: 0;
				border-bottom: 0;
			}
			.well-tab {
				margin: -15px -15px 20px;
				border-radius: 0;
				@include rounded(0);
			}
		}
	}
	@at-root #{&}__panel-container {
		background-color: $white;
		padding:6px 12px;
		border: 1px solid $dark03;
		border-bottom: 0;
		@include rounded(6px);
		&.active {
			border-color: $purple01;
			background-color: $purple01;
			color: $white;
		}
	}
}

/*
* ACTION BOX
*/

.action__box {
	padding: 16px 20px;
	margin: 0 auto;
	text-align: center;
}

/*
* CARD
*/

.card {
  background: $white;
  border-radius: 4px;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.19), 1px 8px 20px 4px rgba(169, 165, 165, 0.12);
  padding: 12px 24px;
  border: 0;
}

/*
* FOLDERS
*/

.folder__title {
	@include ellipsis(135px);
	display: inline-block;
}

/*
* INDICATORS
*/

div[class~="indicator"] {
	.navbar-fixed__content  {
		margin-top: 223px;
	}
}

.indicator {
	height: 56px;
	background-color: $purple01;
	color: $white;
	a {
		color: $white;
	}
}

.indicator-warning {
	background-color: $yellow;
	color: $white;
}

.indicator-danger {
	background-color: $bg-red;
	color: $white;
}

.indicator-info {
	background-color: $purple01;
	color: $white;
}

/*
* CREDIT
*/

.credit span {
	font-size: 72px;
}

/*
* PRICING
*/
.pricing {
	@at-root {
		#{&}__container {
			@extend .flex;
			@extend .flex-center;
			padding-top: 32px;
		}
		#{&}__box {
			width: 100%;
			max-width: 388px;
			margin: 8px;
			min-height: 454px;
			text-align: center;
			@extend .vertical-item--between;
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			padding: 24px 24px 42px;
		}
		#{&}-body {
			padding: 36px 24px;
			text-align: left;
		}
		.pricing-head {
			padding-top: 32px;
			span {
				font-size: 72px;
				font-weight: bold;
				display: block;
			}
			.quote {
				display: inline-block;
				border-bottom: 1px solid $border-color;
				padding: 8px 25px 12px;
				text-transform: uppercase;
				margin-bottom: 20px;
				font-size: 12px;
			}
		}
		.recommended {
			position: absolute;
	    width: 100%;
	    background-color: $purple01;
	    color: #fff;
	    letter-spacing: 5px;
	    text-transform: uppercase;
	    padding: 14px 0;
	    font-size: 16px;
	    margin: -30px -20px;
	    border-radius: 5px 5px 0 0;
		}

	}
}

.pricing-help-block {
	width: 100%;
	max-width: 1224px;
	padding: 20px 73px;
	margin: 0;
}

.pricing-botom {
	@extend .horizontal-item--start;
	width: 100%;
}

.pricing-faq {
	width: 64.5%;
	max-width: 789px;
	margin: 8px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.pricing-contact {
	width: 32%;
	max-width: 386px;
	margin: 8px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

/*
* SLICK SLIDER
*/

.slick-onboarding {
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.slick-dots {
		position: absolute;
		bottom: 20px;
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
		li {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;
			padding: 0;
			cursor: pointer;
			button {
				font-size: 0;
				line-height: 0;
				display: block;
				width: 20px;
				height: 20px;
				padding: 5px;
				cursor: pointer;
				color: transparent;
				border: 0;
				outline: none;
				background: transparent;
				&:hover, &:focus {
					outline: none;
				}
				&:hover:before, &:focus:before {
					opacity: 1;
				}
				&:before {
					font-family: 'FontAwesome';
					content: '\F10C';
					font-size: 14px;
					line-height: 20px;
					position: absolute;
					top: 0;
					left: 0;
					width: 20px;
					height: 20px;
					text-align: center;
					color: $purple01;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
			&.slick-active button:before {
				color: $purple01;
				content: '\F111';
			}
		}
	}
	.slick-slide {
	  margin: 0;
	}
}

.boarding-item {
  background: $white;
  position: relative;
  text-align: center;
  padding: 20px 20px 60px;
}

/*
* ARROWS
*/
.slick-onboarding {
	.slick-prev, .slick-next {
	  font-size: 0;
	  line-height: 0;
	  position: absolute;
	  top: 50%;
	  display: block;
	  width: 20px;
	  height: 20px;
	  padding: 0;
	  -webkit-transform: translate(0, -50%);
	  -ms-transform: translate(0, -50%);
	  transform: translate(0, -50%);
	  cursor: pointer;
	  color: transparent;
	  border: none;
	  outline: none;
	  background: transparent;
	}

	.slick-prev {
	  &:hover, &:focus {
	    color: transparent;
	    outline: none;
	    background: transparent;
	  }
	}

	.slick-next {
	  &:hover, &:focus {
	    color: transparent;
	    outline: none;
	    background: transparent;
	  }
	}

	.slick-prev.slick-disabled, .slick-next.slick-disabled {
	  background-image: none;
	  &:hover, &:focus {
	    background-image: none;
	  }
	}

	.slick-prev:before, .slick-next:before {
	  font-family: 'slick';
	  font-size: 20px;
	  line-height: 1;
	  opacity: .75;
	  color: white;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	}

	.slick-prev {
	  left: -30px;
	  z-index: 1;
	  background-image: url(../img/prev.png);
	  position: absolute;
	  width: 60px;
	  height: 60px;
	  &:hover, &:focus {
	    background-image: url(../img/prev.png);
	  }
	}

	.slick-next {
	  right: -30px;
	  z-index: 1;
	  background-image: url(../img/next.png);
	  position: absolute;
	  width: 60px;
	  height: 60px;
	  &:hover, &:focus {
	    background-image: url(../img/next.png);
	  }
	}
	.slick-prev:before,
	.slick-next:before {
		content: '';
	}
}

/*
* SOCIAL MEDIA
*/

.social-media {
	display: block;
	i {
		color: #333;
	}
	.icons {
		background-color: $white;
		width: 40px;
		height: 40px;
		@extend .horizontal-item--center;
		@include rounded(50%);
		margin-right: 12px;
	}
}

.footer-link-heading {
	font-weight: bold;
}

.footer-link-download {
	display: block;
}

.main-footer .download-box .googleplay, .main-footer .download-box .appstore {
	width: 100px;
  max-width: 99px;
	height: 29.5px;
  background-size: contain;
  display: inline-block;
}

/*
* LIST
*/

.checked-list {
	li {
		padding-bottom: 12px;
		&:before {
			font-family: 'FontAwesome';
			content: '\F00C';
			font-size: 16px;
			display: block;
			height: 16px;
			width: 16px;
			position: absolute;
			margin-left: -20px;
		}
	}
}

.brand-container {
	img {
		max-height: 200px;
    max-width: 300px;
		width: 100%;
	}
}

.table-transaction {
	margin-bottom: 40px;
	td {
		line-height: 32px;
	}
}

.form-vertical {
	input {
		display: block;
	}
}

.touch-spinner {
	.input-spinner {
		border-color: $dark03;
	}
	.input-group-btn:first-child > button {
		border-bottom-right-radius: 0;
    border-top-right-radius: 0;
		border-right: 0;
	}
	.input-group-btn:last-child > button {
		border-bottom-left-radius: 0;
    border-top-left-radius: 0;
		border-left: 0;
	}
}

/*
* PAYMENT
*/
.payment-complete {
	p {
		font-size: 16px;
    line-height: 23px;
    font-weight: normal;
	}

	button {
		font-weight: 600;
    font-size: 14px;
    margin: 25px 0 25px 0;
    padding: 8px 10px 8px 10px;
	}
}

.payment-outstanding {
	h3 {
		font-size: 24px;
	}
	p {
		font-size: 16px;
    line-height: 1.5;
    font-weight: normal;
    margin: 0 0 24px 0px;
	}
	button {
	  font-size: 14px;
	  font-weight: normal;
	  text-align: center;
	  color: #ffffff;
	  margin-top: 36px;
	  margin-bottom: 65px;
	}
	table {
		font-size: 16px;
		line-height: 1.5;
		font-weight: normal;
		width: 100%;
		td:nth-child(1) {
			width: 23%;
		}
		td:nth-child(2) {
			width: 2%;
		}
		margin-bottom: 24px;
	}
}

/*
* COMPANY DASHBOARD
*/
.transaction-history {
	padding: 30px;

	table {
		thead {
			tr {
				border-bottom: 1px solid black;
				line-height: 50px;
			}
		}

		tbody {
			tr {
				line-height: 45px;
			}
		}

		a {
			text-decoration: underline;
			color: #333;
		}
	}
}

.transaction-documents {
	a {
		text-decoration: underline;
		color: #333;
	}
}

.table-transaction-dashboard {
	tr:nth-child(n+2) {
		td:last-child {
			text-align: end;
		}
	}
}

.transaction-details {
	padding: 24px 19px 36px 24px;
	.total {
		margin-bottom: 15px;
		font-size: 18px;
	}
	.order {
		margin-top: 48px;
		margin-bottom: 6px;
		font-size: 18px;
	}
	a {
		font-size: 12px;
		color: #333;
		text-decoration: underline;
	}
	table {
		td {
			line-height: 32px;
			width: 10%;
		}

		td:nth-child(2) {
			text-align: end;
		}

		tr:nth-child(3) {
			border-bottom: solid 1px #707070;
		}
	}
}
