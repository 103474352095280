/*
* TYPOGRAPHY
*/

#public {
	.heading1 {
		font-size: 32px;
		line-height: 42px;
		font-weight: bold;
		&.larger {
			font-size: 42px;
			line-height: 52px;
			font-weight: bold;
		}
	}

	.heading2 {
		font-size: 24px;
		line-height: 34px;
		font-weight: bold;
	}

	.heading3 {
		font-size: 22px;
		line-height: 32px;
		font-weight: bold;
	}

	.heading4 {
		font-size: 18px;
		line-height: 28px;
		font-weight: normal;
	}

	.heading5 {
		font-size: 16px;
		line-height: 26px;
		font-weight: 600;
	}

	.heading6 {
		font-size: 14px;
		line-height: 24px;
		font-weight: normal;
	}

	.paragraph {
		font-size: 12px;
		line-height: 18px;
		font-weight: normal;
	}

	p {
		font-size: 12px;
		line-height: 18px;
		font-weight: normal;
	}

	.p-error-page {
		font-size: 19px;
	}

	.bold {
		font-weight: bold;
	}

	.semibold {
		font-weight: 600 !important;
	}

	.normal {
		font-weight: normal !important;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline
	}
}
