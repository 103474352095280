/*
*
* ASTRONAUT WEB APP
* TYPOGRAPHY
* VERSION 1.0
*
Block component
.block {}

Element that depends upon the block
.block__price {}

Modifier that changes the style of the block
.block--orange {}
.block--big {}

*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}

a {
  color: $purple01;
  &:hover, &:focus {
    color: $purple02;
  }
}
.heading1, .heading2, .heading3, .heading4, .heading5, .heading6 {
  margin: 0;
}

.heading1 {
  font-size: 32px;
  line-height: 52px;
  font-weight: bold;
  &.larger {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
  }
}

.heading2 {
  font-size: 24px;
  line-height: 38px;
  font-weight: bold;
}

.heading3 {
  font-size: 22px;
  line-height: 36px;
  font-weight: bold;
}

.heading4 {
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
}

.heading5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.heading6 {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
}

.heading6-center {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  text-align: center;
}

p {
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
}

.purple {
	color: $purple;
}

.unbold {
  font-weight: normal !important;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600 !important;
}

.normal {
  font-weight: normal !important;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline
}

.text-purple {
  color: $white !important;
	&:hover, &:focus {
    color: $purple02 !important;
  }
}

.text-violet {
  color: $purple02 !important;
	&:hover, &:focus {
    color: $purple02 !important;
  }
}

.text-inherit {
  color: $text-color !important;
	&:hover {
		color: $text-color !important;
	}
}

.text-link {
  color: $text-color !important;
	&:hover {
		color: #0026a8 !important;
	}
}

.text-delete {
  color: #A1A1A1;
  &:hover, &:focus {
    color: $red;
  }
}

.text-success {
	color: $light-green;
}

.react-bs-table-del-btn {
	.glyphicon {
		display: inline-block;
	  font: normal normal normal 14px/1 FontAwesome;
	  font-size: inherit;
	  text-rendering: auto;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
		top: -1px;
	}

	.glyphicon-trash:before {
	  content: "\f1f8";
	}
}

.readable {
  li {
    margin-bottom: 24px;
  }
  b, p {
    font-size: 16px;
  }
  p {
    line-height: 24px;
    padding: 10px 0 0;
  }
}

#theme-purple {
	.text {
		font-size: 16px;
		line-height: 24px;
		margin: 0;
		@at-root #{&}--42 {
			font-size: 42px;
			font-weight: bold;
			line-height: 52px;
		}
		@at-root #{&}--32 {
			font-size: 32px;
			font-weight: bold;
			line-height: 52px;
		}
		@at-root #{&}--24 {
			font-size: 24px;
			font-weight: regular;
			line-height: 38px;
		}
		@at-root #{&}--22 {
			font-size: 22px;
			font-weight: 600;
			line-height: 36px;
		}
		@at-root #{&}--18 {
			font-size: 18px;
			font-weight: regular;
			line-height: 32px;
		}
		@at-root #{&}--16 {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}
		@at-root #{&}--12 {
			font-size: 12px;
			font-weight: regular;
			line-height: 18px;
		}
		@at-root #{&}--small {
			font-size: 12px;
			line-height: 18px;
		}
		@at-root #{&}--large {
			font-size: 18px;
			line-height: 32px;
		}
	}
}
