#public {
	/*
	* HOMEPAGE
	*/

	.homepage-feature {
		position: static;
		max-height: 100vh;
		min-height: 34.5rem;
		overflow: hidden;
		.jumbotron {
			background: transparent;
			position: relative;
			height: 100vh;
			min-height: 34.5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			h1 {
				margin-top: 0;
				margin-bottom: 24px;
			}
			p {
				font-size: 22px;
				line-height: 30px;
				margin-bottom: 40px;
				margin-bottom: 0;
				small {
					font-size: 18px;
				}
			}
		}
		form {
			label {
				@extend .heading3;
				@extend .normal;
			}
		}
		@at-root {
			#{&}--image, #{&}--text {
				.jumbotron {
					color: $white;
				}
				&.homepage {
					background: url('../../img/public/homepage1.png') center center no-repeat;
					background-size: cover;
				}
				&.teampage {
					background: url('../../img/public/teampage.png') center center no-repeat;
					background-size: cover;
				}
				&.partnerspage {
					background: url('../../img/public/partnerspage.png') center center no-repeat;
					background-size: cover;
				}
				&.howitworks {
					background: url('../../img/public/howitworks.png') center center no-repeat;
					background-size: cover;
				}
			}
			#{&}--search, #{&}--text {
				.jumbotron {
					height: auto;
					min-height: 100vh;
					overflow: visible;
				}
			}
		}
		.logobar {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $white;
		}
	}

	.homepage-feature-list {
		padding: 40px;
		background-color: #8C8C8C;
		color: $white;
		text-align: center;
		min-height: 480px;
		&.bg-campuses {
			background: url('../../img/public/bg-campuses.jpg') center center no-repeat;
			background-size: cover;
			margin: 95px -10px 0;
		}
		&.bg-global {
			background: url('../../img/public/bg-global.jpg') center center no-repeat;
			background-size: cover;
			margin: 95px -10px 0;
		}
		&.bg-partners {
			background: url('../../img/public/bg-partners.jpg') center center no-repeat;
			background-size: cover;
			margin: 95px -10px 0;
		}
		&.bg-professionals {
			background: url('../../img/public/bg-professionals.jpg') center center no-repeat;
			background-size: cover;
			margin: 95px -10px 0;
		}
		&.bg-technical {
			background: url('../../img/public/bg-technical.jpg') center center no-repeat;
			background-size: cover;
			margin: 95px -10px 0;
		}
		&.bg-remote {
			background: url('../../img/public/bg-remote.jpg') center center no-repeat;
			background-size: cover;
			margin: 95px -10px 0;
		}
		.feature-list-item {
			width: 350px;
		}
		.feature-list-image {
			width: 100px;
			height: 100px;
			border-radius: 50px;
			background-color: $white;
			margin: 0 auto 26px;
			@extend .horizontal-item--center;
			img {
				max-width: 56px;
			}
		}
	}

	.intro-signup {
		padding-top: 76px;
		padding-bottom: 76px;
	}

	.section-heading {
		@extend .heading1;
		margin-bottom: 60px;
	}

	.section-divider {
		border-top: 1px solid #707070;
		margin: 76px auto;
		text-align: center;
	}

	.partnerspage-content {
		max-width: 700px;
	}

	.help-page .main-content {
		padding: 15px 0 0;
	}
}
