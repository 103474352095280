/*
* BUTTON
*/
#public {
	a.button:hover, a.button:focus {
		color: $white !important;
	}
	a.button__secondary:hover, a.button__secondary:focus {
		color: $purple01 !important;
		cursor: pointer !important;
	}
	.button {
		background-color: $purple01;
		border: 1px solid $purple01;
		border-radius: $btn-border-radius;
		outline: 3px solid transparent;
		color: $white;
		text-transform: capitalize;
		cursor: pointer;
		display: inline-block;
		font-size: 18px;
		line-height: 24px;
		font-weight: 200;
		margin: 0;
		padding: 9px 20px;
		width: auto;
		min-width:122px;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-image: none;
		transition: all 300ms ease;
		box-shadow: 0 0 3px 3px transparent;
		&:hover {
			background-color: $purple02;
		}
		&:focus {
			background-color: $purple01;
			outline-color: $purple04;
			box-shadow: 0 0 3px 3px $purple04;
		}
		&:active {
			outline-color: $purple04;
		}
		&:disabled {
			background-color: $purple02;
			opacity: 50;
			cursor: not-allowed;
		}
		@at-root #{&}--loading {
			background-color: $purple01;
			box-shadow: none;
			&:focus {
				box-shadow: none;
			}
		}
		@at-root #{&}__primary {
			background-color: $purple01;
			&:hover {
				background-color: $purple02;
				border-color: $purple02;
			}
			&:focus {
				background-color: $purple01;
				outline-color: $purple04;
			}
			&:active {
				background-color: $purple01;
			}
			&:disabled {
				background-color: $purple04;
				border-color: $purple04;
			}
			@at-root #{&}--loading {

			}
		}
		@at-root #{&}__secondary {
			background-color: transparent;
			border: 1px solid $purple01;
			color: $purple01;
			&:hover {
				background-color: transparent;
				border: 1px solid $purple01;
			}
			&:focus {
				background-color: transparent;
				outline-color: $purple04;
				color: $purple01;
			}
			&:active {
				border: 1px solid $purple01;
			}
			&:disabled {
				background-color: transparent;
				opacity: 0.5;
			}
			@at-root #{&}--loading {

			}
		}
		@at-root #{&}__danger {
			background-color: $red01;
			border-color: $red01;
			&:hover {
				background-color: $red02;
			}
			&:focus {
				background-color: $red01;
				outline-color: $purple04;
			}
			&:active {
				background-color: $red01;
			}
			&:disabled {
				background-color: $red01;
				opacity: 0.5;
			}
			@at-root #{&}--loading {

			}
		}
		&.block {
			display: block;
		}

		@at-root #{&}--small {
			font-size: 12px;
			line-height: 16px;
			padding: 9px 20px;

			[class^="icon"] {
				font-size: 10px;
			}
		}
		@at-root #{&}--medium {
			font-size: 14px;
			line-height: 18px;
			padding: 10px 20px;
			min-width: 92px;

			[class^="icon"] {
				font-size: 18px;
			}
		}
		@at-root #{&}--large {
			font-size: 18px;
			line-height: 24px;
			padding: 9px 20px;
			min-width: 122px;

			[class^="icon"] {
				font-size: 24px;
			}
		}
	}
}
