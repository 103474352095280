#public {
	/*
	* NAVIGATION
	*/

	.public-page {
		margin-bottom: 0;
		.navbar-top {
			> li > a {
				padding-top: 36px;
				padding-bottom: 36px;
				&:hover {
					background: transparent;
					text-decoration: underline;
				}
			}
			li > a {
				color: $text-color;
				font-size: 18px;
				&:hover, &:focus {
					background: transparent;
				}
				&.disabled {
					opacity: 0.5;
					text-decoration: none !important;
				}
			}
			.dropdown {
				.dropdown-menu {
					top: 70%;
					@include rounded(6px);
					@extend .shadow;
					> li > a {
						padding: 6px 20px !important;
						&:hover {
							background: transparent;
							text-decoration: underline;
						}
					}
				}
			}
		}

	}

	.navbar-aside {
		@extend .bg-white;
		@extend .shadow-md;
		border-radius: 3px;
		li {
			padding: 8px 0;
			a {
				color: $text-color;
			}
		}
	}

	.public-page {
		.navbar-toggle {
			padding: 38px 10px;
		}
		.icon-bar {
			border-color: #000000;
		}
		.navbar-brand {
			background-image: none;
			height: auto;
			padding: 23px 8px;
			img {
				height: 48px;
				position: relative;
				display: block;
				width: auto;
			}
		}
	}

	.call-to-action {
		@extend .vertical-item--center;
		height: 100%;
		button {
			margin: 24px 10px;
			display: inline-block;
		}
	}

	.no-menu {
		.navbar-toggle {
			display: none;
		}
		#navbar {
			display: none !important;
		}
	}

}
