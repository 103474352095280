#public {
	/*
	* ELEMENT
	*/
	@extend .bg-white;
	.showcase-logo {
		@extend .flex;
		img {
			display: inline-block;
			max-height: 50px;
			margin: 0 16px;
		}
	}

	.media-showcase {
		margin-bottom: 40px;
		padding: 0 60px;
		p {
			@extend .heading4 ;
			font-weight: normal;
		}
		.media-object {
			max-width: 240px;
		}
		.media-body .media-heading {
			margin-bottom: 20px;
		}
	}

	.media {
		margin-bottom: 40px;
		.media-content {
			line-height: 1.5;
			@at-root #{&}--icon {
				padding-left: 70px;
				&:before {
					content: "";
				  display:block;
				  height:42px;
				  width:42px;
				  background-size: 42px 42px;
				  background-image: url('../../img/public/point.svg');
				  background-repeat: no-repeat;
					position: absolute;
					margin-left: -58px;
				}
			}
		}
	}

	.logobar-bca-mobile {
		height: 30px;
	}

	/*
	* list
	*/

	.list-plain {
		list-style: none;
		padding-left: 0;
		border: 0;
		box-shadow: none;
		@at-root #{&}--icon i {
			font-size: 12px;
			margin-right: 8px;
		}
		.list-plain {
			padding-left: 8px;
		}
	}

	.testimonial {
		padding: 100px 40px;
		background-color: #eee;
		text-align: right;
		@at-root #{&}-item {
			font-size: 12px;
			padding: 30px 0;
			@extend .flex;
			@extend .flex-center;
			@extend .align-items-end;
			text-align: right;
		}
	}

	.intro-page {
		padding: 40px 0;
	}

	.product-feature {
		@extend .flex-container;
		@extend .flex-around;
		@at-root #{&}-list {
			width: 392px;
			position: relative;
			display: inline-block;
			margin-bottom: 25px;
			margin-right: 2px;
		}
	}

	.gallery-card {
		@extend .flex-container;
		@extend .flex-around;
		@at-root #{&}-item {
			width: 284px;
			max-width: 240px;
			position: relative;
			border-radius: 5px;
			text-align: center;
			display: inline-block;
			overflow: hidden;
			margin-bottom: 25px;
			margin-right: 2px;
			&:hover {
				.avatar.top {
					opacity: 0;
				}
				.gallery-card-name {
					bottom: 0;
				}
			}
			.avatar {
		    position: absolute;
		    -webkit-transition: opacity .8s ease-in-out;
		    -moz-transition: opacity .8s ease-in-out;
		    -o-transition: opacity .8s ease-in-out;
		    transition: opacity .8s ease-in-out;
				&.top {
				    position: relative;
				}
			}
		}
		@at-root #{&}-image {
			position: relative;
			display: block;
		}
		@at-root #{&}-name {
			color: $white;
			width: 100%;
			padding: 20px;
			position: absolute;
			background: rgba(102, 96, 222, 0.9);
			bottom: -95px;
			-webkit-transition: all .8s ease;
			-moz-transition: all .8s ease;
			-o-transition: all .8s ease;
		}
	}

	.card {
		margin-bottom: 12px;
		@at-root #{&}-category {
			@extend .uppercase;
		}
		@at-root #{&}-title {
			@extend .heading3;
			margin: 0;
		}
		@at-root #{&}-content {
			@extend .heading5;
			font-weight: normal;
		}
		.header {
			min-height: 200px;
		}
		.body {
			border-top: 1px solid #eee;
			padding: 24px 16px;
			.category {
				@extend .heading6;
				@extend .uppercase;
			}
		}
	}

	.article {
		padding: 40px 0;
		@at-root #{&}-category {
			@extend .heading6;
			@extend .uppercase;
			margin-bottom: 8px;
		}
		@at-root #{&}-title {
			@extend .heading1;
		}
		@at-root #{&}-meta {
			@extend .heading3;
			@extend .normal;
		}
		@at-root #{&}-media {
			max-height: 200px;
			overflow: hidden;
			margin-bottom: 24px;
		}
		@at-root #{&}-content {
			@extend .heading3;
			@extend .normal;
		}
	}

	.well-form {
		max-width: 455px;
		background-color: #eee;
		padding: 64px 36px;
		margin: 0 auto;
		.form-group {
			margin-bottom: 24px;
		}
		label {
			font-weight: 600;
		}
		.vertically-message {
			min-height: 500px;
			@extend .vertical-item--center;
		}
	}

	.media.support img, .logobar img {
		max-height: 60px;
	}

	.checked-list {
		li {
			padding-bottom: 12px;
			&:before {
				font-family: 'FontAwesome';
		    content: '\F00C';
		    font-size: 16px;
		    display: block;
		    height: 16px;
		    width: 16px;
		    position: absolute;
		    margin-left: -20px;
			}
		}
	}

	/*
	* Full Page
	*/

	.full-page {
		display: table;
		width: 100%;
		height: 100%;
		overflow: hidden;
		min-height: calc(100vh - 94px);
		@at-root #{&}-body {
			display: table-cell;
			vertical-align: middle;
			padding: 40px 0;
		}
	}

	.main-content {
		padding: 110px 0 0;
    background-color: $white;
	}

	.subheader {
		border: 1px solid $border-color;
    border-right: 0;
    border-left: 0;
    padding: 20px 0;
	}

	.search-form {
		position: relative;
	}

	.search-form:before {
		content: "\e003";
		font-family: 'Glyphicons Halflings';
		position: absolute;
		width: 20px;
		height: 20px;
		font-size: 14px;
	}

	.breadcrumb {
		margin: 0;
		>li {
			padding: 0 4px;
		}
		>li+li:before {
			content: "\e080";
			font-family: 'Glyphicons Halflings';
			color: $text-color;
			font-size: 12px;
			padding-left: 0;
		}
	}

	.blog-wrapper {
		background-color: #fff;
		padding: 60px 0;
		.blog-box {
			background-color: #ffffff;
			border-radius: 2px;
			display: inline-block;
			position: relative;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			width: 100%;
			min-height: 460px;
			margin-bottom: 20px;
			@include rounded(6px);
			&:hover {
				box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
			}
			.blog-image {
				max-width: 100%;
				height: auto;
				vertical-align: middle;
				overflow: hidden;
				max-height: 200px;
				img {
					width: 100%;
					height: auto;
					@include rounded(6px 6px 0 0);
				}
			}
			.blog-text {
				text-align: left;
				padding: 0 16px 12px;
				word-wrap: break-word;
				.blog-category {
					font-size: 16px;
					text-align: left;
					text-transform: uppercase;
					margin-bottom: 8px;
				}
				.blog-title {
					@extend .heading3;
					word-wrap: break-word;
				}
				a {
					color: #333;
				}
			}
		}
	}

	.article-page-title {
		max-width: 1010px;
	  border-radius: 6px;
	  background-color: #564fdb;
	  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
		text-align: center;
		color: $white;
		margin: -40px auto 0;
		padding: 40px 50px;
	}

	.desc-job {
		width: 100%;
	}

	.page-sticky-navbar {
		padding-top: 96px;
	}

	.page-sticky-title {
		min-height: 100px;
		background-color: $white;
	}

	.pricing-help-block {
		width: 100%;
    max-width: 1140px;
    padding: 20px 42px;
    margin: 0;
	}

	.pricing-botom {
		@extend .horizontal-item--start;
		width: 100%;
		max-width: 1140px;
	}

	.pricing-faq {
		width: 100%;
		max-width: 746px;
		margin: 8px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	}

	.pricing-contact {
		width: 100%;
		max-width: 361.5px;
		margin: 8px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	}

	.feature-img {
		text-align: left;
		img {
			max-height: 50px;
			width: 67px;
			display: block;
		}
	}

	.search {
		padding: 0 36px 0 3px;
    border: 1px solid purple;
    background-position-x: 96%;
    @include rounded(5px);
		.Select-control {
			border: 0;
		}
		&.full-width {
			width: 100%;
			padding: 0 46px 0 3px;
		}
	}

	.hide-options .Select-arrow {
    border-color: transparent;
	}

	.search-form {
	  position: relative;
	}

	.search-form:before {
	  content: "\e003";
	  font-family: 'Glyphicons Halflings';
	  position: absolute;
	  width: 20px;
	  height: 20px;
	  font-size: 14px;
	}

	.pricing__container {
		width: 100%;
	}

	.pricing__box {
		max-width: 360px;
	}
}

/*
* footer
*/

.main-footer {
	background-color: $purple01;
	color: $white;
	padding: 60px 0 100px;
	a {
		color: $white !important;
	}
	.list-plain li {
		padding: 8px 0;
	}
	.download-box img {
		max-height: 34.5px;
	}
}

.footer-link-heading {
	@extend .heading5;
	font-weight: bold;
}

.footer-logo {
	background: url('../../img/public/logo-astrnt-white.svg') no-repeat;
	height: 36px;
	text-indent: -9999px;
	display: block;
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transition: opacity .25s ease-in-out 0s;
	-moz-transition: opacity .25s ease-in-out 0s;
	-o-transition: opacity .25s ease-in-out 0s;
	transition: opacity .25s ease-in-out 0s;
}

.footer-link-download {
	display: block;
}

.footer-copyright {
	margin-top: 40px;
	p {
		font-size: 14px !important;
	}
}

/*
* social media
*/
.social-media {
	display: block;
	i {
		color: #333;
	}
	.icons {
		background-color: $white;
		width: 40px;
		height: 40px;
		@extend .horizontal-item--center;
		@include rounded(50%);
		margin-right: 12px;
	}
}

.sticky {
	position: -webkit-sticky;
  position: sticky;
  top: 0;
	z-index: 100;
}
