/*
*
* ASTRONAUT WEB APP
* INPUT FIELD AND FORMS
* VERSION 1.0
*
*/

#theme-purple {
	label {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 4px;
	}
	.form-group {
		margin-bottom: 24px;

		[class^="text-error"] {
			display: block;
			margin-top: 4px;
		}
	}
	.input-icon {
		position: relative;
		&.input-icon-right > input {
			display: inline-block;
			padding-right: 30px;
		}
		&.input-icon-right > [class*="fa-"] {
	    padding: 0 3px;
	    z-index: 2;
	    position: absolute;
	    display: inline-block;
			right: 8px;
	    font-size: 16px;
			line-height: 40px;
		}
	}
	.form-control {
		display: block;
    width: 100%;
    height: auto;
    padding: 8px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(51, 51, 51, 0.5);
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

		&:focus {
			border: 1px solid $purple;
		}
		&:disabled {
			border: 1px solid #BDBDBD;
			color: $gray;
		}
		&.error {
			border: 1px solid $red;
    	color: $red;
		}
	}
	.text-error {
		color: $red;
		line-height: normal;
		font-size: 12px;
	}

	.has-error .form-control {
    border-color: $red;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	}

	.has-success {
		.form-control {
			border-color: #2F80ED;
			-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		}
		i {
			color: #2F80ED;
		}
	}

	.search {
		@include all-transition(0.3s,0s);
		border: 1px solid #ddd;
		box-shadow: none;
		background: $white url(../img/icon-search.png) no-repeat 8px 6px;
		padding-left: 40px;
		[data-loading="true"] {
			padding-right: 40px;
			content: "\f110";
			font-family: fontAwesome;
			@extend .has-success;
		}
	}
}
