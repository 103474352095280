$mobile-width: 320px;
$mobile-md-width: 720px;
$tablet-width: 960px;
$tablet-md-width: 1024px;
$desktop-width: 1280px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$mobile-md-width - 1px}) {
		#public {
	    .homepage-feature {
	      position: relative;
	      max-height: none;
	      min-height: inherit;
	      .jumbotron {
	        text-align: center;
					margin-top: 0;
	        h1 {
	          margin: 0;
						font-size: 22px;
						margin-bottom: 20px;
	        }
	        P {
	          font-size: 12px;
						line-height: 18px;
	          small {
	            font-size: 12px;
	          }
	        }
	      }
        &--list {
          .feature-list-item {
            margin: 0 auto;
            .feature-list-image {
              margin: 0 auto 16px;
              width: 80px;
              height: 80px;
              img {
                max-width: 36px;
              }
            }
          }
        }
	    }
			.public-page .navbar-brand {
				padding: 29px 8px;
				img {
					height: 40px;
				}
			}
	    .footer-link-heading {
	      margin-top: 40px;
	    }
	    .flex {
	      flex-direction: column;
	    }
	    .testimonial {
	      padding: 20px;
	      @at-root #{&}-item {
	        padding: 20px;
	      }
	    }
	    .media {
	      .media-content {
	        @at-root #{&}--icon {
	          padding-left: 0;
	          &:before {
	            display: none;
	          }
	        }
	      }
	    }
			.media-showcase {
				.media-object {
					margin-bottom: 40px;
					max-width: 180px;
				}
			}
	    .well-form {
	      background-color: transparent;
	    }
	    .intro-signup {
	      padding-top: 20px;
	      padding-bottom: 20px;
	      background-color: #eee;
	    }
	    .full-page-body {
	      padding: 0;
	    }
	    .main-content {
	      padding: 40px 20px;
	    }
			.public-page {
				.navbar-top {
		      li > a {
		        padding-top: 10px;
		        padding-bottom: 10px;
		      }
					.dropdown .dropdown-menu {
						box-shadow: none;
					}
		    }
			}
	    .call-to-action button {
	      margin: 10px 10px;
	    }
      .pricing__container {
        flex-direction: column;
        width: 388px;
      }
			.pricing__box {
				margin: 8px 0;
				max-width: 100%;
			}
			.mobile-show {
				display: block;
			}
			.sticky {
				position: relative;
			}
		}
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-md-width}) and (max-width: #{$tablet-width - 1px}) {
		#public {
			.homepage-feature {
				position: relative;
				max-height: none;
				min-height: inherit;
				@at-root {
					#{&}--text, #{&}--search {
						.jumbotron {
							display: block;
							margin-top: 0;
						}
					}
				}
			}
			.media-showcase {
				.media-object {
					margin-bottom: 40px;
					max-width: 180px;
				}
			}
			.section-heading {
				font-size: 24px;
		    line-height: normal;
			}
			.public-page {
				.navbar-top {
					.dropdown .dropdown-menu {
						box-shadow: none;
					}
		    }
			}
		}
  }
}

@mixin tablet-md {
  @media (min-width: #{$tablet-width}) and (max-width: #{$tablet-md-width - 1px}) {
		#public {
			.homepage-feature {
				position: relative;
				max-height: none;
				min-height: inherit;
				@at-root {
					#{&}--text, #{&}--search {
						.jumbotron {
							display: block;
						}
					}
				}
			}
			.desktop-none {
				display: none;
			}
		}
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-md-width}) and (max-width: #{$desktop-width - 1px}) {
		#public {
			.desktop-none {
				display: none;
			}
		}
  }
}

@mixin largeDesktop {
  @media (min-width: #{$desktop-width}) {
		#public {
			.largedesktop-none {
				display: none;
			}
		}
  }
}

@media (max-width: $desktop-width - 1px) {
	#public {
		.logobar {
			img {
				max-height: 60px;
				width: auto;
			}
			.logobar-1 {
				width: 30%;
			}
			.logobar-2 {
				width: 30%;
			}
		}
    .desktop {
      display: none;
    }

    .desktop-none {
      display: none;
    }
	}
}

/* ----------- Non-Retina Screens ----------- */

@media screen
  and (min-width: $desktop-width) {
    @include largeDesktop;
}

@media screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $desktop-width)
  and (-webkit-min-device-pixel-ratio: 1) {
    @include desktop;
}

@media (max-width: $desktop-width - 1px) {
  #public {
    .public-page .navbar-top {
			li > a {
				font-size: 14px;
			}
		}
		.call-to-action button {
			font-size: 14px;
			line-height: 18px;
			padding: 10px 20px;
			min-width: 92px;
		}
  }
}

@media (min-width: $tablet-md-width - 1px) {
	#public {
		.mobile {
			display: block;
		}
    .desktop {
      display: none;
    }
		.desktop-none {
			display: none;
		}
		.public-page .navbar-top {
			.dropdown {
				&:hover {
					.dropdown-menu {
						display: block;
					}
					li:hover .sub-menu {
						visibility: visible;
					}
				}
			}
		}
	}
}

@media (max-width: $tablet-md-width - 1px) {
	#public {
		.public-page .navbar-top {
			li > a {
				padding: 6px 20px;
				margin: 0;
			}
			.dropdown.open .dropdown-menu {
				box-shadow: none;
			}
		}
		.call-to-action button {
			margin: 0;
			background-color: transparent;
			border: 0;
			color: $text-color;
			text-align: left;
			font-weight: normal;
			padding: 6px 20px;
			&:hover, &:focus {
				background-color: transparent;
				border: 0;
			}
		}
		.logobar {
			img {
				max-height: 60px;
				width: auto;
			}
			.logobar-1 {
				width: 30%;
			}
			.logobar-2 {
				width: 30%;
			}
		}
		.footer-logo-container {
			margin-bottom: 42px;
		}
    .desktop {
      display: none;
    }
		.sticky {
			position: relative;
		}
	}
}

/* ----------- Retina Screens ----------- */
@media screen
  and (min-device-width: $desktop-width)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
    @include largeDesktop;
}

@media screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $desktop-width)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
    @include desktop;
}

/* ----------- Tablet Medium ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $tablet-md-width)
  and (-webkit-min-device-pixel-ratio: 1) {
		@include tablet-md;
}

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $tablet-md-width){
		@include tablet-md;
}

/* ----------- Tablet ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $mobile-md-width)
  and (max-device-width: $tablet-width)
  and (-webkit-min-device-pixel-ratio: 1) {
		@include tablet;
}

@media only screen
  and (min-device-width: $mobile-md-width)
  and (max-device-width: $tablet-width) {
		@include tablet;
}

/* ----------- iPhone 6+ ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $mobile-width)
  and (max-device-width: $mobile-md-width)
  and (-webkit-min-device-pixel-ratio: 3) {
    @include mobile;
}

/* ----------- iPhone under 5 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: $mobile-width)
  and (-webkit-min-device-pixel-ratio: 2) {
    @include mobile;
}

@media (max-device-width: $mobile-md-width) {
	@include tablet;
	@include mobile;
}

@media (max-width: $mobile-md-width - 1px) {
	#public {
    .testimonial-item {
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
    }
		.logobar {
			img {
				max-height: 45px;
				width: auto;
				margin: 0 auto;
				text-align: center;
				display: block;
			}
			.logobar-1 {
				width: 50%;
				padding-bottom: 15px;
			}
			.logobar-2 {
				width: 50%;
				padding-bottom: 15px;
			}
			.logobar-3 {
				width: 100%;
			}
		}
		.footer-logo-container {
			margin-bottom: 42px;
			text-align: center;
			margin: 0 auto;
		}
    .desktop {
      display: block;
    }
    .pricing__container {
      flex-direction: column;
      width: 388px;
    }
		.pricing__box {
			margin: 8px 0;
			max-width: 100%;
		}
		.centering {
			@extend .horizontal-item--center;
		}
	}
}

@media (max-width: 764px) {
	#public {
    .homepage-feature-list {
      .feature-list-item {
        margin: 0 auto;
        .feature-list-image {
          margin: 0 auto 16px;
          width: 80px;
          height: 80px;
          img {
            max-width: 36px;
          }
        }
      }
    }
		.main-footer {
			text-align: center;
		}
		.footer-logo-container {
			padding-bottom: 42px;
			.footer-logo {
				background-position: center;
			}
		}
		.social-media {
			display: inline-block;
			margin: 0 auto;
			text-align: center;
		}
		.footer-link-download {
			display: inline-block;
			margin: 0 auto;
			text-align: center;
			a:first-child {
				margin: 0 !important;
			}
		}
		.mobile {
      display: none;
    }
    .flex {
      flex-direction: column;
    }
    .pricing__container {
      flex-direction: column;
      width: 388px;
    }
		.pricing__box {
			margin: 8px 0;
			max-width: 100%;
		}
		.centering {
			@extend .horizontal-item--center;
		}
		.pricing-help-block {
			text-align: center;
		}
		.pricing-faq {
			max-width: 388px;
		}
		.pricing-contact {
			max-width: 388px;
		}
		.mobile-show {
			display: block;
		}
	}
}
