/*
* VARIABLE
*/

// UI Colors
$purple01: #564FDB;
$purple02: #8B4DFF;
$purple03: #A778FF;
$purple04: #D4BDFF;

$dark01: #333333;
$dark02: #4F4F4F;
$dark03: #828282;
$dark04: #BDBDBD;

$red01: #EA4D4D;
$red02: #F06262;

$text-color: #333333;
$white: #FFFFFF;
$border-color: #EEEEEE;

//Border radius for buttons
$btn-border-radius: 50px;

.bg-white {
  background-color: $white;
}

.bg-grey {
	background-color: #f0f0f0;
}

.text-body {
	color: $text-color !important;
	&:hover, &:focus {
		color: $text-color !important;
	}
}
