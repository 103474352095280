/*
* BASE
*/
#public {
	/*
	* FLEX CONTAINER
	*/

	.flex-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
	}

	.flex {
		display: flex;
	}

	.flex-column {
		flex-flow: column;
	}

	.flex-nowrap {
		flex-wrap: nowrap;
	}

	.flex-around {
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.flex-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.flex-around {
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.flex-center {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.align-items-center {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.align-items-start {
		-webkit-box-align: flex-start;
		-ms-flex-align: flex-start;
		align-items: flex-start;
	}

	.align-items-end {
		-webkit-box-align: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
	}

	.vertical-item--center {
		@extend .flex-container;
		@extend .align-items-center;
	}

	.vertical-item--top {
		@extend .flex-container;
		@extend .align-items-start;
	}

	.vertical-item--justify {
		@extend .flex-container;
		@extend .flex-start;
	}

	.horizontal-item--around {
		@extend .flex-container;
		@extend .flex-around;
		@extend .align-items-center;
	}

	.horizontal-item--between {
		@extend .flex-container;
		@extend .flex-between;
		@extend .align-items-center;
	}

	.horizontal-item--center {
		@extend .flex-container;
		@extend .flex-center;
		@extend .align-items-center;
	}

	.horizontal-item--start {
		@extend .flex-container;
		@extend .flex-center;
		@extend .align-items-start;
	}

	.horizontal-item--end {
		@extend .flex-container;
		@extend .flex-center;
		@extend .align-items-end;
	}

	.vertical-item--between {
		@extend .flex;
		@extend .flex-column;
		@extend .flex-between;
	}

	.align-self-end {
		align-self: flex-end;
	}

	.align-self-start {
		align-self: flex-start;
	}

	.align-self-center {
		align-self: center;
	}

	.flex-initial {
		flex: initial;
	}

	.flex-1 {
		flex: 1;
	}

	.flex-inherit {
		flex: inherit;
	}

	.flex-row {
		flex-flow: row;
	}

	/*
	* BLOCK
	*/

	.block {
		display: block;
	}

	.center {
		margin: 0 auto;
		text-align: center;
	}

	.text-left {
		text-align: left;
	}

	.valign-middle {
		vertical-align: middle;
	}

	.valign-bottom {
		vertical-align: bottom;
	}

	.valign-top {
		vertical-align: top;
	}

	.bordered {
		border: 1px solid $border-color;
	}

	.border-bottom {
		border-bottom: 1px solid $border-color;
	}

	.rounded-sm {
		border-radius: 3px;
	}

	.shadow {
		box-shadow: 0 3px 6px 0 rgba(0,0,0,0.10);
		@at-root #{&}-md {
			box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
			0 2px 4px 0 rgba(0,0,0,0.08);
		}
		@at-root #{&}-lg {
			box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11),
			0 5px 15px 0 rgba(0,0,0,0.08);
		}
	}

	.full-width {
		width:100%;
	}

	.largedesktop-none {
		display: none;
	}

	.desktop-none {
		display: none;
	}

	.padding-bottom-100 {
		padding-bottom: 100px;
	}

	@media screen and (max-width: 767px){
		.padding-bottom-100 {
			padding-bottom: 0px;
		}
	}

	@media screen and (width: 1024px){
		.padding-bottom-100 {
			padding-bottom: 225px;
		}
		.logobar-middle {
			padding-top: 15px;
		}
	}

	@media screen and (width: 768px){
		.padding-bottom-100 {
			padding-bottom: 225px;
		}
		.logobar-middle {
			padding-top: 15px;
		}
	}

	@media screen and (width: 834px){
		.padding-bottom-100 {
			padding-bottom: 225px;
		}
		.logobar-middle {
			padding-top: 15px;
		}
	}

	@media screen and (width: 1112px){
		.padding-bottom-100 {
			padding-bottom: 225px;
		}
		.logobar-middle {
			padding-top: 15px;
		}
	}

	/*
	* MARGINS & PADDINGS
	*/

	@each $widthConfig in '0' '4' '8' '12' '16' '20' '24' '32' '36' '40' '44' '56' '60' '76' '100' {
		.p-#{$widthConfig} {
			padding: #{$widthConfig}px;
		}

		.p-t-#{$widthConfig} {
			padding-top: #{$widthConfig}px;
		}

		.p-l-#{$widthConfig} {
			padding-left: #{$widthConfig}px;
		}

		.p-b-#{$widthConfig} {
			padding-bottom: #{$widthConfig}px;
		}

		.m-#{$widthConfig} {
			margin: #{$widthConfig}px;
		}

		.m-l-#{$widthConfig} {
			margin-left: #{$widthConfig}px;
		}

		.m-l-n-#{$widthConfig} {
			margin-left: -#{$widthConfig}px;
		}

		.m-t-#{$widthConfig} {
			margin-top: #{$widthConfig}px;
		}

		.m-t-n-#{$widthConfig} {
			margin-top: -#{$widthConfig}px;
		}

		.m-r-#{$widthConfig} {
			margin-right: #{$widthConfig}px;
		}

		.m-r-n-#{$widthConfig} {
			margin-right: -#{$widthConfig}px;
		}

		.m-b-#{$widthConfig} {
			margin-bottom: #{$widthConfig}px;
		}

		.m-b-n-#{$widthConfig} {
			margin-bottom: -#{$widthConfig}px;
		}
	}
}
