@mixin hideAnchorText {
	text-indent: -9999px;
  overflow: hidden;
}

@mixin rounded($value) {
	-webkit-border-radius: $value;
	-moz-border-radius:$value;
  border-radius: $value;
}
