$mobile-width: 320px;
$mobile-md-width: 720px;
$tablet-width: 960px;
$tablet-md-width: 1024px;
$desktop-width: 1280px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$mobile-md-width - 1px}) {
		.wrapper-listing {
			width: 100%;
		}
    .article-page-title {
      margin: 10px auto 0;
    }
		.section-heading {
			font-size: 24px;
	    line-height: normal;
		}
    .pricing__container {
      flex-direction: column;
    }
		.centering {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-flow: row wrap;
			flex-flow: row wrap;
			-webkit-box-pack: center;
		      -ms-flex-pack: center;
		          justify-content: center;
			-webkit-box-align: center;
		      -ms-flex-align: center;
		          align-items: center;
		}
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-md-width}) and (max-width: #{$tablet-width - 1px}) {

  }
}

@mixin tablet-md {
  @media (min-width: #{$tablet-width}) and (max-width: #{$tablet-md-width - 1px}) {
		.forum-info {
			margin: 7px 14px;
			display: none;
		}
		.forum-desc {
			float: none !important;
		}
		.forum-detail {
			padding: 6px 20px;
		}
		.wrapper-listing {
			width: 600px;
		}
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-md-width}) and (max-width: #{$desktop-width - 1px}) {
		.wrapper-box-profile {
	    margin: 0 10px;
	  }
		.forum-detail {
	    padding: 10px 20px;
	  }
	  .forum-info {
	    padding: 7px 14px;
	  }
	  .forum-icon {
	    padding: 16px;
	  }
  }
}

/* ----------- Non-Retina Screens ----------- */

@media screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $desktop-width)
  and (-webkit-min-device-pixel-ratio: 1) {
    @include desktop;
}

@media screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $desktop-width){
    @include desktop;
}

/* ----------- Retina Screens ----------- */

@media screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $desktop-width)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
    @include desktop;
}

/* ----------- Tablet Medium ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $tablet-md-width)
  and (-webkit-min-device-pixel-ratio: 1) {
		@include tablet-md;
}

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $tablet-width)
  and (max-device-width: $tablet-md-width){
		@include tablet-md;
}

/* ----------- Tablet ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $mobile-md-width)
  and (max-device-width: $tablet-width)
  and (-webkit-min-device-pixel-ratio: 1) {
		@include tablet;
}

@media only screen
  and (min-device-width: $mobile-md-width)
  and (max-device-width: $tablet-width) {
		@include tablet;
}



/* ----------- iPhone 6+ ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: $mobile-width)
  and (max-device-width: $mobile-md-width)
  and (-webkit-min-device-pixel-ratio: 3) {
    @include mobile;
}

/* ----------- iPhone under 5 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: $mobile-width)
  and (-webkit-min-device-pixel-ratio: 2) {
    @include mobile;
}

@media (max-device-width: $mobile-md-width) {
	@include tablet;
	@include mobile;
}

@media (max-width: 764px) {
	.main-footer {
		text-align: center;
	}
	.footer-logo-container {
		padding-bottom: 42px;
		.footer-logo {
			background-position: center;
		}
	}
	.social-media {
		display: inline-block;
		margin: 0 auto;
		text-align: center;
	}
	.footer-link-download {
		display: inline-block;
		margin: 0 auto;
		text-align: center;
	}
	.flex {
		flex-direction: column;
	}
	.pricing__container {
		flex-direction: column;
	}
	.centering {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		-webkit-box-pack: center;
				-ms-flex-pack: center;
						justify-content: center;
		-webkit-box-align: center;
				-ms-flex-align: center;
						align-items: center;
	}
}
