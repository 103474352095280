/*
*
* ASTRONAUT WEB APP
* BUTTONS
* VERSION 1.0
*
Block component
.block {}

Element that depends upon the block
.block__price {}

Modifier that changes the style of the block
.block--orange {}
.block--big {}

*/

.btn {
	@at-root #{&}__text-only {
		background-color: transparent;
	}
	@at-root #{&}--dense {
		padding: 0;
	}
}

.btn-purple {
	border-radius: 1000px;
	background-color: #AB47BC;
	color: $white;
	font-size: 16px;
	padding: 0 20px;
	font-weight: bold;
	line-height: 38px;
	&:hover, &:focus, &:active {
		color: $white;
	}
	&:hover {
		background-color:#BA68C8;
	}
	&:focus, &:active {
		background-color:#7B1FA2;
	}
}

.btn-save {
	border-radius: 6px;
	background-color: #564FDB;
	color: $white;
	font-size: 18px;
	font-family: "Open Sans", sans-serif;
	padding: 0 20px;
	width: 124px;
	height: 42px;
	font-weight: normal;
	line-height: normal;
	&:hover, &:focus, &:active {
		color: $white;
	}
	&:hover {
		background-color:#5B54E8;
	}
	&:focus, &:active {
		background-color:#423DA8;
	}
}

.btn-cancel {
	border-radius: 6px;
	border: 1px solid #564FDB;
	background-color: #FFFFFF;
	color: #564FDB;
	font-size: 18px;
	font-family: "Open Sans", sans-serif;
	padding: 0 20px;
	width: 124px;
	height: 42px;
	font-weight: normal;
	line-height: normal;
	&:hover, &:focus, &:active {
		color: #564FDB;
	}
}

.btn-transparent {
	background-color: transparent;
	border: 0;
	font-size: 12px;
	padding: 7px 0px;
}

.icon-align-bottom {
	vertical-align: bottom;
	margin-right: 5px;
}

.icon-align-sub {
	vertical-align: sub;
	margin-right: 5px;
}

.btn-action {
	background-color: $purple01;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover {
		background-color: $purple01;
		color: $white;
	}
	&:focus {
		color: $white;
	}
}

.btn-action-2 {
	background-color: $navy;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: $dark-navy;
		color: $white;
	}
}

.btn-action-3 {
	background-color: transparent;
	@include rounded($btn-rounded);
	border: 1px solid #42BE7A;
	font-size: 12px;
	padding: 7px 20px;
	color: #42BE7A;
	font-weight: bold;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: #42BE7A;
		color: $white;
	}
}

.btn-action-4 {
	background-color: transparent;
	@include rounded($btn-rounded);
	border: 1px solid #5272D1;
	font-size: 12px;
	padding: 7px 20px;
	color: #5272D1;
	font-weight: bold;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: #5272D1;
		color: $white;
	}
}

.btn-grey {
	background-color: $grey;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: $dark-grey;
		color: $white;
	}
}

.btn-filter {
	background-color: $border-color;
	@include rounded($btn-rounded);
	border-width: 2px;
	font-size: 13px;
	color: #415466;
	&:hover, &:focus {
		background-color: $dark-grey;
		color: $white;
	}
}

.btn-download {
	background-color: $grey;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: $light-green;
		color: $white;
	}
}

.btn-download-xs {
	background-color: #E0E0E0;
	@include rounded(3px);
	border: 0;
	font-size: 12px;
	padding: 5px 15px;
	color: #4F4F4F;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover {
		background-color: #F2F2F2;
		color: #4F4F4F;
	}
}

.btn-border {
	background-color: transparent;
	@include rounded($btn-rounded);
	border: 1px solid #4B4F85;
	font-size: 12px;
	font-weight: bold;
	padding: 5px 20px;
	color: #4B4F85;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover {
		background-color: #4B4F85;
		color: $white;
	}
}

.btn-preview {
	background-color: $turqoise;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: $dark-turqoise;
		color: $white;
	}
}

.btn-publish {
	background-color: $light-green;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: $dark-green;
		color: $white;
	}
}

.btn-share {
	background-color: $light-blue;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 5px 20px;
	color: $white;
	i {
		margin-right: 10px;
	}
	&:hover, &:focus {
		background-color: $purple01;
		color: $white;
	}
}

.btn-question {
	background-color: $purple01;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 5px;
	}
	&:hover, &:focus {
		background-color: $purple01;
		color: $white;
	}
}

.btn-next {
	background-color: $purple01;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 0;
	}
	&:hover, &:focus {
		background-color: $purple01;
		color: $white;
	}
}

.btn-back {
	background-color: $grey;
	@include rounded($btn-rounded);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 0;
	}
	&:hover, &:focus {
		background-color: $dark-grey;
		color: $white;
	}
}

.btn-cross {
	cursor: pointer;
	@extend .icon-i-cross;
	&:hover, &:focus {
		@extend .icon-i-cross-hover;
	}
}

.btn-cross2 {
	cursor: pointer;
	@extend .icon-i-close;
	&:hover, &:focus {
		@extend .icon-i-close;
	}
}

.btn-cross-white {
	cursor: pointer;
	@extend .icon-i-cross-white;
}

.btn-nav-prev {
	background-color: transparent;
	position: absolute;
  top: 45%;
  left: -8%;
  width: 75px;
  height: 64px;
	i {
		margin-right: 10px;
		@extend .icon-i-prev-button;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-prev-button;
		}
		color: #5272D1;
	}
	&[disabled] {
		opacity: 0.35;
	}
}

.btn-play-profile {
	background-color: #0000005B;
	width: 50px;
	height: 50px;
	color: white;
	font-size: 20px;
	border-radius: 25px;
	position: absolute;
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.btn-nav-prev-2 {
	background-color: #7F7F7F;
	width: 31px;
	height: 31px;
	color: white;
	border-radius: 16px;
	position: absolute;
	top: 50%;
    transform: translateY(-50%);
  	left: 0;
}

.btn-nav-prev-2-full {
	background-color: #131313;
	width: 31px;
	height: 31px;
	color: white;
	border-radius: 16px;
	position: absolute;
	top: 50%;
  	left: 30%;
}

.btn-nav-next-2 {
	background-color: #7F7F7F;
	width: 31px;
	height: 31px;
	color: white;
	border-radius: 16px;
	position: absolute;
	float: right;
	top: 50%;
    transform: translateY(-50%);
	right: 7%;
}

.btn-nav-next-2-full {
	background-color: #131313;
	width: 31px;
	height: 31px;
	color: white;
	border-radius: 16px;
	position: absolute;
	float: right;
	top: 50%;
	right: 30%;
}

.btn-close-vid-full {
	background-color: transparent;
	width: 31px;
	height: 31px;
	color: white;
	border-radius: 16px;
	position: absolute;
	float: right;
	top: 3%;
	right: 30vw;
	font-size: 14px;
}

.btn-nav-next {
	background-color: transparent;
	position: absolute;
	float: right;
  top: 45%;
  right: -8%;
  width: 75px;
  height: 64px;
	i {
		margin-right: 10px;
		@extend .icon-i-next-button;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-next-button;
		}
		color: #5272D1;
	}
	&[disabled] {
		opacity: 0.35;
	}
}

.btn-retake {
	@extend .btn-transparent;
	border: 1px solid #5272D1;
	font-size: 15px;
	padding: 5px;
	color: #5272D1;
	i {
		margin-right: 2px;
		@extend .icon-i-retake;
	}
	// &:hover, &:focus {
	// 	i {
	// 			@extend .icon-i-retake-active;
	// 	}
	// 	background-color: #5272D1;
	// 	color: $white;
	// }
}

.btn-send-question {
	@extend .btn-transparent;
	border: 1px solid #5272D1;
	font-size: 15px;
	padding: 5px;
	color: #5272D1;
	i {
		margin-right: 2px;
		@extend .icon-i-mini-question;
	}
	// &:hover, &:focus {
	// 	i {
	// 			@extend .icon-i-mini-question-active;
	// 	}
	// 	background-color: #5272D1;
	// 	color: $white;
	// }
}

.btn-close-document {
	@extend .btn-transparent;
	border: 1px solid #5272D1;
	font-size: 15px;
	padding: 5px;
	color: #5272D1;
	height: 36px;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 2px;
		@extend .icon-i-cancel;
	}
	// &:hover, &:focus {
	// 	i {
	// 			@extend .icon-i-delete-active;
	// 	}
	// 	background-color: #5272D1;
	// 	color: $white;
	// }
}

.btn-duplicate {
	@extend .btn-transparent;
	font-family: "Open Sans", Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: normal;
	color: #343434;
	i {
		@extend .icon-i-duplicate-purple;
		@extend .icon-align-bottom;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-duplicate-purple;
				@extend .icon-align-bottom;
		}
		background-color: transparent;
		color: $red;
	}
}

.btn-duplicate-modal {
	background-color: #564FDB;
	@include rounded(5px);
	width: auto;
	height: auto;
	border: 0;
	font-size: 18px;
	padding: 10px 19px;
	font-family: "Open Sans", Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	text-align: center;
	color: #FFFFFF;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &.active {
		background-color: $purple01;
		color: $white;
	}
	&:focus {
		color: $white;
	}
}

.btn-duplicate-candidate {
	@extend .btn-transparent;
	border: 1px solid #5272D1;
	font-size: 15px;
	padding: 5px;
	color: #5272D1;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 2px;
		@extend .icon-i-duplicate;
	}
	// &:hover, &:focus {
	// 	i {
	// 			@extend .icon-i-document-black;
	// 	}
	// 	background-color: #5272D1;
	// 	color: $white;
	// }
}

.btn-delete {
	@extend .btn-transparent;
	font-family: "Open Sans", Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: normal;
	color: #343434;
	i {
		@extend .icon-i-trash-purple;
		@extend .icon-align-bottom;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-trash-purple;
				@extend .icon-align-bottom;
		}
		background-color: transparent;
		color: $red;
	}
}

.btn-edit {
	@extend .btn-transparent;
	color: #4B4F85;
	i {
		@extend .icon-i-edit;
		@extend .icon-align-bottom;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-edit-active;
				@extend .icon-align-bottom;
		}
		background-color: transparent;
		color: #5272D1;
	}
	&.disabled {
		i {
			@extend .icon-i-edit-disabled;
			@extend .icon-align-bottom;
		}
	}
}

.btn-rename {
	@extend .btn-transparent;
	color: #4B4F85;
	i {
		@extend .icon-i-rename;
		@extend .icon-align-bottom;
	}
}

.btn-archive {
	@extend .btn-transparent;
	color: #4B4F85;
	i {
		@extend .icon-i-archive;
		@extend .icon-align-bottom;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-archive-active;
				@extend .icon-align-bottom;
		}
		background-color: transparent;
		color: #5272D1;
	}
	&.disabled {
		i {
			@extend .icon-i-archive-disabled;
			@extend .icon-align-bottom;
		}
	}
}

.btn-clone {
	@extend .btn-transparent;
	color: #4B4F85;
	i {
		@extend .icon-i-clone;
		@extend .icon-align-bottom;
	}
	&:hover, &:focus {
		i {
				@extend .icon-i-clone-active;
				@extend .icon-align-bottom;
		}
		background-color: transparent;
		color: #5272D1;
	}
	&.disabled {
		i {
			@extend .icon-i-clone-disabled;
			@extend .icon-align-bottom;
		}
	}
}


.btn-filter-delete {
	cursor: pointer;
	padding: 0;
}

.btn-dark-blue {
	background-color: #3F6182;
}

.btn-light-blue {
	background-color: #61B7DB;
}

.btn-orange {
	background-color: #EC8A1E;
}

.btn-turqoise {
	background-color: $turqoise;
}

.btn-dark-grey {
	background-color: #6A6A6A;
}

.btn-xsmall {
	padding: 3px 10px;
}

.btn-remove {
	cursor: pointer;
	@extend .icon-i-remove;
	&:hover, &:focus {
		@extend .icon-i-remove-active;
	}
}

.btn-pencil {
	cursor: pointer;
	@extend .icon-i-pencil;
	&:hover, &:focus {
		@extend .icon-i-pencil-active;
	}
}

.btn-ellipsis {
	cursor: pointer;
	@extend .icon-dots;
	&:hover, &:focus {
		@extend .icon-dots;
	}
}

.btn-o-archive {
	cursor: pointer;
	padding: 6px 10px;
	@extend .icon-i-o-archive;
	&:hover, &:focus {
		@extend .icon-i-o-archive-active;
	}
}

.btn-list {
	@extend .icon;
	cursor: pointer;
	@extend .icon-i-list;
	&:hover {
		@extend .icon-i-list-active;
	}
}

.btn-list-active {
	@extend .icon;
	cursor: pointer;
	@extend .icon-i-list-active;
}

.btn-grid {
	@extend .icon;
	cursor: pointer;
	@extend .icon-i-grid;
	&:hover, &:focus {
		@extend .icon-i-grid-active;
	}
}

.btn-grid-active {
	@extend .icon;
	cursor: pointer;
	@extend .icon-i-grid-active;
}

.btn-folder-plus {
	cursor: pointer;
	@extend .icon-i-folder-plus;
	&:hover, &:focus {
		@extend .icon-i-folder-plus-active;
	}
}

.btn-votes {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-vote-yes;
	&:hover {
		@extend .icon-vote-yes-hover;
	}
	&.active {
		@extend .icon-vote-yes-hover;
	}
}

.btn-vote-yes {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-i-vote-yes;
	&:hover, &:focus {
		@extend .icon-i-vote-yes-hover;
	}
	&.active {
		@extend .icon-i-vote-yes-hover;
	}
}

.btn-vote-no {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-i-vote-no;
	&:hover, &:focus {
		@extend .icon-i-vote-no-hover;
	}
	&.active {
		@extend .icon-i-vote-no-hover;
	}
}

.btn-vote-maybe {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-i-vote-maybe;
	&:hover, &:focus {
		display: block;
		@extend .icon-i-vote-maybe-hover;
	}
	&.active {
		@extend .icon-i-vote-maybe-hover;
	}
}

.btn-bell {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon35;
	@extend .icon-i-bell-o;
	&:hover, &:focus {
		@extend .icon-i-bell-o-hover;
	}
	&.active {
		@extend .icon-i-bell-o-hover;
	}
}


.btn-white-2 {
	color: inherit;
	background: $white;
	border: 1px solid #ccc;
	&:hover, &:focus {
		background: $white;
	}
}

.btn-add-img {
	@extend .img-add-img;
	&:hover {
		@extend .img-add-img;
	}
	/*&:focus {
		@extend .img-add-img-process;
	}
	&.active {
		@extend .img-add-img-process;
	}*/
}

.btn-close-img-ftq {
	position: absolute;
	right: -1px;
	top: 1px;
	@extend .close-img-2;
	&:hover, &:focus {
		@extend .close-img-2;
	}
	&.active {
		@extend .close-img-2;
	}
}

.btn-close-img {
	position: absolute;
	right: 0;
	color: #c9302c;
	&:hover, &:focus {
		color: #ac2925
	}
}
.input-group .form-control {
	z-index: 0;
}
.input-group-btn {
	.btn {
		border-radius: 0;
		border-left: 0;
		border-color: #cccccc;
		margin-right: -1px;
	}
}

.btn-list-question {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-list-question;
	&:hover, &:focus {
		@extend .icon-i-list-question-hover;
	}
	&.active {
		@extend .icon-i-list-question-hover;
	}
}

.btn-comment {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-comment;
	&:hover, &:focus {
		@extend .icon-i-comment-hover;
	}
	&.active {
		@extend .icon-i-comment-hover;
	}
}

.btn-player {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-player;
	&:hover, &:focus {
		@extend .icon-i-player-hover;
	}
	&.active {
		@extend .icon-i-player-hover;
	}
}

.btn-pdf {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-pdf;
	&:hover, &:focus {
		@extend .icon-i-pdf-hover;
	}
	&.active {
		@extend .icon-i-pdf-hover;
	}
}

.btn-big-info {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-big-info;
	&:hover, &:focus {
		@extend .icon-i-big-info-hover;
	}
	&.active {
		@extend .icon-i-big-info-hover;
	}
}

.btn-longarrow-left {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-longarrow-left;
	&:hover, &:focus {
		@extend .icon-i-longarrow-left-hover;
	}
	&.active {
		@extend .icon-i-longarrow-left-hover;
	}
}

.btn-longarrow-right {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-longarrow-right;
	&:hover, &:focus {
		@extend .icon-i-longarrow-right-hover;
	}
	&.active {
		@extend .icon-i-longarrow-right-hover;
	}
}

.btn-image {
	i.profile {
		@extend .icon-i-profile;
	}
	i.player {
		@extend .icon-i-player;
	}
	i.comment {
		@extend .icon-i-comment;
	}
	i.pdf {
		@extend .icon-i-pdf;
	}
	&:hover, &:focus {
		i.profile {
			@extend .icon-i-profile-hover;
		}
		i.player {
			@extend .icon-i-player-hover;
		}
		i.comment {
			@extend .icon-i-comment-hover;
		}
		i.pdf {
			@extend .icon-i-pdf-hover;
		}
	}
	&[aria-expanded="true"] {
		i.profile {
			@extend .icon-i-profile-hover;
		}
		i.player {
			@extend .icon-i-player-hover;
		}
		i.comment {
			@extend .icon-i-comment-hover;
		}
		i.pdf {
			@extend .icon-i-pdf-hover;
		}
	}
}

.btn-profile {
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-align-bottom;
	@extend .icon-i-profile;
	&:hover, &:focus {
		@extend .icon-i-profile-hover;
	}
	&.active {
		@extend .icon-i-profile-hover;
	}
}

.btn-video-share{
	cursor: pointer;
	display: block;
	position: relative;
	@extend .icon-i-big-share;
}

.btn-detail {
	cursor: pointer;
	@extend .icon-i-detail;
	&:hover, &:focus {
		@extend .icon-i-detail-active;
	}
}

.btn-summary {
	cursor: pointer;
	@extend .icon-i-summary;
	&:hover, &:focus {
		@extend .icon-i-summary-active;
	}
}

.btn-test {
	cursor: pointer;
	@extend .icon-i-test;
	&:hover, &:focus {
		@extend .icon-i-test-active;
	}
}

.btn-addinfo {
	cursor: pointer;
	&:hover, &:focus {
		text-decoration: underline;
	}
}

.btn-checkbox {
	width: 20px;
	height: 20px;
	border: 1px solid #C1C7CD;
	border-radius: 3px;
	background-color: #fff;
	padding: 0;
	&.checked {
		background-image: url(../img/checkmark.png);
		background-color: #465190;
		border: 1px solid #465190;
		background-size: 12px 10px;
		background-repeat: no-repeat;
		background-position: 3px 4px;
	}
	&:hover {
		border: 1px solid #465190;
	}
}

.btn-checkbox-minus {
	width: 20px;
	height: 20px;
	border: 1px solid #C1C7CD;
	border-radius: 3px;
	background-color: #fff;
	padding: 0;
	&.checked {
		background-image: url(../img/checked-minus.png);
		background-size: 6px 2px;
		background-repeat: no-repeat;
		background-position: 6px 8px;
	}
	&:hover {
		border: 1px solid #465190;
	}
}

.btn-xs-checkbox {
	width: 16px;
	height: 16px;
	border: 1px solid #C1C7CD;
	border-radius: 3px;
	background-color: #fff;
	padding: 0;
	&.checked {
		border-color: #465190;
		&:after {
			font-family: 'fontAwesome';
			content: "\f00c";
			color: #000;
			font-size: 11px;
			position: relative;
			top: -6px;
		}
	}
	&:hover {
		border: 1px solid #465190;
	}
}

.btn-xs-checkbox2 {
	width: 20px;
	height: 20px;
	border: 1px solid #C1C7CD;
	border-radius: 3px;
	background-color: #fff;
	padding: 0;
	margin-right: 22px;
	&.checked {
		border-color: #465190;
		background-color: #564FDB;
		&:after {
			font-family: 'fontAwesome';
			content: "\f00c";
			color: #fff;
			font-size: 11px;
			position: relative;
			top: -6px;
		}
	}
	&:hover {
		border: 1px solid #465190;
	}
}

.btn-borderless {
	background-color: transparent;
	padding: 0px 0px;
	color: #337ab7;
	&:hover {
		background-color: transparent;
		color: #337ab7;
		text-decoration: underline;
	}
}

.btn-outline {
	border: 1px solid #3E3C82;
	background-color: transparent;
	color: #3E3C82;
	font-size: 14px;
	border-radius: 30px;
	padding: 0 20px;
	line-height: 30px;
	@extend .flex-container;
	@extend .align-items-center;
	&:hover {
		color: #4945D9;
		border-color: #4945D9;
	}
}

.btn-deeplink {
	text-decoration:none;
	background-color:#6472C7;
	color:#ffffff;
	font-size:13px;
	padding:12px 25px;
	border-radius:30px;
	cursor:pointer;
	position: relative;
	display: inline-block;
	i {
		padding-left:10px;
		position: relative;
		vertical-align: bottom;
		@extend .arrow-right;
	}
}

.btn-add-candidate {
	background-color: #98A1D8;
	@include rounded(5px);
	border: 0;
	font-size: 12px;
	padding: 7px 20px;
	color: $white;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &.active {
		background-color: $purple01;
		color: $white;
	}
	&:focus {
		color: $white;
	}
}

.btn-add-candidate2 {
	background-color: #564FDB;
	@include rounded(5px);
	width: auto;
	border: 0;
	font-size: 18px;
	padding: 6px 12px;
	font-family: "Open Sans", Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	text-align: center;
	color: #FFFFFF;
	@include all-transition(0.3s,0s);
	i {
		margin-right: 10px;
	}
	&:hover, &.active {
		background-color: $purple01;
		color: $white;
	}
	&:focus {
		color: $white;
	}
}

.font-add-candidate {
	font-family: "Open Sans", Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: normal;
	text-align: center;
	color: #FFFFFF;
}

#theme-purple {
	.btn {
		background-color: $gray;
		border-radius: $btn-border-radius;
		border-width: 3px;
		border-color: transparent;
		color: $white;
		cursor: pointer;
		display: inline-block;
		font-size: 18px;
		line-height: 25px;
		font-weight: 200;
		margin: 0;
		padding: 10px 16px;
		width: auto;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-image: none;
		border: 3px solid transparent;
		transition: all 300ms ease;
		@at-root #{&}__primary {
			background-color: $purple01;
			&:hover {
				background-color: $purple02;
			}
			&:focus {
				background-color: $purple01;
				border-width: 3px;
				border-color: $purple01;
				border-style: solid;
			}
			&:active {
				background-color: $purple01;
				border-color: #3C00AB;
			}
			&:disabled {
				background-color: $gray;
				cursor: not-allowed;
			}
			@at-root #{&}--loading {
				background-color: $purple01;
			}
			i {
				color: $white;
				&:hover, &:focus {
					color: $white;
				}
			}
		}
		@at-root #{&}__secondary {
			background-color: transparent;
			border-color: $purple01;
			color: $purple01;
			&:hover {
				border-width: 3px;
				border-color: $purple02;
				border-style: solid;
			}
			&:focus {
				border-width: 3px;
				border-color: #D4BDFF;
				border-style: solid;
			}
			&:active {
				border-color: #3C00AB;
			}
			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
			@at-root #{&}--loading {
				background-color: $purple01;
			}
			i {
				color: $purple01;
				&:hover, &:focus {
					color: $purple02;
				}
			}
		}
		@at-root #{&}__text-only {
			background-color: transparent;
			font-size: 18px;
			color: $purple01;
			padding: 0;
			border-width: 1px;
			&:hover {
				color: $purple01;
			}
			&:focus {
				border-color: #D4BDFF;
			}
			&:disabled {
				color: $purple02;
				cursor: not-allowed;
			}
			&:active {
				color: $purple01;
			}
		}
		&.block {
			display: block;
		}

		@at-root #{&}--small {
			font-size: 12px;
			line-height: 16px;
			border-radius: 3px;
			padding: 6px 10px;

			[class^="icon"] {
				font-size: 10px;
			}
		}
		@at-root #{&}--medium {
			font-size: 14px;
			line-height: 22px;
			border-radius: 4px;
			padding: 6px 12px;

			[class^="icon"] {
				font-size: 18px;
			}
		}
		@at-root #{&}--large {
			font-size: 18px;
			line-height: 25px;
			border-radius: 6px;
			padding: 10px 16px;

			[class^="icon"] {
				font-size: 24px;
			}
		}
	}
}

.button {
	font-size: 12px;
	line-height: 16px;
	border-radius: 3px;
	padding: 6px 10px;
	font-weight: 200;
	border-width: 3px;
	background-color: $gray;
	border-color: transparent;
	color: $white;
	cursor: pointer;
	display: inline-block;
	width: auto;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	transition: all 300ms ease;
	@at-root #{&}__primary {
		background-color: $purple01;
		&:hover {
			background-color: $purple02;
		}
		&:focus {
			background-color: $purple01;
			border-width: 1px;
			border-color: $purple01;
			border-style: solid;
		}
		&:active {
			background-color: $purple01;
			border-color: #3C00AB;
		}
		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
		@at-root #{&}--loading {
			background-color: $purple01;
		}
	}
	@at-root #{&}__secondary {
		background-color: transparent;
		border-color: $purple01;
		color: $purple01;
		&:hover {
			border-width: 1px;
			border-color: $purple01;
			border-style: solid;
		}
		&:focus {
			border-width: 1px;
			border-color: #D4BDFF;
			border-style: solid;
		}
		&:active {
			border-color: #3C00AB;
		}
		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
		@at-root #{&}--loading {
			background-color: $purple01;
		}
	}
	@at-root #{&}__video {
		background-color: transparent;
		border-color: $purple01;
		color: $purple01;
		&:hover {
			border-width: 1px;
			border-color: $purple01;
			border-style: solid;
		}
		&:focus {
			border-width: 1px;
			border-color: #D4BDFF;
			border-style: solid;
		}
		&:active {
			border-color: #3C00AB;
		}
		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
		@at-root #{&}--loading {
			background-color: $purple01;
		}
		i {
			margin-right: 2px;
			@extend .icon-i-video-upload;
			color: $purple01;
			&:hover, &:focus {
				color: $purple02;
			}
		}
	}
	@at-root #{&}__document {
			background-color: transparent;
			border-color: $purple01;
			color: $purple01;
			&:hover {
				border-width: 3px;
				border-color: $purple02;
				border-style: solid;
			}
			&:focus {
				border-width: 3px;
				border-color: #D4BDFF;
				border-style: solid;
			}
			&:active {
				border-color: #3C00AB;
			}
			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
			@at-root #{&}--loading {
				background-color: $purple01;
			}
			i {
				margin-right: 2px;
				@extend .icon-i-document-black;
				color: $purple01;
				&:hover, &:focus {
					color: $purple02;
				}
			}
		}
	@at-root #{&}__text-only {
		background-color: transparent;
		font-size: 18px;
		color: $purple01;
		padding: 0;
		border-width: 1px;
		&:hover {
			color: $purple01;
		}
		&:focus {
			border-color: #D4BDFF;
		}
		&:disabled {
			color: $purple02;
			cursor: not-allowed;
		}
		&:active {
			color: $purple01;
		}
	}
	@at-root #{&}__danger {
		background-color: $red;
		&:hover {
			background-color: $bg-red;
		}
	}
	&.block {
		display: block;
	}

	@at-root #{&}--small {
		font-size: 12px;
		line-height: 16px;
		border-radius: 3px;
		padding: 6px 10px;

		[class^="icon"] {
			font-size: 10px;
		}
	}
	@at-root #{&}--medium {
		font-size: 14px;
		line-height: 22px;
		border-radius: 4px;
		padding: 6px 12px;

		[class^="icon"] {
			font-size: 18px;
		}
	}
	@at-root #{&}--large {
		font-size: 18px;
		line-height: 25px;
		border-radius: 6px;
		padding: 10px 16px;

		[class^="icon"] {
			font-size: 24px;
		}
	}
	@at-root #{&}--transparent {
		background-image: none;
		background-color: transparent;
		padding:0;
		line-height: normal;
		border: 1px solid transparent;
	}
}

/*
* RADIO BUTTON
*/

.button {
	@at-root #{&}__radio {
		> li {
			float: left;
			position: relative;
			display: block;
			> a {
				border: 1px solid $purple01 !important;
				border-radius: 3px;
				color: $purple01;
				margin: 0;
				cursor: pointer;
				line-height: 1.42857143;
				padding: 7px 10px;
				&:hover, &:focus {
					background-color: $purple01;
					color: $white;
				}
			}
			&.active {
				a, a:hover, a:focus {
					background-color: $purple01;
					color: $white;
				}
			}
			&:first-child {
				a {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&:last-child {
				a {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}

.button-continue {
	min-width: 100% !important;
	height: 50px !important;
	border-radius: 5px !important;
	color: white !important;
	font-size: 16px !important;
	font-weight: bold !important;
}

.button-continue:disabled {
    background-color: #999999 !important;
    border-color: #999999 !important;
}

@media screen and (max-width: 767px) and (orientation: portrait){
	.btn-nav-prev-2 {display: none;}
	.btn-nav-next-2 {display: none;}
	.btn-close-vid-full {display: none;}
}