/*
*
* ASTRONAUT WEB APP
* PAGES
* VERSION 1.0
*
*/


/*
* HOME
*/

.title-heading {
	font-size: 14px;
	font-weight: bold;
	color: $text-color;
	padding-bottom: 10px;
	text-transform: uppercase;
	border-bottom: 0;
}

h5.title-heading {
  @extend .title-heading;
	text-transform: uppercase;
}

h5.title-heading-unborder {
	font-size: 14px;
	font-weight: bold;
	color: $text-color;
	padding-bottom: 10px;
	text-transform: uppercase;
	border-bottom: 0;
}

.forum-detail {
	padding: 10px 20px;
}

.forum-info {
	text-align: center;
	border-left: 1px solid $border-color;
	padding: 7px 14px;
}

.forum-icon {
	float: left;
	width: 30px;
	margin-right: 20px;
	text-align: center;
	height: 60px;
	padding: 22px 20px;
}

.forum-status {
	margin-right: 20px;
	.label {
		font-size: 13px;
		vertical-align: middle;
		display: inline-block;
		min-width: 110px;
		line-height: 22px;
	}
}

.forum-item {
	border: 1px solid $border-color;
	border-bottom: 0;
	.btn-checkbox {
		opacity: 1;
		margin-top: 7px;
	}
	&.show-boxes {
		.btn-checkbox {
			opacity: 1;
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
	&:hover, &:focus {
		.btn-checkbox {
			opacity: 1;
		}
	}
}

.forum-item2 {
	border-bottom: 1px solid $border-color;
	.btn-checkbox {
		opacity: 1;
		margin-top: 7px;
	}
	&.show-boxes {
		.btn-checkbox {
			opacity: 1;
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
	&:hover, &:focus {
		.btn-checkbox {
			opacity: 1;
		}
	}
}

.views-number {
	font-size: 24px;
	font-weight: 400;
	color: #909090;
	padding-top: 5px;
}

.forum-item {
	small {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
		&.sourced {
			color: #2A3788;
		}
		&.applied {
			color: $purple;
		}
		&.shortlist {
			color: #58A5C9;
		}
		&.offer {
			color: #70C780;
		}
		&.disqualified {
			color: $red;
		}
	}
	.forum-sub-title {
		color: #999;
		margin-top: -7px;
	}
}

.forum-item2 {
	small {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
		&.sourced {
			color: #2A3788;
		}
		&.applied {
			color: $purple;
		}
		&.shortlist {
			color: #58A5C9;
		}
		&.offer {
			color: #70C780;
		}
		&.disqualified {
			color: $red;
		}
	}
	.forum-sub-title {
		color: #999;
		margin-top: -7px;
	}
}

.forum-title {
	margin: 15px 0 15px 0;
}

.forum-desc {
	color: #999;
}

a.forum-item-title {
	color: $text-color;
	display: block;
	font-size: 20px;
	font-weight: 600;
	display: inline-block;
	@include ellipsis(550px);
	&:hover {
		color: $purple;
	}
}

.forum-icon .fa {
	font-size: 30px;
	margin-top: 8px;
	color: #9b9b9b;
}

.forum-item.active {
	.fa {
		color: #1ab394;
	}
	a.forum-item-title {
		padding-bottom: 12px;
	}
}

.flex-container.forum-sub-title div {
	margin-right: 14px;
}

.forum-date {
	color: #4D4D4D;
	p {
		//border-right: 1px solid $border-color;
		padding-right: 10px;
		margin-bottom: 0;
		display: inline-block;
	}
}

.forum-expired {
	color: #BE5757;
	font-weight: 600;
}

.forum-place {
	color: #757575;
	font-weight: bold;
}

.forum-checker input {
	width: 15px;
	height: 15px;
	cursor: pointer;
}

/*
* CANDIDATE LIST VIEW
*/

.hlist-detail {
	height: 59px;
}

.hlist-info {
	text-align: center;
	padding: 12px 20px;
}

.rating-profile, .hlist-info, .votes-profile .votes,
.notif-profile, .hlist-info, .votes-profile .notifs {
	.message-info, .rating-info, .share-info, .question-info {
		padding: 0 3px;
		position: relative;
		display: block;
	}
}

.rating-profile, .hlist-info, .votes-profile, .notif-profile {
	.count-info .label {
		line-height: 12px;
		padding: 2px 5px;
		position: absolute;
		right: 0;
		top: -6px;
		border-radius: 50%;
	}
}

.rating-profile {
	position: absolute;
	bottom: 20px;
	right: 10px;
}

.star-rating-profile {
	position: absolute;
	bottom: 20px;
	left: 10px;
}

.votes-profile {
	position: relative;
	z-index: 99;
}

.notif-profile {
	position: absolute;
	bottom: 20px;
	left: 50px;
}

.hlist-icon {
	float: left;
	width: 30px;
	margin-right: 20px;
	text-align: center;
	height: 60px;
	padding: 22px 20px;
}

.hlist-item {
	border-bottom: 1px solid $border-color;
	margin: 0 -20px;
	&.select-boxes {
		.btn-checkbox {
			opacity: 1;
		}
		.check-profile {
			z-index: 99;
			position: absolute;
			right: 10px;
			top: 15px;
		}
		&.show-boxes {
			.btn-checkbox {
				opacity: 1;
			}
		}
		&:hover, &:focus {
			.btn-checkbox {
				opacity: 1;
			}
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
}

.hlist-item2 {
	border-bottom: 1px solid $border-color;
	&.select-boxes {
		.btn-checkbox {
			opacity: 1;
		}
		.check-profile {
			z-index: 99;
			position: absolute;
			right: 10px;
			top: 15px;
		}
		&.show-boxes {
			.btn-checkbox {
				opacity: 1;
			}
		}
		&:hover, &:focus {
			.btn-checkbox {
				opacity: 1;
			}
		}
	}
	&:last-child {
		border-bottom: 1px solid $border-color;
	}
}

.hlist-desc {
	color: #3D3D3D;
	-webkit-box-align: center;
			-ms-flex-align: center;
					align-items: center;
	height: 100%;
}

.flex-container.hlist-sub-title div {
	margin-right: 14px;
}

.hlist-name {
	font-weight: bold;
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	color: #3D3D3D;
	width: 80%;
	p {
		margin: 0;
	}
}

.hlist-job-title {
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	color: #4B4F85;
	font-weight: 600;
	width: 115px;
	text-align: center;
}

.hlist-stats {
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
}

.hlist-rating {
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	.count-info {
		padding: 0 6px;
		position: relative;
		cursor: inherit;
	}
}

.hlist-score {
	padding: 0 10px;
	border-right: 1px solid #dbdbdb;
	.score-info {
		line-height: 15px;
		margin-left: 5px;
		p {
			margin-bottom: 0;
			font-size: 12px;
		}
	}
}

.hlist-star-rating {
	padding: 0 10px;
	h2 {
		margin: 0;
		padding: 0;
		font-size: 21px;
		font-weight: 600;
	}
}

.profile-title {
	width: 55%
}

.score-test {
	i {
		font-style: normal;
		font-size: 18px;
	}
	&.right {
		line-height: 7px;
		p {
				font-size: 15px !important;
			}
		}
		.score-info {
			margin-right: 0px;
			text-align: right;
	}
}

.score-test-candidate-list {
	width: 45%;
	display: flex;
  flex-direction: column-reverse;
	i {
		font-style: normal;
		font-size: 18px;
	}
	&.right {
		line-height: 7px;
		p {
				font-size: 15px !important;
			}
		}
		.score-info {
			margin-right: 0px;
			text-align: right;
	}
}


/*
* CANDIDATE BOX VIEW
*/

.wrapper-box-profile {
	min-width: 226px;
	max-width: 226px;
	padding: 10px 0 25px;
	margin: 0 15px;
	overflow: hidden;
	min-height: 347px;
	.img-profile {
		min-height: 224px;
		background-color: #f7f7f7;
			img {
				width: 100%;
			}
		}
	.profile-detail {
		font-size: 12px;
		.profile-name {
			@include ellipsis(145px);
			a {
				color: #6B97E2;
				font-weight: bold;
				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
		.job-title-profile {
			margin-top: 5px;
			@include ellipsis(145px);
			a {
				color: #3B3B3B;
				font-weight: normal;
				cursor: default;
			}
		}
		.job-title-profile a {
			color: #3B3B3B;
			font-weight: normal;
			cursor: default;
		}
		.question-wrap {
			flex-grow: 2;
			padding: 3px 0;
		}
		.rating-wrap {
			flex-grow: 1;
			padding: 0 10px;
			border-left: 1px solid $border-color;
			.count-info {
				padding: 0 3px;
				position: relative;
				display: block;
				.label {
					right: -6px;
					top: -6px;
				}
			}
		}
	}
	.btn.box-profile {
		color: #fff;
		white-space: inherit;
		padding: 10px 0;
		@include rounded($none-rounded);
		position: relative;
		box-shadow: none;
		width: 100%;
		&.select-boxes {
			.btn-checkbox {
				opacity: 1;
			}
			.check-profile {
				position: absolute;
				right: 10px;
				top: 15px;
				z-index: 0;
			}
			&.show-boxes {
				.btn-checkbox {
					opacity: 1;
				}
			}
			&:hover, &:focus {
				.btn-checkbox {
					border: 1px solid #465190;
				}
			}
		}
		.img-profile {
			z-index: 98;
		}
	}
	&.active {
		border-color: #476881;
	}
}

.wrapper-vote {
	justify-content: space-around !important;
	div {
		padding-right: 5px;
		font-size: 12px;
		b {
			display: block;
			text-align: center;
			margin-top: 5px;
		}
	}
	.btn {
		text-align: center;
		margin: 0 auto;
	}
}

/*
* CUSTOM FORM - CREATE JOB
*/

.form-custom {
	label {
	font-size: 16px;
	font-weight: bold;
	}
	.form-group {
		margin-bottom: 30px;
	}
	.form-control {
		@include rounded($none-rounded);
	}
	textarea:first-child {
		height: 200px;
	}
}

.wrapper-barcode {
	div {
		margin: 10px 0;
	}
	b {
		color: #050505;
	}
	.code-here {
		color: #F16C6C;
		font-weight: bold;
		margin-right: 10px;
	}
	input[type="text"] {
		vertical-align: middle;
		margin: 0 5px;
		display: inline-block;
		height: 34px;
		width: 158px;
		&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
			color: #A1A1A1;
		}
		&:-moz-placeholder {
			opacity: 1;
		}
	}
}

/*
* CUSTOM BREADCRUMBS
*/

.wrapper-breadcrumb {
	.breadcrumb {
		background-color: transparent;
		margin: 0;
		padding: 0;
		> li {
			&.active {
				a {
					color: $purple;
					font-weight: 600;
				}
			}
			> a {
				color: #444444;
			}
		}
		> li + li:before {
			font-family: FontAwesome;
			font-weight: bold;
			font-style: normal;
			content: "\f105";
			color: #444444;
			padding: 0 10px;
		}
	}
}

.custom-modal .modal-content  {
	border-radius: 10px;
}

.wrapper-modal-addbranding {
	&.modal-dialog {
		width: 628px;
	}
	.modal-header {
		padding: 10px 35px 10px;
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		color: #454545;
		&.modal-title {
			margin-right: 20px;
		}
	}
	.modal-body {
		padding: 20px;
		h4 {
			margin-bottom: 15px;
			text-align: left;
		}
		label {
			font-weight: bold;
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

.wrapper-modal-percandidate {
	label {
		display: none;
	}
	&.modal-dialog {
		width: 824px;
	}
	.modal-header {
		padding: 10px 35px 10px;
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		color: #454545;
		&.modal-title {
			margin-right: 20px;
		}
	}
	.modal-body {
		padding: 20px;
		h4 {
			margin-bottom: 15px;
			text-align: left;
		}
		label {
			font-weight: bold;
		}
		.table-percandidate {
			margin-top: 10px;
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

.wrapper-modal-share {
	&.modal-dialog {
		width: 628px;
	}
	.modal-header {
		padding: 10px 35px 10px;
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		color: #454545;
		font-weight: bold;
		&.modal-title {
			margin-right: 20px;
		}
	}
	.modal-body {
		padding: 20px;
		h4 {
			margin-bottom: 15px;
			text-align: left;
		}
		label {
			font-weight: 600;
			margin-bottom: 15px;
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

.wrapper-modal-candidatelist {
	.modal-header {
		border-bottom: 0;
	}
	.modal-body {
		td {
			word-wrap: break-word;
		}
	}
}


/*
* CUSTOM TABS AND MODAL
*/

.full-width-tabs > ul.nav.nav-tabs {
		display: table;
		width: 100%;
		table-layout: fixed;
		> li {
			float: none;
			display: table-cell;
			> a {
					text-align: center;
			}
			&.wrap-space{
					width:100%;
			}
		}
}

.wrapper-modal-import {
	&.modal-dialog {
		min-width: 824px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		h4 {
			font-size: 16px;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 0;
		.uploads {
			.panel-body {
				padding: 15px 40px;
			}
		}
	}
}

.wrapper-modal-addcandidate {
	&.modal-dialog {
		width: 562px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 0;
		.import-input,.manual-input {
			width: 50%;
			text-align: center;
			padding: 40px 0 20px;
		}
		.manual-input img {
			padding: 13px 0;
		}
		.import-input {
			border-right: 1px solid $border-color;
		}
		p {
			color: $purple;
			font-size: 12px;
			padding-top: 15px;
			font-weight: bold;
		}
	}
}

.tabs-add-candidates {
	ul.nav.nav-tabs > li {
		background-color: #030A10;
		&.active {
			border: 0;
			background: #3B7895;
			> a {
				background-color: #3B7895;
				color: $white;
				&:hover,&:focus {
					background-color: #3B7895;
					border: 0;
				}
			}
		}
		> a {
			@include rounded($none-rounded);
			border: 0;
			background-color: #030A10;
			font-size: 14px;
			font-weight: 600;
			color: $white;
			&:hover,&:focus {
				background-color: #3B7895;
				border: 0;
			}
		}
	}
}

.wrapper-modal-question {
	&.modal-dialog {
		width: 645px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 0px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		padding: 40px 35px 20px;
		label {
			font-weight: 600;
		}
		.form-control {
			@include rounded($none-rounded);
		}
		.select2 .Select-control {
			width: 100%;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 25px;
	}
}

.wrapper-modal-duplicate {
	&.modal-dialog {
		width: 645px;
		position: absolute !important;
		top: 45% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 0px solid $border-color;
		h4 {
			margin: 0;
			font-size: 18px;
			color: #000000;
			&.modal-title {
				margin-right: 20px;
				font-family: "Open Sans", Helvetica;
				font-style: normal;
				font-weight: bold;
				line-height: normal;
				padding-top: 25px;
			}
		}
		.close-modal {
			position: absolute;
			right: 18px;
			top: 10px;
		}
	}
	.modal-body {
		padding: 20px 35px 20px;
		label {
			font-weight: 600;
		}
		.form-control {
			@include rounded($none-rounded);
		}
		.select2 .Select-control {
			width: 100%;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 25px;
	}
}

.wrapper-candidate-detail {
	.nav-candidate-detail {
		height: 20px;
		.nav-list {
			width: calc(100% - 50px);
			button {
				width: 50%;
				border-radius: 8px 0 0 0;
				border-right: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
			}
		}
	}
	.close-modal {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 13px;
		top: 13px;
	}
	.modal-header {
		border-bottom: 0px solid #B9B9B9;
		padding-bottom: 2px;
	}
	.modal-content {
		background-color: #F8F8F8;
	}
	.modal-body {
		padding: 0;
	}
	.detail-header {
		position: relative;
		padding: 5px 15px;
		line-height: 20px;
		font-weight: 600;
		h4 {
			font-size: 24px;
			display: inline-block;
			margin-right: 15px;
			line-height: 1;
			a {
				cursor: default;
				color: #656565;
				@include ellipsis(350px);
				display: inline-block;
				vertical-align: bottom;
			}
			small {
				font-size: 14px;
				margin-left: 15px;
				display: inline-block;
				vertical-align: bottom;
				line-height: normal;
			}
		}
		.star-summary {
			vertical-align: text-top;
			border-radius: 10px;
		}
		p {
			margin-bottom: 0;
			color: #656565;
			font-weight: normal;
			line-height: 25px;
			visibility: visible;
			&:first-child {
				margin-bottom: 5px;
			}
		}
		.box-action {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
	.detail-actions {
		border-bottom: 1px solid #B9B9B9;
		.detail-job-name {
			border-right: 0px solid #B9B9B9;
			padding: 5px 38px;
		}
		.detail-tabs {
			padding: 0;
			.btn-image {
			}
			.nav > li {
				border-right: 0px solid #ddd;
				&:first-child {
					border-left: 0;
				}
				&:last-child {
					border-right: 0;
				}
				&.active, &:hover, &:focus {
					border-left: 0;
					border-bottom: 2px solid $purple;
					a {
						color: #4B4F85;
					}
				}
				> a {
					padding: 9px 5px;
					border-bottom: 0;
					background: #f8f8f8;
					color: inherit;
					border: 0;
				}
			}
		}
	}
	.detail-body {
		padding: 0 15px;
		.box-detail-top {
			.tabs {
				padding: 20px 0;
			}
			.rating {
				font-size: 21px;
			}
			.tab-content .box-panel{
				&:not(:first-child) {
					.box-header {
						border-top: 0px solid #B9B9B9;
						padding-bottom: 1px;
					}
				}
			}
		}
		h4 {
			font-weight: bold;
		}
		.box-detail-bottom {
			border-top: 0px solid $border-color;
			padding: 25px 15px;
		}
	}
}

.wrapper-modal-customcode {
	&.modal-dialog {
		width: 377px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		.preview-code {
			width: 300px;
			background-color: $light-grey;
			padding: 5px 10px;
			text-align: left;
			margin: 0 auto;
			small {
				color: #A1A1A1;
			}
			h1 {
				font-size: 24px;
				font-weight: bold;
				color: $purple;
				margin: 8px 0 5px;
				text-align: center;
			}
		}
		h4 {
			margin-bottom: 25px;
		}
		input[type="text"] {
			width: 300px;
			@include rounded($none-rounded);
			margin: 0 auto;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
}

.wrapper-modal-introvideo {
	&.modal-dialog {
  	width: 720px;
  	height: 480px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		h4 {
			margin-bottom: 25px;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.videoModal {
		width: 100%;
	}
}

.wrapper-modal-welcomevideo {
	&.modal-dialog {
  	width: 720px;
  	height: 480px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px;
		margin: 0 auto;
		text-align: center;
		h4 {
			margin-bottom: 25px;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.videoModal {
		width: 100%;
	}
}

.wrapper-modal-addinfo {
	&.modal-dialog {
		width: 377px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px 25px;
		margin: 0 auto;
		text-align: center;
		.instruction {
			font-size: 12px;
			padding-right: 32px;
		}
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
}

.wrapper-profile {
	// background-color: $white;
	padding: 0px 15px;
	.form-group {
			margin-bottom: 0px;
		}
}

.username {
	display: inline-block;
	padding: 0px 5px;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: relative;
	top: 4px;
	text-transform: uppercase;
	@include ellipsis(120px);
}

/*
* CUSTOM INVITES
*/

.invite-list-container {
	.invite-list {
		padding: 10px 5px 0 0;
		border-bottom: 1px solid $border-color;
		label {
			width: 100%;
			cursor: pointer;
		}
		.dividing {
			border-bottom: 1px solid $border-color;
			display: inline-block;
			padding: 0 10px;
		}
		h4 {
			color: #555555;
			font-size: 13px;
			display: inline-block;
			padding-left: 10px;
			font-weight: normal;
			margin: 0;
			vertical-align: text-top;
		}
		.invite-stats {
			padding: 15px 0 5px 30px;
			-webkit-box-align: center;
					-ms-flex-align: center;
							align-items: center;
			font-weight: 600;
			> div {
				margin-right: 20px;
			}
		}
	}
}

/*
* CUSTOM ADDQUESTION
*/

.loadmore {
	margin-top: 20px;
}

.header-folder {
	margin: 0px 10px;
	.nav-folder {
		padding: 10px 0;
	}
}

.question-folder-library {
	border: 1px solid $border-color;
	@include rounded(8px);
	border-top-right-radius: 0;
	display: flex;
}

.wrapper-question {
	.folder-question {
		background: $white;
		border: 2px solid rgba(216, 216, 216,0.8);
		width: 180px;
		height: 100%;
		border-radius: 6px;
		color: #929292;
		margin: 10px 5px;
		box-shadow: 0 3px 2px rgba(0, 0, 0, 0.05);
		position: relative;
		.sticky {
			width: 100%;
			position: absolute;
			top: 0;
			z-index: 99;
			visibility: hidden;
		}
		h3 {
			margin: 0;
			padding: 10px 15px;
			font-size: 12px;
			font-weight: normal;
			line-height: 18px;
			position: relative;
			@include ellipsis(160px);
			.btn-folder {
				@extend .icon-i-folder;
			}
		}
		&:hover, &:focus, &.selected {
			border: 2px solid $purple;
			background: $purple;
			.sticky {
				z-index: 99;
				visibility: visible;
			}
			h3 {
				color: $white;
				.btn-folder {
					@extend .icon-i-folder-active;
				}
			}
		}
		&.active {
			border-color: $purple;
		}
	}
}

.img-container {
	margin: 10px 0;
	.img-preview-sm {
		position: relative;
		overflow: hidden;
		text-align: center;
		height: auto;
		max-width: 200px;
		img {
			max-width: 100%;
		}
	}
}

.img-container-ftq {
	margin: 10px 0;
	.img-preview-ftq {
		position: relative;
		overflow: hidden;
		text-align: center;
		height: 150px;
		width: 150px;
		img {
			max-width: 150px;
			max-height: 150px
		}
	}
}

/*
* CUSTOM WIZARD
*/

.wrapper-tabs {
	margin: 20px 0;
}
.wrapper-wizard {
	.heading-wizard {
		position: relative;
		padding: 15px 0 0;
		margin: 0 -20px !important;
		border-bottom: 4px solid $border-color;
		text-align: center;
		margin: 0 auto;
		ul.list-wizard > li {
			display: inline-block;
			&:not(:last-child) {
				&:after {
					font-family: 'fontAwesome';
					content: "\f178";
					font-size: 20px;
					padding: 0 20px;
					color: #909090;
				}
			}
			> a {
				font-size: 12px;
				color: #ADADAD;
				font-weight: bold;
				vertical-align: text-top;
				&.active, &:hover, &:focus {
					color: #5689D0;
				}
			}
		}
		.question-mode {
			border-top: 1px solid #d8d8d8;
			margin: 0 auto;
			background: #d8d8d8;
			h4 {
				color: #343434;
				margin: 0;
				padding: 10px 0;
				b {
					color: #5689D0;
				}
			}
		}
		.question-switcher {
			position: absolute;
			width: 130px;
			margin-left: 20px;
			padding: 10px 0;
			p {
				padding: 2px 0;
			}
		}
		.question-title {
			position: absolute;
			text-align: left;
			left: 20px;
			p {
				margin: 0;
				font-weight: 600;
				font-size: 13px;
			}
		}
	}
	.body-wizard {
		padding: 40px 0 20px;
		.tabs-wizard {
			padding: 30px 0 20px;
			ul > li {
				display: inline-block;
				> a {
					font-size: 14px;
					color: #a4a4a4;
					font-weight: bold;
					padding: 10px 15px;
					border-right: 1px solid $border-color;
					i {
						height: 16px;
					}
					&:hover, &:focus, &.active {
						color: #45667F;
					}
				}
			}
			.btn:first-child {
				margin-right: 15px;
			}
		}
	}
	.footer-wizard {
		text-align: right;
	}
}

/*
* CUSTOM INVITE FORM
*/

.invite-form {
	margin: -30px 0 40px;
	.box-left {
		padding: 0;
		border: 1px solid $border-color;
		.text-from {
			border-radius: 5px 5px 0 0;
			background: #F2F2F2;
			color: #424242;
		}
		.text-subject {
			@include rounded($dropdown-rounded);
			border-top: 0;
			background: #F2F2F2;
			color: #424242;
		}
		.textarea-content {
			background: #F2F2F2;
			color: #424242;
			overflow: auto;
		}
	}
	.box-right {
		padding: 14px 20px;
		border: 1px solid $border-color;
		border-left: 0;
		h4 {
			text-align: center;
			font-size: 18px;
			color: #5B5B5B;
			margin-bottom: 20px;
		}
		&.preview {
			border-left: 1px solid $border-color;
		}
	}
}

.wrapper-question-preview {
	height: 781px;
	position: relative;
	padding: 10px;
	border: 1px solid $border-color;
	border-radius: 5px;
	margin-right: 15px;
	.footer-preview {
		position: absolute;
		bottom: -76px;
		text-align: right;
		width: 100%;
		padding: 21px 15px;
		border-left: 1px solid $border-color;
		height: 75px;
		margin-left: -11px;
	}
}

.question-preview {
	height: 500px;
	position: relative;
	border: 1px solid $border-color;
	@include rounded(8px);
	.footer-preview {
		text-align: center;
		margin:0 auto;
		padding: 6px 20px;
		flex: initial;
	}
}

.question-list-preview {
	flex: 1;
	padding: 6px 12px;
}

.wrapper-question-invitation {
	height: 781px;
	position: relative;
	padding: 10px;
	border-left: 0;
	margin-right: 15px;
	.footer-preview {
		position: absolute;
		bottom: -76px;
		text-align: right;
		width: 100%;
		padding: 21px 15px;
		height: 75px;
	}
}

.section--title {
	h4 {
		font-size: 12pt;
		margin-top: 46px;
		padding: 0 15px;
	}
}

.selected--title {
	height: 73px;
	padding: 35px 15px;
	h4 {
		font-size: 12pt;
		margin: 0;
		padding-top: 10px;
	}
}

.folder--title {
	h4 {
		font-size: 12pt;
		margin: 0;
		padding-top: 10px;
		padding-left: 10px;
	}
}

.custom-setting, .header-setting {
	border-bottom: 1px solid #D8D8D8;
	margin: 0 -20px;
	position: relative;
	background-color: $white;
}

.custom-setting {
	padding: 0 20px;
}

.header-setting {
	padding: 10px 20px;
	.input-group {
		max-width: 235px;
	}
	.form-control {
		height: 40px;
	}
	.react-datepicker-wrapper {
		height: 35px;
	}
}

.wrapper-preview {
	.header-preview {
		h5 {
			font-size: 14px;
			text-transform: capitalize;
			padding: 10px 15px;
			background-color: $navy;
			color: $white;
		}
	}
	.body-preview {
		padding: 20px 10px;
	}
}

.box-header {
	margin-left: -8px;
	margin-right: -15px;
	padding: 10px 20px;
	border-bottom: 0px solid #B9B9B9;
	padding-bottom: 1px;
	.btn {
		border: 1px solid;
		padding: 2px 12px;
	}
	h4 {
		font-weight: bold;
		display: inline-block;
		margin-right: 20px;
	}
}

.box-body {
	padding: 10px 0;
	border-bottom: 1px solid #B9B9B9;
}

.box-body-history {
	font-size: 13px !important;
	padding-left: 12px;
	padding-right: 12px;
}

.table-head-history {
	background-color: lightgrey;
}

.tab-pane-profile {
	height: 512px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.box-panel {
	> h4 {
		margin-bottom: 20px;
	}
	.panel {
		background: transparent;
		@include rounded($none-rounded);
		border: 0;
		margin-right: 12px;
		.panel-heading {
			padding: 0;
			position: relative;
			display: block;
			margin-bottom: 5px;
			background-color: transparent;
			.wrap-player {
				position:relative;
			}
			h4 {
				padding: 0;
				a {
					padding: 13px 40px 13px 16px;
					position: relative;
					display: block;
					font-size: 14px;
					color: $text-color;
					font-weight: normal;
					border: 2px solid #cdcdcd;
					line-height: 20px;
					background: $white;
					@include all-transition(0.3s,0s);
					&:hover, &:focus {
						border-color: $purple;
					}
					span {
						display: block;
						padding-left: 15px;
					}
				}
			}
		}
		.panel-body {
			border: 0;
			padding: 0 10px 15px;
		}
	}
	.status {
		position: absolute;
		float: right;
		top: 15px;
		right: 15px;
		&.dots:before {
			content: ' \25CF';
			font-size: 20px;
			color: $red;
		}
	}
	.wrapper-comments {
		background: #ffffff;
		border-radius: 5px;
		padding: 15px;
		border: 2px solid #CDCDCD;
		h4 {
			margin-top: 0;
		}
		.media-body div {
			word-break: break-word;
		}
	}
	.scroll {
		height: 440px;
		overflow-y: auto;
	}
	.panel-group .panel-heading + .panel-collapse > {
		.panel-body {
			border-top: 0;
		}
		.list-group {
			border-top: 0;
		}
	}
}

.wrapper-video {
	padding:25px 30px 50px;
	border-right: 0px solid #B9B9B9;
	.box-video {
		height: 390px;
		border: 1px solid #B9B9B9;
	}
	.video-info {
		padding-top: 10px;
	}
}

.wrapper-cv {
	height: 584px;
	border: 1px solid #ddd;
	border-radius: 10px;
}

.wrapper-comments {
	.comment-form {
		clear: both;
		position: relative;
		display: block;
		margin-bottom: 20px;
	}
	h4 {
		margin-top: 20px;
		padding: 10px 0;
	}
}
.swal-wide{
    width: 650px !important;
    left: 46% !important;
}
.sweet-alert p {
    max-width: 100%;
}
.total-comments {
	clear: both;
	display: block;
	font-weight: bold;
	padding: 0px 15px 5px;
	border-bottom: 1px solid #cdcdcd;
	margin: 0 -15px 20px;
}

.box-action {
	position: relative;
}

.rc-duration, .rc-break-time {
	&.rc-time-picker {
		display: unset;
		@include rounded(4px);
	}
	.rc-time-picker-input {
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		height: 34px;
	}
}

.rc-max-time {
	&.rc-time-picker {
		display: unset;
		@include rounded(4px);
	}
	.rc-time-picker-input {
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		height: 36px;
		border: 1px solid #828282 !important;
		border-left: 0 !important;
		border-bottom-left-radius: 0 !important;
	  border-top-left-radius: 0 !important;
	}
}

.rc-date-custom {
	width: 100%;
	.rc-time-picker-input {
		height: 34px;
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
		-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	}
}

.rc-time-picker-panel-select {
	width: 56px;
}

/*
* CUSTOM USERS
*/

.profile-content {
	border-top: none !important;
}

.profile-stats {
	margin-right: 10px;
}

.profile-image {
	width: 120px;
	float: left;
	img {
		width: 96px;
		height: 96px;
	}
}

.profile-info {
	margin-left: 120px;
}

.feed-element {
	&:first-child {
		margin-top: 0;
	}
	padding-bottom: 5px;
	margin-top: 10px;
	.media {
		margin-top: 15px;
	}
}


.feed-element {
	> .pull-left {
		margin-right: 10px;
	}
	.user-feed {
		padding: 0 0 10px;
		border-bottom: 1px solid #979797;
		.time {
			font-weight: normal;
		}
	}
	.img-circle {
		width: 38px;
		height: 38px;
	}
	.media-body {
		vertical-align: middle;
		height: 35px;
		word-break: break-all;
		.time {
			color: #ccc;
		}
		strong {
			color: #6472C7;
		}
		.comment-text {
			padding-top: 10px
		}
	}
}

/*
* CUSTOM WIZARD
*/

.wizard-steps {
	list-style: none;
	display: table;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	li {
		display: table-cell;
		text-align: center;
		width: 1%;
		position: relative;
		.step {
			border: 2px solid $dark01;
			color: $dark01;
			font-size: 15px;
			border-radius: 100%;
			background-color: $white;
			position: relative;
			z-index: 2;
			display: inline-block;
			width: 40px;
			height: 40px;
			line-height: 34px;
			text-align: center;
		}
		&:not(:first-child) a {
			&:before {
				display: block;
				font-family: FontAwesome;
				content: "\F054";
				width: 100%;
				font-size: 20px;
				overflow: hidden;
				position: absolute;
				top: 10px;
				right: 24px;
				color: $dark01;
			}
		}
		&:before {
			display: block;
			content: "";
			width: 100%;
			font-size: 0;
			overflow: hidden;
			border-top: 4px solid $dark01;
			position: relative;
			top: 21px;
			z-index: 1;
			left: 50%;
		}
		&:last-child:before {
			border-top: 0;
			left:0;
		}
		&:first-child:before {
			max-width: 100%;
		}
		&.active + li a:before {
			color: $purple01;
		}
		&.active:before, &.complete:before, &.active .step {
			border-color: $purple01;
			background-color: $purple01;
			color: $white;
		}
		&.complete {
			.step {
				border-color: $purple01;
				cursor: default;
				color: $white;
				@include transform-transition(0.1s);
				&:before {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					line-height: 30px;
					text-align: center;
					border-radius: 100%;
					content: "\f00c";
					background-color: #FFF;
					z-index: 3;
					font-family: FontAwesome;
					font-size: 17px;
					color: #87ba21;
				}
			}
			&:hover {
				.step {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
					border-color: #80afd4;
				}
				&:before {
					border-color: #80afd4;
				}
			}
		}
		.title {
			display: block;
			margin-top: 4px;
			max-width: 100%;
			color: $dark01;
			font-size: 14px;
			z-index: 104;
			text-align: center;
			table-layout: fixed;
			word-wrap: break-word;
		}
		&.complete .title, &.active .title {
			color: $purple01;
		}
	}
}

.step-content {
	.step-pane {
		display: none;
		min-height: 200px;
		padding: 4px 8px 12px;
	}
	.active {
		display: block;
	}
}

.report-wizard, .invitation-wizard {
	padding: 0 0 20px;
	margin: 0 -20px;
	li a {
		cursor: default;
	}
}

.report-wizard {
	border-bottom: 1px solid $border-color;
}

.step-content {
	padding: 20px 10px;
	.step-footer {
		margin-top: 40px;
		.btn:first-child {
			margin-right: 15px;
		}
	}
}

.report-form {
	.select-custom, textarea, input[type="text"] {
		display: block;
	}
	textarea, input[type="text"] {
		@include rounded($none-rounded);
	}
	.form-group {
		label {
			font-weight: bold;
			&.radio-check {
				font-weight: normal;
			}
		}
	}
}

.full-width {
	.select2 .Select-control {
		width: 100%;
	}
	&.with-info {
		margin-right: -40px;
		> div:first-child() {
			> div:first-child() {
				width: 94%;
			}
		}
	}
}

.big-panel--title {
	font-weight: 600;
	text-transform: uppercase;
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.wizard-steps li {
		.step {
			width: 30px;
			height: 30px;
			line-height: 24px;
			border-width: 3px;
		}
		&:before, &:after {
			border-width: 3px;
		}
		&.complete .step:before {
			line-height: 24px;
			font-size: 13px;
		}
		&:before {
			top: 16px;
		}
	}
	.step-content .step-pane {
		padding: 4px 4px 6px;
	}
}

/*
* CUSTOM TABLE
*/

.table.addbranding {
	text-align: center;
	.table-cell, .table-head {
		border:$border-color 1px solid;
		padding: 2px 5px;
	}
	.img-wrap {
		img {
			max-height:100px;
			width: auto;
		}
	}
}

/*
* CUSTOM CREATE JOB
*/

.create-job-container {
	margin-top: 40px;
	&.form-custom {
		.radio-form {
			font-weight: normal;
			input[type="radio"], input[type="checkbox"] {
				vertical-align: sub;
			}
		}
		.select2 .Select-control {
			width: 100%;
		}
	}
}

/*
* CUSTOM EMPTY JOB
*/

.empty-job {
	padding: 100px 0;
	text-align: center;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: $purple;
		margin: 30px 0;
		opacity: 0.47;
	}
}

/*
* CUSTOM EMPTY CANDIDATE
*/

.empty-candidate {
	padding: 100px 0;
	text-align: center;
	margin: 0 auto;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: $purple;
		margin: 30px 0;
		opacity: 0.47;
	}
}


/*
* CUSTOM EMPTY QUESTIONS
*/

.empty-question {
	padding: 100px 0;
	text-align: center;
	margin: 0 auto;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: $purple;
		margin: 30px 0;
		opacity: 0.47;
	}
}

/*
* CUSTOM EMPTY FOLDER
*/

.empty-folder {
	margin: 25px 0;
	text-align: center;
	width: 100%;
	h1 {
		font-size: 20px;
		font-weight: bold;
		color: #6472c7;
		margin: 20px 0;
		opacity: 0.47;
	}
}

/*
* EMPTY SECTIONS
*/

.empty-section {
	padding: 40px 0;
	text-align: center;
	h1 {
		font-size: 20px;
		font-weight: 600;
		color: #5689D0;
		margin: 30px 0;
	}
	img {
		margin: 20px auto;
	}
}

/*
* CUSTOM EMPTY
*/

.empty-video, .empty-cv, .empty-addinfo {
	padding: 20px;
	text-align: center;
	background-color: #ddd;
	height: 100%;
	.placehold {
		border: 0px dashed $grey;
		background-color: #f2f2f2;
		height: 100%;
		h4 {
			font-size: 24px;
			color: #BDBDBD;
		}
		p {
			color: #BDBDBD;
		}
	}
}

.empty-video {
	height: 440px;
}

.empty-addinfo {
	height: 440px;
	.placehold h4 {
		font-size: 22px;
	}
}

.empty-member {
	text-align: center;
}

/*
* CUSTOM ADDQUESTION
*/

.question-type {
	border-bottom: 1px solid $border-color;
	margin-bottom: 15px;
}

.result-list {
	border-top: 1px solid $border-color;
	padding-top: 20px;
	margin-top: 30px;
}

/*
* CUSTOM OVERLAY
*/

.wrapper-overlay {
	.overlay-content {
		color: $white;
		position: relative;
		top: 25%;
		width: 100%;
		text-align: center;
		height: 60vh;
		h1 {
			font-size: 22px;
			font-weight: bold;
		}
		a {
			color: $white;
		}
		p {
			font-size: 15px;
		}
		.video-container {
			margin: 20px 0;
		}
	}
}

/*
* CUSTOM NOTIFICATIONS
*/

.wrapper-notification-list {
	li {
		a {
			font-size: 12px;
			color: $text-color;
			font-weight: normal;
			&.active, &:hover, &:focus {
				background-color: $purple;
				color: $white;
			}
			.boxes {
				width: 100%;
				min-height: 100px;
				.flex-between {
					height: 100%;
				}
				.flex-item {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					&.left {
						width: 20%;
						> div {
							margin: 0 auto;
						}
					}
					&.right {
						width: 80%;
					}
				}
				.media-body {
					small {
						float: right;
						margin-right: 10px;
					}
				}
			}
		}
		em {
			font-size: 10px;
		}
	}
}

/*
* Reset Password Page
*/

div {
	background-color: white;
	&.reset-pass-page {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.pass-container {
			display: table;
			width: 100%;
			height: 100%;
			padding: 0;
			text-align: center;
			color: #fff;
			background: white;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.pass-body {
				height: 80vh;
				display: table-cell;
				vertical-align: middle;
		}
		.pass-content {
			position: relative;
			text-align: center;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.text, a {
				color: #5876B4 !important;
				text-align: center;
				text-decoration: none;
			}
			.text {
				.page-title {
					display:flex;
					justify-content:center;
					align-items:center;
					img {
						width: 20px;
						margin-right: 10px;
					}
					label {
						font-size: 18px;
						font-weight: bold;
					}
				}
				p {
					font-size: 14px;
					font-weight: bold;
					margin: 20px 0;
				}
			}
			.form-reset {
				max-width: 300px;
				margin: 0 auto;
				.field-wrapper{
			        position: relative;
			        margin-top: 17px;
			    }

			    .field-wrapper input{
			        border: 1px solid #5E7BB7;
			        padding: 15px;
			        border-radius: 4px;
			        width: 268px;
			    }

			    .field-wrapper input:focus{
			        border:1px solid #1A73E8;
			    }

			    .field-wrapper .field-placeholder{
			        font-size: 16px;
			        position: absolute;
			        /* background: #fff; */
			        bottom: 16px;
			        -webkit-box-sizing: border-box;
			        box-sizing: border-box;
			        color: #5E7BB7;
			        left: 8px;
			        padding: 0 8px;
			        -webkit-transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
			        transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
			        z-index: 1;

			        text-align: left;
			        width: 290px;
			    }        

			    .field-wrapper .field-placeholder span{
			        background: #ffffff;
			        padding: 0px 8px;
			    }

			    .field-wrapper input:not([disabled]):focus~.field-placeholder
			    {
			        color:#1A73E8;
			    }
			    .field-wrapper input:not([disabled]):focus~.field-placeholder,
			    .field-wrapper.hasValue input:not([disabled])~.field-placeholder
			    {
			        -webkit-transform: scale(.75) translateY(-39px) translateX(-60px);
			        transform: scale(.75) translateY(-39px) translateX(-60px);

			    }
			    .field-wrapper.error{
			        input {
			        	border-color: #FA7C8A;
			        }
			        .field-placeholder {
			        	color: #FA7C8A;
			        }
			        input:not([disabled]):focus~.field-placeholder {
			        	color: #FA7C8A;
			        }
			    }
			    .error-message {
			    	color: #FA7C8A;
			    	font-size: 12px;
			    	text-align: left;
			    	margin-top: 8px;
			    	line-height: 14px;
			    }
			    .btn-save-password {
			    	width: 100%;
			    	padding: 10px 0;
			    	background-color: #607EB6;
			    	border-radius: 2px;
			    	color: white;
			    	font-weight: bold;
			    	font-size: 12px;
			    	margin-top: 17px;
			    }
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

/*
* 404 PAGE
*/

div {
	&.error-page {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error {
			display: table;
			width: 100%;
			height: 100%;
			padding: 0;
			text-align: center;
			color: #fff;
			background: #071522 url(../img/stars.png) no-repeat bottom center scroll;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-body {
				height: 100vh;
				display: table-cell;
				vertical-align: middle;
		}
		.error-content {
			position: relative;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.text, a {
				color: $white !important;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				font-size: 19px;
				 margin: 10px 0 5px;
			 }
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

div {
	&.error-page-new {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error {
			display: table;
			width: 100%;
			height: 100%;
			padding: 0;
			text-align: center;
			color: #fff;
			background: white;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-body {
				height: 100vh;
				display: table-cell;
				vertical-align: middle;
		}
		.error-content {
			position: relative;
			text-align: center;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.circle {
				width: 134px;
				height: 134px;
				display: inline-block;
				background-color: #5876B4;
				border-radius: 67px;
				margin: 0 auto;
				display:flex;
				justify-content:center;
				align-items:center;
				font-size: 48px;
				font-weight: bold;
			}
			.text, a {
				color: #5876B4 !important;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				font-size: 14px;
				font-weight: bold;
				 margin: 50px 0;
			 }
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

div {
	&.finish-reset {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error {
			display: table;
			width: 100%;
			height: 100%;
			padding: 0;
			text-align: center;
			color: #fff;
			background: white;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-body {
				height: 100vh;
				display: table-cell;
				vertical-align: middle;
		}
		.error-content {
			position: relative;
			text-align: center;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.store-container {
				img {
					height: 40px;
					margin: 0 7px;
				}
			}
			.logo-finish {
				width: 80px;
			}
			.circle {
				width: 134px;
				height: 134px;
				display: inline-block;
				background-color: #5876B4;
				border-radius: 67px;
			}
			.text, a {
				color: #5876B4 !important;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				font-size: 14px;
				font-weight: normal;
				margin: 30px 0;
				&.bold {font-weight: bold;}
			 }
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

div {
	&.verify-new-email {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error {
			display: table;
			width: 100%;
			height: 100%;
			padding: 0;
			text-align: center;
			color: #fff;
			background: white;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-body {
				height: 100vh;
				display: table-cell;
				vertical-align: middle;
		}
		.error-content {
			position: relative;
			text-align: center;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.store-container {
				img {
					height: 40px;
					margin: 0 7px;
				}
			}
			.logo-finish {
				width: 80px;
			}
			.circle {
				width: 134px;
				height: 134px;
				display: inline-block;
				background-color: #5876B4;
				border-radius: 67px;
			}
			.text, a {
				color: #5876B4 !important;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
					font-size: 14px;
					font-weight: normal;
					margin-top: 50px;
					margin-bottom: 0;
					&.bold {
						font-weight: normal;
						font-size: 36px;
						line-height: 40px;
					}
			 	}
			 	p:last-child {
					margin-top: 15px;
				}
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

div {
	&.finish-verify {
		width: 100%;
		height: 100%;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.error {
			display: table;
			width: 100%;
			height: 100%;
			padding: 0;
			text-align: center;
			color: #fff;
			background: white;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.error-body {
				height: 100vh;
				display: table-cell;
				vertical-align: middle;
		}
		.error-content {
			position: relative;
			text-align: center;
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.store-container {
				margin-top: 35px;
				img {
					height: 40px;
					margin: 0 7px;
				}
			}
			.logo-finish {
				width: 80px;
			}
			.circle {
				width: 134px;
				height: 134px;
				display: inline-block;
				background-color: #5876B4;
				border-radius: 67px;
			}
			.text, a {
				color: #707070 !important;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
					font-size: 14px;
					font-weight: normal;
					margin: 0;
					&.bold {font-weight: bold;}
				}
				.p-header {
					font-size: 36px;
					margin-bottom: 42px;
					line-height: 42px;
				}
				.p-body {
					color: #3B3B3B;
					font-size: 14px;
					margin-bottom: 14px;
				}
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

div {
	&.video-full-page{
		width: auto;
		height: auto;
		font-family: 'Open Sans', sans-serif;
		color: #000;
		background-color: #262626;
		font-size: 25px;
		padding: 0;
		margin: 0;
		.video-page {
			display: block;
			width: auto;
			height: auto;
			padding: 0;
			text-align: center;
			color: #fff;
			background: #262626;
			background-size: cover;
			-o-background-size: cover;
			overflow:hidden;
		}
		.video-body {
				height: 100vh;
				display: inline-block;
				vertical-align: middle;
				background-color: #262626;
		}
		.video-content {
			position: relative;
			text-align: center;
			background-color: #262626;
			height: 100%;
			video{
			    display: inline-block;
			    height: 100%;
			    max-width: 100%;
			    width: auto;
			    z-index: -1;
			}
			.link {
				font-size: 17px;
				margin-top: 80px;
				position: relative;
				a:hover {
					text-decoration: underline;
				}
			}
			.circle {
				width: 134px;
				height: 134px;
				display: inline-block;
				background-color: #5876B4;
				border-radius: 67px;
				margin: 0 auto;
				display:flex;
				justify-content:center;
				align-items:center;
				font-size: 48px;
				font-weight: bold;
			}
			.text, a {
				color: #5876B4 !important;
				background-color: #262626;
				text-align: center;
				text-decoration: none;
			}
			.text {
				p {
				font-size: 14px;
				font-weight: bold;
				 margin: 50px 0;
			 }
			}
			.img-notfound {
				background:transparent url(../img/404.png) no-repeat top center;
				position:relative;
				width: 379px;
				height: 221px;
				margin: 0 auto;
			}
		}
	}
}

/*
* CUSTOM THUMBS
*/

.dropdown {
	&.ratings, &.notifs {
		width: 270px;
		margin-top: 20px;
	}
	&.custom {
		.dropdown-menu {
			margin-top: 10px;
			a {
				padding: 10px 20px;
			}
		}
	}
}

.dropbtn {
	cursor: pointer;
}

.dropdown-content {
	position: absolute;
	background-color: $white;
	width: 400px;
	right: 0;
	top: 100%;
	border: 1px solid #d8d8d8;
	z-index: 1000;
	@include animated(0.5s);
	@extend .fadeIn;
	a {
		display: inline-block;
		padding: 12px 14px;
		position: relative;
		text-align: center;
		.btn {
			min-height: 74px;
		}
		h5 {
			text-align: center;
			width: 103px;
			font-size: 12px;
			margin-bottom: 0;
			color: #4B4F85;
			&:hover, &:focus {
				color: #1A1D3B;
			}
		}
		&.dropdown-toggle {
			&:after {
				content: " ";
				height: 10px;
			}
		}
	}
	.summary, .detail {
		border-right: 1px solid #d8d8d8;
	}
	&.md-content {
		width: 200px;
	}
}

.dropup-content {
	display: none;
	position: absolute;
	top:-165px;
	right: 0;
	height: 160px;
	background-color: rgba(255,255,255,0.90);
	width: 36px;
	border-radius: 40px;
	padding: 6px 4px;
	border: 2px solid #6472C7;
	cursor: default;
	div {
		margin-bottom: 5px;
		small {
			color: #3D3D3D;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.dropup:hover {
	.dropup-content {
		display: block;
	}
}


.react-autosuggest__suggestions-container {
	position: absolute;
	z-index: 1000;
	max-height: 294px;
	overflow-y: auto;
	width: 100%;
	border-top: 0;
	margin-top: -2px;
	.react-autosuggest__suggestions-list {
		border: 1px solid #ddd;
	}
}

.react-autosuggest__container {
	.form-control:focus {
		outline: none;
		border-color: #ddd;
		box-shadow: none;
	}
}

.wrapper-custom-dropdown {
	width: 100%;
	font-size: 12px;
	background-color: $white;
	color: inherit;
	&:hover, &:focus {
		background-color: #f3f6ff;
		color: $body-text;
	}
	.boxes {
		padding: 10px 15px;
		width: 100%;
		h5 {
			margin: 0 0 5px;
			font-weight: bold;
		}
		p {
			margin: 0;
		}
		.flex-between {
			height: 100%;
		}
		.flex-item {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			&.left {
				width: 100%;
				> div {
					margin: 0 auto;
				}
			}
			&.right {
				width: 30%;
				.selection {
				 float: right;
				 a {
					 margin-right: 10px;
					 color: $purple;
				 }
				}
			}
		}
	}
}

/*
* CUSTOM EMAIL INVITATION PREVIEW
*/

.wrapper-preview-email {
	.bg-email {
		background: $text-color;
	}
	.wrapper-email {
		color: #000000;
		text-align: center;
		font-family:'Helvetica';
		width: 100%;
	}
	.company-logos {
		margin: 0 0 20px;
		max-height: 124px;
		width: auto;
		max-width: 190px;
	}
	.company-name {
		margin: 20px 0 15px;
		max-height: 124px;
		width: auto;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 25px;
	}
	.box-email {
		background: #F2F2F2;
		background-size: cover;
		padding: 35px 0;
		text-align: center;
		width: 100%;
		.templates {
			max-width: 600px;
			margin: 0 auto;
			text-align: left;
			.email-invitation {
				padding: 40px 30px 30px;
				margin: 0 auto;
				background: #FFFFFF;
				@include rounded($medium-rounded);
			}
			.email-greetings {
				font-size: 24px;
				margin: 0 0 25px;
				font-weight: bold;
				font-family: 'Helvetica';
				line-height: 33px;
			}
			.email-content{
				font-size: 16px;
				margin: 0 0 25px;
				line-height: 24px;
			}
			.email-content-header-about {
				font-size: 18px;
				margin: 0 0 25px;
				font-weight: bold;
				font-family: 'Helvetica';
				line-height: 33px;
			}
			.content-button-text {
				@extend .email-content;
				width : 410px;
				max-width : 420px;
				text-align: center;
			}
			.email-deeplink {
				text-decoration:none;
				background-color: #564FDB;
				color: #ffffff;
				font-size: 24px;
				padding: 14px 30px;
				border-radius: 30px;
				position: relative;
				display: inline-block;
				margin: 0 0 25px;
				line-height: 33px;
			}
			.email-content-code-top {
				background-color: #ffffff;
				padding: 1px 30px;
				position: relative;
				display: inline-block;
				z-index:1
			}
			.email-content-code-text-top {
				font-size: 12px;
				line-height: 12px
			}
			.email-content-code-bottom {
				background-color: #ffffff;
				padding: 15px 25px;
				border-radius:5px;
				border: 1px solid #000000;
				position: relative;
				display: inline-block;
				margin: -20px auto;
			}
			.email-content-code-text-bottom {
				font-size: 18px;
				line-height: 24px;
				margin-bottom: 0;
			}
			.email-content-ending-top {
				font-size: 16px;
				margin: 0 0 1px;
				line-height: 24px
			}
			.email-content-ending-bottom {
				font-size: 16px;
				margin: 0 0 30px;
				line-height: 24px
			}
			.main-intro {
				color: #000000;
				margin: 5px 0 20px;
				font-size: 18px;
				line-height: 25px;
			}
			.first-intro {
				font-size: 15px;
				margin: 0;
			}
			.second-intro {
				font-size: 15px;
				margin: 15px 0;
			}
			.box-code {
				.code {
					font-family: "Helvetica";
					font-size: 25px;
				}
			}
			.email-msg {
				font-size: 15px;
				max-width: 527px;
				padding: 0 20px;
				.n-m {
					margin: 0;
				}
				.m-b-40 {
					margin: 0 0 40px;
				}
				b.deadline {
					font-weight: bold;
				}
			}
			.ads {
				display: block;
				margin-bottom: 30px;
				position: relative;
				font-weight: bold;
			}
			.gplay, .appstore {
				max-height: 40px;
				margin:0 5px
			}
			.help {
				font-size: 15px;
				margin-bottom: 70px;
				.intro {
					margin-top: 30px;
				}
			}
			.help-link {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
						-ms-flex-flow: row wrap;
								flex-flow: row wrap;
				-ms-flex-pack: distribute;
						justify-content: space-around;
				font-size: 15px;
				width: 420px;
				margin: 0 auto;
				a {
					text-decoration: none;
					color: #000000;
				}
			}
			.email-footer {
				color: $white;
				font-family: "Helvetica";
				p {
					font-size: 12px;
					a {
						color:#3B7ADB;
						text-decoration:underline;
					}
				}
			}
		}
		.email-showcase__container {
			max-width: 260px;
			margin: 0 auto;
		}
		.email-showcase {
			max-width: 260px;
			display: inline-block;
			margin-bottom: 40px;
			display: flex;
			.text-left {
				max-width: 180px;
			}
		}
		.email-showcase__column {
			max-width: 260px;
			display: inline-block;
			margin-bottom: 40px;
		}
		.email-footer {
			padding: 20px 30px 30px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content:
			space-between;
			padding: 20px 30px 30px;
			text-align: left;
			font-size: 12px;
			color: $white;
			background-color: #564fdb;
			border-radius: 0px 0px 10px 10px;
			ul {
				li {
					float: left;
					a, a:hover, a:focus {
						color: $white;
					}
				}
			}
			img {
				max-height: 40px;
			}
			.email-footer__left {
				flex: 1;
			}
			.email-footer__right {
				width: 35%;
			}
		}
		.email-footer-first-margin {
			margin-right: 40px;
		}
		.email-footer-left-content {
			font-size: 12px ;
			line-height: 24px ;
			margin-left: 6px;
		}
		.email-footer-right-content {
			display: flex;
			flex-wrap: wrap;
		}
		.email-footer-second-margin {
			margin-right: 35px;
		}
		.email-footer-right-content-top {
			font-size: 12px ;
			margin: 18px 0px 12px;
		}
		.email-footer-right-content-bottom {
			font-size: 12px ;
			margin-top: 4px ;
			line-height: 17px;
		}
	}
}

.first-intro, .second-intro, .third-intro, .four-intro, .form-control-static {
	span.editable-invitation-template {
		border-bottom: 1px dashed #6472C7;
		word-break: break-word;
	}
	input, textarea {
		@include riek(block,100%);
	}
}

.form-control-static {
	span {
		border-bottom: 1px dashed #6472C7;
		word-break: break-all;
		&.non-editable {
			word-break: break-all;
		}
	}
	input, textarea {
		@include riek('inline-block','auto');
	}
}

.dropdown-menu{
	&.dropdown-template {
		border-radius: 0;
		> li > a {
			color: #4B4F85;
			padding: 5px 20px;
		}
	}
}

.template-name {
	color: #4B4F85;
}

.wrapper-modal-upload {
	&.modal-dialog {
		width: 549px;
	}
	.modal-header {
		padding: 15px 35px 10px;
		border-bottom: 1px solid $border-color;
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
		.close-modal {
			position: absolute;
			right: 10px;
			top: 13px;
		}
	}
	.modal-body {
		position: relative;
		padding: 15px 25px;
		margin: 0 auto;
		text-align: left;
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.box-intro {
		background-color: #ECECEC;
		padding: 10px 20px;
		font-size: 12px;
		h3 {
			font-weight: bold;
		}
		&.errors {
			h3, ul > li {
				color: #EC3333;
			}
		}
	}
}

.wrapper-modal-reason {
	&.modal-dialog {
		width: 400px;
		margin-top: 10%;
	}
	.modal-header {
		padding: 15px 15px 0 15px;
		border-bottom: 0;
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
}

.wrapper-modal-section {
	h2 {
		color: #4F4F4F;
		font-size: 18px;
		font-weight: 600;
		padding-bottom: 20px;
	}
	.wrapper-add-section {
		padding-bottom: 30px;
	}
	.modal-header {
		padding: 15px 15px 0 15px;
		border-bottom: 0;
	}
	.modal-footer {
		border-top: 0;
		text-align: center;
		padding: 25px 0;
	}
	.error {
		margin-left: 15px;
	}
}

.box-image-preview {
	width: 100%;
	min-height: 150px;
	border: 1px solid $border-color;
	@include rounded(5px);
	margin-bottom: 20px;
	p {
		font-size: 12px;
		color: #BDBDBD;
		margin: 0 auto;
	}
}

.custom-modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid $border-color;
}

/*
* CUSTOM SELECT CANDIDATES
*/

.candidate-library {
	padding: 10px;
}

/*
* WRAPPER OPTIONS
*/

.wrapper-enter-question {
	padding: 10px 20px;
	border-bottom: 1px dashed #ded;
	margin-bottom: 10px;
	background: #eee;
}

.wrapper-enter-question-white {
	padding: 0px 20px;
	margin-bottom: 10px;
	background-color: transparent;
}

.wrapper-options {
	label {
		margin: 20px 0;
	}
	.option {
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px dashed #ddd;
		.img-container {
			padding: 0 20px;
		}
	}
	.option-list {
		margin-bottom: 10px;
	}
}

.option-list {
	.input-group {
		width: 350px;
		.form-control:last-child {
			border: 1px solid #ccc;
		}
	}
	div {
		margin-right: 10px;
	}
	.numeric {
		margin: 5px 0;
		width: 15px;
	}
}

/*
* PREVIEW JOB
*/

.wrapper-listing {
	width: 600px;
	margin-right: auto;
	margin-left: auto;
}

.job-listing {
	width: 100%;
	padding: 15px 0 60px;
	.job-detail {
		width: 100%;
		margin-bottom: 40px;
		color: #515353;
		h2 {
			font-size: 17px;
			font-weight: bold;
			margin-bottom: 14px;
		}
		.company-detail {
			padding: 0 30px 10px;
			.logo {
				text-align: left;
				.company-logo {
					max-height: 75px;
					width: auto;
					margin-left: 0;
				}
			}
		}
		.header-detail {
			padding: 0 30px 20px;
			h1 {
				font-weight: 600;
			}
		}
		.body-detail, .requirement-detail, .other-detail {
			padding: 15px 30px 20px;
			.content {
				white-space:pre-wrap;
				ul {
					list-style-type: disc;
					padding-left: 20px;
					margin-bottom: 0;
				}
			}
		}
		.desc-text {
			white-space: pre-wrap;
		}
		.download-box {
			background-color: #F9F9F4;
			width: 355px;
			height: 236px;
			color: #3E3E3E;
			border: 2px solid #D8D8D8;
			padding: 25px;
			text-align: center;
			margin: 28px auto;
			img {
				max-height: 40px;
			}
		}
		.border-line {
			border-top: 18px solid #404B73;
			margin-bottom: 20px;
		}
		.leftbox {
			background-color: #D8D8D8;
			text-align: left;
		}
	}
	.job-footer {
		padding-top: 40px;
		p {
			float: left;
			padding: 10px 0 0 10px;
			a {
				color: $white;
			}
		}
		.copy {
			text-align: right;
			padding: 10px 30px;
			background-color: #282828;
			margin: 0 -15px;
			a {
				color: $white;
			}
		}
	}
}

.react-datepicker, .react-datepicker__current-month {
	font-size: 1rem;
}

.react-datepicker__input-container {
	display: block;
}

/*
* DROPZONE
*/

.wrapper-dropzone {
	display: block;
	width: 100%;
	text-align: center;
	border: 2px dashed #d8d8d8;
	margin-top: 20px;
	padding: 40px;
	cursor: pointer;
	div {
		width: 200px;
		margin: 0 auto;
	}
	.img-upload {
		display: block;
		margin: 0 auto;
	}
	p {
		text-align: center;
		margin: 15px auto;
	}
}

/*
* CUSTOM TABLE
*/

.custom-table {
	.table {
		margin-top: 10px;
	}
}

.preview-question-table {
	border: 1px solid $border-color;
	li {
		word-break: break-word;
	}
	.w95 {
		width: 95%;
		white-space: normal;
	}
	tr {
		th:not(last-child){
			border-right: 1px solid #D8D8D8;
		}
		td:not(last-child) {
			border-right: 1px solid #D8D8D8;
		}
	}
}

.not-interested-table {
	border: 1px solid $border-color;
	tr {
		th {
			background-color: #DDDDDD;
			&:first-child {
				width: 30%;
			}
			&:not(last-child){
				border-right: 1px solid #D8D8D8;
			}
		}
		td {
			background-color: #F1F1F1;
			&:not(last-child) {
				border-right: 1px solid #D8D8D8;
			}
		}
	}
}

/*
* CUSTOM Company Profile
*/

.logo-preview {
	max-height: 200px;
	width: auto;
	max-width: 260px;
	margin-right: 15px;
	display: block;
}

.cover-preview {
	max-height: 200px;
	width: auto;
	max-width: 400px;
	margin-right: 15px;
	display: block;
}

.question-switcher, .switcher {
	div {
		border-radius: 0 !important;
	}
}

.switcher {
	width: 100px;
	padding: 10px 0;
	p {
		padding: 2px 0;
	}
}


.company-profile-container {
	a.cancel {
		&:hover {
			text-decoration: underline;
		}
	}
}


/*
* CUSTOM JOB DETAIL
*/

.job-detail {
	h5 {
		padding: 0 20px 0 0;
		font-weight: bold;
	}
	.code-here {
		color: #F16C6C;
		font-weight: bold;
	}
}

/*
* CUSTOM ADDITIONAL INFO
*/

.addinfo {
	margin-bottom: 10px;
	.input-group {
		max-width: 265px;
	}
	.form-control {
		max-width: 265px;
		margin-right: 10px;
	}
}

/*
* CUSTOM WELL
*/

.well-tab {
	background: #eee;
	padding: 12px 6px;
	border: 1px solid #ddd;
	@include rounded(8px);
}


/*
* CUSTOM VIDEO JS
*/
.rh5v-Volume_icon, .rh5v-Fullscreen_icon, .rh5v-PlayPause_icon {
	padding: 0;
}

/*
* CUSTOM PUBLIC PAGE
*/
.public-page {
	background-color: $white;
}

.question__instruction {
	h1 {
		font-size: 36px;
		font-weight: 600;
		color: #30307B;
		text-align: center;
		margin: 40px 0 25px;
	}
	.tips {
		padding: 0;
		> div {
			padding: 60px 20px;
			min-height: 355px;
			div {
				width: 100%;
			}
			i {
				margin: 0 auto;
			}
			h2 {
				margin: 50px auto 0;
				color: rgba(0, 0, 0, 0.54);
				font-size: 22px;
				font-weight: 600;
				text-align: center;
				width: 215px;
				line-height: 25px;
			}
		}
	}
	.tips-left, .tips-right {
		background: #83D8E3;
		h2 {
			color: rgba(0, 0, 0, 0.54);
		}
	}
	.tips-center {
		background: #B2E2E9;
		h2 {
			color: #1C1B6F;
		}
	}
}

.registration-box {
	background-color: #F8F8F8;
	padding: 30px 50px;
	h4 {
		text-align: center;
		font-weight: 600;
	}
	.form-group {
		font-size: 16px;
		line-height: 24px;
		width: 100%;
		height: 72px;
		display: inline-block;
		position: relative;
		background-color: transparent;
		font-family: Roboto, sans-serif;
		transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		margin-bottom: 20px;
		cursor: auto;
		label, .label {
			font-size: 18px;
			position: absolute;
			line-height: 22px;
			top: 30px;
			transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
			z-index: 1;
			transform: scale(0.75) translate(0px, -28px);
			transform-origin: left top 0px;
			pointer-events: none;
			user-select: none;
			color: #878787;
			font-weight: normal;
		}
		input[type="text"] {
			padding: 25px 0 0px;
			position: relative;
			width: 100%;
			border: none;
			outline: none;
			background-color: rgba(0, 0, 0, 0);
			color: rgba(0, 0, 0, 0.87);
			cursor: inherit;
			font-style: inherit;
			font-variant: inherit;
			font-weight: inherit;
			font-stretch: inherit;
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			box-sizing: border-box;
			margin-top: 14px;
			-webkit-appearance: textfield;
		}
		textarea {
			width: 100%;
			resize: none;
			font-style: inherit;
			font-variant: inherit;
			font-weight: inherit;
			font-stretch: inherit;
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;
			padding: 20px 0 0;
			cursor: inherit;
			position: relative;
			border: none;
			outline: none;
			background-color: rgba(0, 0, 0, 0);
			color: rgba(0, 0, 0, 0.87);
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			height: 162px;
			margin-top: 35px;
			box-sizing: border-box;
			-webkit-appearance: textfield;
		}
		hr {
			border-top: none #C3C3C3;
			border-left: none #C3C3C3;
			border-right: none #C3C3C3;
			border-bottom: 1px solid #C3C3C3;
			bottom: 8px;
			box-sizing: content-box;
			margin: 0px;
			position: absolute;
			width: 100%;
		}
		&.sm {
			height: 10px;
		}
		.label {
			top: 0;
			padding-left: 0;
		}
	}
}

.visible-box {
	visibility: hidden;
	height: auto;
	position: absolute;
}

.relative {
	position: relative
}

.box-list {
	label {
		font-size: 16px;
	}
	.form-group {
		margin-bottom: 10px;
	}
	.fullname, .description {
		border-bottom: 1px solid $border-color;
		margin-bottom: 20px;
	}
	.question {
		margin-bottom: 20px;
	}
	h1 {
		font-size: 21px;
		font-weight: bold;
		text-transform: capitalize;
	}
	h4 {
		font-weight: normal;
	}
}

.reason-list {
	padding: 20px;
}

.second-modal {
		z-index: 1051;
}

/*
* CUSTOM ADD SECTION
*/

.box-mobile-preview {
	border: 2px solid #ccc;
	@include rounded(5px);
	position: relative;
	.header-mobile {
		 border-bottom: 1px solid #ccc;
		 position: relative;
		 h3 {
			text-align: center;
			margin: 0;
			padding: 20px 0;
			color: $purple;
		 }
	}
	.body-mobile {
		min-height: 280px;
		position: relative;
		.mobile-content {
			text-align: center;
			padding: 0 20px;
			h1 {
				font-size: 20px;
				font-weight: 600;
				word-break: break-all;
			}
			h5 {
				font-size: 16px;
				font-weight: normal;
			}
			.question-img-preview {
				margin: 25px 0;
			}
			p {
				font-size: 14px;
				word-break: break-all;
			}
		}
	}
	.footer-mobile {
		border-top: 1px solid #ccc;
		text-align: center;
		padding: 5px 0 10px 0;
		position: relative;
		h5 {
			text-align: center;
			color: $purple;
			font-size: 14px;
			padding: 10px 0;
		}
		.box-time {
			background-color: #F4F4F4;
			min-height: 40px;
			border-radius: 5px;
			margin: 10px 20px;
			padding: 7px 0;
			text-align: center;
		}
	}
}

.mobile-preview__section {
	.indicator {
	  width: 100%;
	  height: 48px;
	  color: #FFFFFF;
	  padding: 14px 24px;
		&.info {
		  background-color: $purple01;
		}
	}
}

/*
* WRAPPER MAINTENANCE
*/

.wrapper-maintenance {
	margin-top: 40px;
	line-height: 32px;
	text-align: center;
	img {
		max-height: 40px;
	}
	h1 {
		line-height: 45px;
	}
}
.warning-msg, .success-msg, .import-msg {
	padding: 20px;
	h3, p {
		font-weight: normal;
		margin-top: 20px;
	}
}

/*
* CUSTOM DATEPICKER
*/

.datepicker-sm {
	width: 200px;
	display: inline-block;
}

/*
* RATING VIDEO
*/

.rating-video {
	height: 40px;
}

/*
* CUSTOM CHART
*/

.recharts-default-legend {
	right: -5px !important;
}

.chart-container {
	.recharts-wrapper {
		height: 430px !important;
	}
	.recharts-responsive-container {
		height: 460px !important;
	}
	.recharts-legend-wrapper {
		left: 65px !important;
	}
}


#nprogress {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.1);
	z-index:9999;
	.spinner {
		pointer-events: none;
		position: fixed;
		z-index: 999;
		height: 2em;
		width: 2em;
		overflow: show;
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.member-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
	.member-list {
		flex-basis: 50%;
		margin-bottom: 10px;
	}
}

.duplicate-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
	background-color: #FAFAFA;
	padding: 20px 20px;
	text-align: left;
	margin: 0 auto;
	.duplicate-list {
		flex-basis: 100%;
		margin-bottom: 10px;
		height: 37px;
		border-bottom: 1px solid #E5E5E5;
	}
	.duplicate-list-all {
		flex-basis: 100%;
		margin-bottom: 10px;
		height: 37px;
		border-bottom: 1px solid #CCCCCC;
	}
}

a[aria-expanded="true"] {
	border-color: $purple01 !important;
}

#theme-purple {
	.page-title {
		margin-bottom: 24px;
	}
}

#public {
	.intro-signup {
	  padding-top: 76px;
	  padding-bottom: 76px;
	}
}

.text-center{
	text-align: center;
}

.question-display-input input[type=number]::-webkit-outer-spin-button, 
.question-display-input input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
}

.upload-cv {
	background-position: right;
	background-repeat: no-repeat;
	background-size: 30px 20px;
	margin-top: 5px;
	margin-bottom: 0;
	background-origin: content-box;
	height: 52px;
}

.upload-cv input{
	opacity: 0;
	margin-top: -10px;
}


.cv-file {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	margin-bottom: 0;
	margin-top: 10px;
	width: 175px;
	font-size: 16px !important;
	color: #666666;
}

.uploaded-cv {
	display: none;
}

.div-soc-container {
	height: 36px;
	font-size: 13px;
	color: #999999;
	font-weight: bold;
	.div-soc-med {
		width: 110px;
		height: 36px;
		border-radius: 5px;
		padding: 9px;
		color: white;
		font-weight: normal;
		margin-left: 10px;
		&.email {
			background-color: #564FDB;
		}
		&.facebook {
			background-color: #3B5998;
		}
		&.twitter {
			background-color: #1DA1F2;
		}
		&.linkedin {
			background-color: #0077B5;
		}
		&.whatsapp {
			background-color: #25D366;
		}
		i {
			font-size: 18px;
		}
	}
	.div-soc-med-small {
		width: 36px;
		height: 36px;
		border-radius: 5px;
		padding: 6px;
		color: white;
		font-size: 18px;
		font-weight: normal;
		margin-left: 10px;
		&.email {
			background-color: #564FDB;
		}
		&.facebook {
			background-color: #3B5998;
		}
		&.twitter {
			background-color: #1DA1F2;
		}
		&.linkedin {
			background-color: #0077B5;
		}
		&.whatsapp {
			background-color: #25D366;
		}
	}
}

.input-group-addon-up-cv {
	padding: 3px 12px;
	color: #999999;
}

.progress-cv {
	background-color: white;
	margin-top: -2px;
    margin-bottom: 0px;
    height: 7px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0px 0px 4px 4px;
}

.div-wisiwig {
	p {
		margin: 0;
	}
	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.div-error-cv {
	margin-top: -36px;
    margin-left: -13px;
}

.div-total-view {
	color: #5876B4;
	border-radius: 3px;
	margin-bottom: -35px;
	font-weight: bold;
	z-index: 1;
	display:flex;
	justify-content:center;
	align-items:center;
	padding: 10px;
	width: auto;
	float: right;
	position: absolute;
	top: 30px;
	right: 30px;
	// background-color: rgba(255, 255, 255, 0.23);
	background-color: #FFFFFFB2;
	backdrop-filter: blur(3px);
	backdrop-filter: brightness(50%);
	img {
		margin-right: 24px;
		width: 20px;
	}
}

.div-total-view-full {
	color: #FFF;
	font-size: 12px;
	font-weight: bold;
	border-radius: 3px;
	margin-bottom: -35px;
	z-index: 1;
	display:flex;
	justify-content:center;
	align-items:center;
	padding: 7px;
	width: auto;
	float: left;
	position: absolute;
	top: 20px;
	left: 21px;
	background-color: rgba(255, 255, 255, 0.36);
	// background-color: #FFFFFF5B;
	backdrop-filter: blur(3px);
	backdrop-filter: brightness(50%);
	img {
		margin-right: 10px;
		width: 24px;
	}
}

.div-username-full {
	color: #FFF;
	font-size: 12px;
	font-weight: bold;
	border-radius: 3px;
	margin-bottom: -35px;
	z-index: 1;
	display:flex;
	justify-content:center;
	align-items:center;
	padding: 12px;
	width: auto;
	max-width: 35%;
	float: right;
	position: absolute;
	top: 20px;
	right: 100px;
	background-color: rgba(255, 255, 255, 0.36);
	// background-color: #FFFFFF5B;
	backdrop-filter: blur(3px);
	backdrop-filter: brightness(50%);
	span {
		max-width: 100%;
	    white-space: nowrap;
	    overflow: hidden !important;
	    text-overflow: ellipsis;
	}
	img {
		width: 50px;
	    height: 50px;
	    border-radius: 25px;
	    position: absolute;
	    right: -40px;
	    &.default-profpic {
	      border-radius: 0;
	    }
	}
}

.div-close-mobile-full {
	color: #FFF;
	font-size: 12px;
	font-weight: bold;
	border-radius: 36px;
	margin-bottom: -35px;
	z-index: 1;
	display:flex;
	justify-content:center;
	align-items:center;
	padding: 12px;
	width: auto;
	max-width: 36px;
	float: right;
	position: absolute;
	top: 20px;
	right: 13px;
	// background-color: rgba(255, 255, 255, 0.23);
	background-color: #FFFFFF5B;
	backdrop-filter: blur(3px);
	backdrop-filter: brightness(50%);
	span {
		max-width: 100%;
	    white-space: nowrap;
	    overflow: hidden !important;
	    text-overflow: ellipsis;
	}
	i {
		font-size: 16px;
	}
	img {
		width: 50px;
	    height: 50px;
	    border-radius: 25px;
	    position: absolute;
	    right: -40px;
	    &.default-profpic {
	      border-radius: 0;
	    }
	}
}

@media screen and (min-width: 767px){
	.div-close-mobile-full {display: none !important;}
	.div-username-full {
		max-width: 48%;
		right: 55px;
	}
}

.div-question-video {
	color: white;
	z-index: 1;
	display:flex;
	margin-top: -71px;
	justify-content:center;
	align-items:center;
	position: relative;
	padding: 25px 20px;
	border-radius: 0 0 5px 5px;
	background-color: rgba(88, 118, 180, 0.50);
	backdrop-filter: blur(3px);
	backdrop-filter: brightness(50%);
	img {
		width: 22px;
		margin-right: 10px;
	}
	span {
		max-width: 100%;
	    white-space: nowrap;
	    overflow: hidden !important;
	    text-overflow: ellipsis;
	}
}

.div-question-video-full {
	z-index: 1;
	display:flex;
	justify-content:center;
	align-items:center;
	position: absolute;
	bottom: 3%;
	left: 50%;
    transform: translate(-50%, 0);
	width: 100%;
	max-width: 85%;
	background-color: rgba(88, 118, 180, 0.50);	
	border-radius: 5px;
	.isi {
		color: white;
		background-color: transparent;
		justify-content:center;
		display:flex;
		align-items:center;
		padding: 15px;
		max-width: 90%;
	}
	img {
		width: 22px;
		margin-right: 10px;
	}
	span {
		max-width: 90%;
		font-size: 13px;
	    // white-space: nowrap;
	    // overflow: hidden !important;
	    // text-overflow: ellipsis;
	}
}